import * as React from "react"

import { AnimatePresence, m as motion } from "framer-motion"
import { useSelector } from "react-redux"

import { getInternalSidebarCollapsedSelector } from "src/redux/UI/selectors"

import CollapsedSidebar from "./CollapsedSidebar"
import { COLLAPSED_SIDE_BAR_WIDTH } from "./constants"
import OpenSidebar from "./OpenSidebar"
/**
 * Creates a Sidebar component that returns either an open or collapsed sidebar depending on global state.
 * The component is wrapped inside a div that uses the animation library Motion and takes in an object with styling properties
 * @return {JSX.Element} Returns the Sidebar component
 */
const Sidebar = () => {
  const collapsed = useSelector(getInternalSidebarCollapsedSelector)

  const sidebar = {
    open: {
      width: 315,
      x: 0,
      opacity: 1,
    },
    closed: {
      width: COLLAPSED_SIDE_BAR_WIDTH,
      x: 0,
      opacity: 1,
    },
  }

  return (
    <AnimatePresence initial={false}>
      <motion.div
        initial={{ x: -200, opacity: 0 }}
        variants={sidebar}
        animate={!collapsed ? "open" : "closed"}
        transition={{ type: "spring", bounce: 0.3, duration: 0.5 }}
      >
        {collapsed ? (
          <CollapsedSidebar collapsed={collapsed} />
        ) : (
          <OpenSidebar collapsed={collapsed} />
        )}
      </motion.div>
    </AnimatePresence>
  )
}

export default Sidebar
