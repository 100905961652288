import * as React from "react"

import { Tooltip } from "@behaviour-lab/blab-component-library"
import Link from "next/link"
import { useRouter } from "next/router"

import { TopLevelNavigationLinkType } from "src/types"
import { useInternalRoutes } from "src/utils/hooks"

import { COLLAPSED_SIDE_BAR_WIDTH } from "../Sidebar/constants"

import IconLink from "./IconLink"

interface IProps extends TopLevelNavigationLinkType {
  isIconView?: boolean
}

/**
 * Creates a SubLink component that returns a navigation sublink
 * * REQUIRED PROPS
 * @param  {string} isIconView Defines the view: true for CollapsedSidebar, false - for Mobile and OpenedSidebar
 * @param  {string} label Name of the page
 * @param  {string} to Link path
 * @param  {Function} Icon Link Icon
 * @return {JSX.Element} Returns the SubLink component
 */
const SubLink = ({ label, to, Icon, isIconView }: IProps) => {
  const router = useRouter()
  const { route } = useInternalRoutes(to)

  const [tooltipElement, setTooltipElement] =
    React.useState<HTMLElement | null>(null)

  const handleShowTooltip = (e: React.MouseEvent) =>
    setTooltipElement(e.target as HTMLElement)
  const handleHideTooltip = () => setTooltipElement(null)

  return isIconView ? (
    <div
      className="relative shadow cursor-pointer"
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleHideTooltip}
      style={{
        width: `${COLLAPSED_SIDE_BAR_WIDTH}px`,
      }}
    >
      <IconLink
        route={route}
        Icon={Icon}
        isActive={router.pathname === to}
        isSubLink
      />
      {!!tooltipElement && (
        <Tooltip
          referencedElement={tooltipElement}
          className="whitespace-nowrap"
          offsetPx={2}
        >
          {label}
        </Tooltip>
      )}
    </div>
  ) : (
    <div className="py-1 ml-6 text-gray-900 border-l-2 border-gray-600 cursor-pointer rounded-r-md">
      <Link href={route}>
        <div
          className={`flex items-center mx-4 px-4 py-2 rounded-md hover:bg-gray-200 ${
            to !== "/" && router.asPath.includes(to)
              ? "bg-gray-600 text-white hover:bg-gray-600"
              : "font-medium"
          }`}
        >
          <Icon className="flex-initial w-6 h-6 mr-3" />
          <p className="ml-3">{label}</p>
        </div>
      </Link>
    </div>
  )
}

export default SubLink
