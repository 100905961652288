import {
  CenterSkew,
  HeavyLeftSkew,
  HeavyRightSkew,
  LeftSkew,
  RightSkew,
} from "@behaviour-lab/blab-component-library"

import { SkewEnum } from "src/types/common"

export const SkewIcons: {
  [key in SkewEnum]: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
} = {
  [SkewEnum.CenterSkew]: CenterSkew,
  [SkewEnum.HeavyLeftSkew]: HeavyLeftSkew,
  [SkewEnum.HeavyRightSkew]: HeavyRightSkew,
  [SkewEnum.LeftSkew]: LeftSkew,
  [SkewEnum.RightSkew]: RightSkew,
}
