import * as React from "react"

import {
  Listbox,
  ListboxDefaultOptionType,
} from "@behaviour-lab/blab-component-library"
import { useDispatch, useSelector } from "react-redux"

import { useSection } from "src/context/section-context"
import { getAnalyticsSettingsAllByIdSelector } from "src/redux/analytics-settings/selectors"
import { updateSectionConfigsSelectedValues } from "src/redux/section-settings/actions"
import { getSectionSettingConfigsSelector } from "src/redux/section-settings/selectors"
import { ConfigsStoreType } from "src/redux/types"
import {
  ConfigNameEnum,
  SectionNameEnum,
  AllAnalyticsSettingsType,
  OptionType,
} from "src/types/common"
import { useSelectorWithArguments } from "src/utils/hooks"

const HeaderConfigs = () => {
  const dispatch = useDispatch()

  const { id } = useSection()
  const configs: ConfigsStoreType | undefined = useSelectorWithArguments<
    SectionNameEnum,
    ConfigsStoreType | undefined
  >(getSectionSettingConfigsSelector, id)
  const configOptions: AllAnalyticsSettingsType = useSelector(
    getAnalyticsSettingsAllByIdSelector
  )

  return (
    <div>
      {Object.entries(configs || {}).map(
        (item: [ConfigNameEnum | string, string]) => {
          const options = configOptions[
            item[0] as ConfigNameEnum
          ]?.data?.options?.map((option: OptionType) => {
            return {
              label: option.value,
              value: option.value,
            }
          }) as ListboxDefaultOptionType[]

          return (
            // Only show Benchmark on the headers for now. All the other configs will be shown in the filter panel
            item[0] === ConfigNameEnum.BENCHMARK_SEDOL && (
              <Listbox
                key={item[0]}
                label={configOptions[item[0]]?.data?.displayName || ""}
                options={options}
                selectedValue={item[1].toString()}
                onChange={({ value }: ListboxDefaultOptionType) => {
                  dispatch(
                    updateSectionConfigsSelectedValues({
                      sectionId: id,
                      data: {
                        ...configs,
                        [item[0]]: value,
                      },
                    })
                  )
                }}
              />
            )
          )
        }
      )}
    </div>
  )
}

export default HeaderConfigs
