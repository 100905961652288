import * as React from "react"

import { MenuIcon } from "@behaviour-lab/blab-component-library"

interface IPropTypes {
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
}

/**
 * Creates a CollapseButton component that opens and closes the mobile nav
 * * REQUIRED PROPS
 * @param  {boolean} collapsed Boolean that indicates whether the mobile nav is open or not
 * @param  {Function} setCollapsed Function that toggles the value of collapsed between true and false
 * @return {JSX.Element} Returns the CollapseButton component
 */
const CollapseButton = ({ collapsed, setCollapsed }: IPropTypes) => {
  return (
    <button
      onClick={() => setCollapsed(!collapsed)}
      className="p-4 m-3 rounded-md mobile-menu-button hover:bg-gray-200 focus:bg-gray-200"
    >
      <MenuIcon className="w-5 h-5" />
    </button>
  )
}

export default CollapseButton
