import * as React from "react"

interface IPropTypes {
  children: string
}

const Title = ({ children }: IPropTypes) => {
  return <div className="text-lg font-bold">{children}</div>
}

export default Title
