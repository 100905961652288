import * as React from "react"

import { useDispatch, useSelector } from "react-redux"

import { useFilterPanel } from "src/context/filter-panel-context"
import { useSection } from "src/context/section-context"
import { getAnalyticsSettingsAllByIdSelector } from "src/redux/analytics-settings/selectors"
import { updateSectionFilterSelectedValues } from "src/redux/section-settings/actions"
import { getSectionSettingsSelector } from "src/redux/section-settings/selectors"
import { SectionSettingsStoreType } from "src/redux/types"
import { FilterNameEnum, SectionNameEnum } from "src/types/common"
import { useSelectorWithArguments } from "src/utils/hooks"

import { filtersDictionary } from "../constants"
import { FilterWithPopover } from "../FilterWithPopover"
import { createAppliedFiltersLabel, formatFilterPayload } from "../functions"

interface IProps {
  filterId: FilterNameEnum
}

const MainFilter = ({ filterId }: IProps) => {
  const dispatch = useDispatch()
  const { id } = useSection()
  const { filterState, setFilterState } = useFilterPanel()
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const settings = useSelector(getAnalyticsSettingsAllByIdSelector)
  const sectionSettings = useSelectorWithArguments<
    SectionNameEnum,
    SectionSettingsStoreType | undefined
  >(getSectionSettingsSelector, id)

  const filterData = settings[filterId]?.data

  const isMainFilterSameAsSplitFilter =
    filterId === sectionSettings?.splitFilterId

  const activeFilterValues = sectionSettings?.filters?.[filterId] || []

  const handleUpdate = (selectedValue: string) => {
    if (!selectedValue) return

    setFilterState({
      ...filterState,
      [filterId]: [...(filterState[filterId] || []), selectedValue],
    })
  }

  const handleDelete = (deletedValue: string) => {
    setFilterState({
      ...filterState,
      [filterId]: filterState[filterId]?.filter(
        value => value !== deletedValue
      ),
    })
  }

  const closePopover = () => {
    buttonRef.current?.click()
  }

  const handleClear = () => {
    const newFilters = { ...sectionSettings?.filters }
    delete newFilters[filterId]

    dispatch(
      updateSectionFilterSelectedValues({
        sectionId: id,
        data: newFilters,
      })
    )

    closePopover()
  }

  const handleSubmit = () => {
    dispatch(
      updateSectionFilterSelectedValues({
        sectionId: id,
        data: formatFilterPayload(filterState),
      })
    )

    closePopover()
  }

  const alignStateOnClose = () => {
    const selectedFilters = filterState?.[filterId] || []

    if (JSON.stringify(activeFilterValues) === JSON.stringify(selectedFilters))
      return

    setFilterState({
      ...filterState,
      [filterId]: activeFilterValues,
    })
  }

  return (
    <FilterWithPopover
      ref={buttonRef}
      title={
        (filterData?.name &&
          filtersDictionary[filterData.name as FilterNameEnum]) ||
        filterData?.displayName ||
        ""
      }
      values={filterState?.[filterId] || []}
      options={filterData?.options || []}
      appliedFiltersLabel={createAppliedFiltersLabel(activeFilterValues)}
      handleUpdate={handleUpdate}
      handleDelete={handleDelete}
      handleClear={handleClear}
      handleSubmit={handleSubmit}
      disabled={isMainFilterSameAsSplitFilter}
      onClose={alignStateOnClose}
    />
  )
}

export default MainFilter
