import * as React from "react"

import {
  ArrowRightIcon,
  LogoutOutline,
  ArrowLeftIcon,
  UserOutline,
  LoadingSpinner,
  Button,
  ButtonVariantEnum,
  useAuth,
} from "@behaviour-lab/blab-component-library"
import { useDispatch } from "react-redux"

import { ErrorMessage } from "src/components/Forms/ErrorMessage"
import { LogoWithText } from "src/components/Logo"
import { UserInfo } from "src/components/UserInfo"
import { toggleExternalSidebar } from "src/redux/UI/actions"
import { ClientType } from "src/types/common"
import { useClientSettings } from "src/utils/hooks"

interface IPropTypes {
  collapsed: boolean
}

/**
 * Creates an OpenSidebar component that displays the open variation of the sidebar
 * * REQUIRED PROPS
 * @param {boolean} collapsed - Boolean that indicates whether the sidebar is collapsed
 * @returns
 */
const OpenSidebar = ({ collapsed }: IPropTypes) => {
  const dispatch = useDispatch()
  const { user, initials, organisation, logout } = useAuth()
  const { pending, error, clients } = useClientSettings()

  return (
    <div className="flex flex-col justify-between h-full px-4">
      <div>
        <div className="flex items-center justify-center justify-between w-full mt-6">
          <Button href="/" variant={ButtonVariantEnum.Pure}>
            <LogoWithText />
          </Button>
          <Button
            onClick={() => dispatch(toggleExternalSidebar())}
            variant={ButtonVariantEnum.Grey}
            className="justify-center w-12 h-8 px-0 py-0"
          >
            {collapsed ? (
              <ArrowRightIcon className="w-4 h-4 text-gray-500" />
            ) : (
              <ArrowLeftIcon className="w-4 h-4 text-gray-500" />
            )}
          </Button>
        </div>
        {initials && user?.attributes?.email && organisation && (
          <div className="my-8">
            <UserInfo
              initials={initials}
              organisation={organisation}
              email={user.attributes.email}
            />
          </div>
        )}
        <div className="mt-10">
          <h3 className="mb-3 text-gray-400">Organizations</h3>
          <div>
            {pending ? (
              <LoadingSpinner />
            ) : error ? (
              <ErrorMessage text="Something went wrong" />
            ) : (
              clients.map(({ id, name }: ClientType) => (
                <Button
                  key={id}
                  href={`/setup/client?clientID=${id}`}
                  variant={ButtonVariantEnum.Transparent}
                >
                  <UserOutline className="w-5 h-5 text-gray-400" />
                  {name}
                </Button>
              ))
            )}
          </div>
        </div>
      </div>
      <div>
        <Button
          onClick={logout}
          variant={ButtonVariantEnum.Grey}
          className="justify-center w-full my-4"
        >
          <LogoutOutline className="w-6 h-6" />
          Logout
        </Button>
      </div>
    </div>
  )
}

export default OpenSidebar
