import * as React from "react"

import { WeightingOutline } from "@behaviour-lab/blab-component-library"

import { WEIGHTING_ROUTE } from "../routes"

type IconProps = {
  className?: string
}

const weightingLink = {
  label: "Weighting",
  to: WEIGHTING_ROUTE,
  Icon: ({ className }: IconProps) => (
    <WeightingOutline className={className} />
  ),
}

export default weightingLink
