import * as React from "react"

import {
  LogoutOutline,
  UserOutline,
  useOnClickOutside,
  LoadingSpinner,
  Button,
  ButtonVariantEnum,
  useAuth,
  getInitials,
  getDomainName,
} from "@behaviour-lab/blab-component-library"
import Link from "next/link"

import { Logo } from "src/components/Logo"
import { ErrorMessage } from "src/components/Setup/ErrorMessage"
import { ClientType } from "src/types/common"
import { useClientSettings } from "src/utils/hooks"

import { CollapseButton } from "./CollapseButon"

/**
 * Creates a MobileNav component with a logo, a button and an open or collapsed mobile navigation menu depending on component's state.
 * @return {JSX.Element} Returns the MobileNav component
 */
const MobileNav = () => {
  const { user, logout } = useAuth()
  const { pending, error, clients } = useClientSettings()

  const [collapsed, setCollapsed] = React.useState(true)
  const ref = React.useRef() as React.RefObject<HTMLDivElement>

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setCollapsed(true))

  return (
    <div ref={ref} className="bg-white border-b shadow-sm">
      <div className="flex justify-between 2xl:hidden">
        <Button href="/" variant={ButtonVariantEnum.Pure} className="mx-4">
          <Logo />
        </Button>
        <CollapseButton collapsed={collapsed} setCollapsed={setCollapsed} />
      </div>

      {!collapsed && (
        <div
          // h-[calc(100vh-76px)] - 76px is header height, not extract 76 to constant as tailwind doesn't calculate it properly
          className="relative px-8 pt-4 pb-6 h-[calc(100vh-76px)] overflow-auto bg-white 2xl:hidden border-t"
        >
          <div className="flex items-center gap-x-3">
            <div className="flex items-center text-sm font-bold w-10 h-10 p-2.5 bg-gray-300 rounded-full align-center drop-shadow">
              {user?.attributes?.email
                ? getInitials(user.attributes.email)
                : ""}
            </div>
            <div>
              {user && (
                <p className="text-sm font-bold">
                  {user.attributes?.email ? user.attributes.email : ""}
                </p>
              )}
              {user?.attributes?.email && (
                <p className="text-sm">
                  {getDomainName(user.attributes.email)}
                </p>
              )}
            </div>
          </div>
          <h3 className="mt-6 mb-2 text-gray-400">Clients</h3>
          <div className={`${pending ? "py-6" : ""}`}>
            {pending ? (
              <LoadingSpinner />
            ) : error ? (
              <ErrorMessage short />
            ) : (
              clients.map(({ id, name }: ClientType) => (
                <Link
                  key={id}
                  href={{
                    pathname: "/setup/client",
                    query: { clientID: id },
                  }}
                >
                  <span className="flex items-center px-3 py-2 my-1 text-left rounded-md gap-x-2 hover:bg-gray-100">
                    <UserOutline className="w-5 h-5 text-gray-400" />
                    {name}
                  </span>
                </Link>
              ))
            )}
          </div>
          <Button
            onClick={logout}
            variant={ButtonVariantEnum.Grey}
            className="my-4"
          >
            <LogoutOutline className="w-6 h-6" />
            Logout
          </Button>
        </div>
      )}
    </div>
  )
}

export default MobileNav
