import * as React from "react"

import { EyeOutline } from "@behaviour-lab/blab-component-library"

import { OVERVIEW_ROUTE } from "../routes"

type IconProps = {
  className?: string
}

const overviewLink = {
  label: "Overview",
  to: OVERVIEW_ROUTE,
  Icon: ({ className }: IconProps) => <EyeOutline className={className} />,
}

export default overviewLink
