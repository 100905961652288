import * as React from "react"

import {
  Button,
  ButtonVariantEnum,
  XMarkIcon,
} from "@behaviour-lab/blab-component-library"

import { useSection } from "src/context/section-context"

interface IProps {
  className?: string
}

const Header = ({ className = "" }: IProps) => {
  const { setShowFilterPanel, setShowSecondaryPanel } = useSection()

  const handleCloseFilterPanel = () => {
    setShowFilterPanel(false)
    setShowSecondaryPanel(true)
  }

  return (
    <div className={`flex items-start justify-between ${className}`}>
      <div className="flex flex-col gap-y-2">
        <h4 className="text-2xl font-bold text-gray-900">Filters</h4>
        <p className="text-lg">
          Filter the data in this section to explore the data.
        </p>
      </div>
      <Button
        onClick={handleCloseFilterPanel}
        variant={ButtonVariantEnum.Transparent}
      >
        <XMarkIcon className="w-5 h-5 text-gray-800" />
      </Button>
    </div>
  )
}

export default Header
