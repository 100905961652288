import * as React from "react"

import { Menu } from "@headlessui/react"

interface IProps {
  content: string
  onClick: () => void
}

const Option = ({ onClick, content }: IProps) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          onClick={onClick}
          className={`${
            active ? "bg-gray-100 text-gray-900" : "text-gray-700"
          } w-full text-left block px-4 py-2 text-sm`}
        >
          {content}
        </button>
      )}
    </Menu.Item>
  )
}

export default Option
