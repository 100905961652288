import * as React from "react"

const ErrorLoadingIcon = () => {
  return (
    <svg
      width="52"
      height="49"
      viewBox="0 0 52 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M32 37.1668C24.7734 37.1668 18.6667 33.7752 18.6667 29.7602V11.2402C18.6667 7.22516 24.7734 3.8335 32 3.8335C39.2267 3.8335 45.3334 7.22516 45.3334 11.2402V29.7602C45.3334 33.7752 39.2284 37.1668 32 37.1668ZM22 25.3335V29.7652C22.1167 30.6868 25.8484 33.4685 32 33.4685C38.1517 33.4685 41.885 30.6785 42 29.7552V25.3335C38.9657 27.0925 35.5066 27.9843 32 27.9118C28.4935 27.9844 25.0343 27.0925 22 25.3335ZM22 16.0685V20.5002C22.1167 21.4218 25.8484 24.2035 32 24.2035C38.1517 24.2035 41.885 21.4135 42 20.4902V16.0685C38.9659 17.8281 35.5067 18.7206 32 18.6485C28.4933 18.7207 25.0341 17.8282 22 16.0685ZM32 7.53683C25.8467 7.53683 22.115 10.3268 22 11.2518C22.1167 12.1668 25.8517 14.9435 32 14.9435C38.1484 14.9435 41.885 12.1535 42 11.2302C41.8834 10.3118 38.1484 7.53683 32 7.53683Z"
          fill="#BF7373"
        />
      </g>
      <path
        d="M18 27.49L25.53 40.5H10.47L18 27.49ZM18 23.5L7 42.5H29L18 23.5ZM19 37.5H17V39.5H19V37.5ZM19 31.5H17V35.5H19V31.5Z"
        fill="#BF7373"
      />
      <g
        opacity="0.2"
        clipPath="url(#clip0_1002_3431)"
        filter="url(#filter0_f_1002_3431)"
      >
        <path
          d="M13.3186 27.7299L9.72841 42.3269L24.1872 38.1143L13.3186 27.7299ZM12.2025 23.8992L28.0782 39.0638L6.95637 45.2177L12.2025 23.8992ZM15.1586 37.6201L17.0787 37.0606L17.6382 38.9808L15.718 39.5402L15.1586 37.6201ZM13.4802 31.8596L15.4004 31.3001L16.5193 35.1405L14.5991 35.6999L13.4802 31.8596Z"
          fill="#BF7373"
        />
      </g>
      <path
        d="M12.8886 22.0721L9.06055 17.4219"
        stroke="#BF7373"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9.59407 28.2328L4.35498 25.2612"
        stroke="#BF7373"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18.32 19.5975L18.2168 13.5752"
        stroke="#BF7373"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_f_1002_3431"
          x="-2.87787"
          y="15.6226"
          width="35.7552"
          height="35.7554"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1.5"
            result="effect1_foregroundBlur_1002_3431"
          />
        </filter>
        <clipPath id="clip0_1002_3431">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-0.960081 0.279722 0.279722 0.960081 23.1641 18.6226)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ErrorLoadingIcon
