import * as React from "react"

import Head from "next/head"

interface IPropTypes {
  title: string
}

const Seo = ({ title }: IPropTypes) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta property="og:title" content={title} key="title" />
      <link rel="icon" href="/favicon.ico" />
    </Head>
  )
}

export default Seo
