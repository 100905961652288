import * as React from "react"

import { ConstructionSolid } from "@behaviour-lab/blab-component-library"

import { PORTFOLIO_CONSTRUCTION_ROUTE } from "../routes"

type IconProps = {
  className?: string
}

const portfolioConstructionLink = {
  label: "Portfolio Construction",
  to: PORTFOLIO_CONSTRUCTION_ROUTE,
  Icon: ({ className }: IconProps) => (
    <ConstructionSolid className={className} />
  ),
}

export default portfolioConstructionLink
