import * as React from "react"

import { useSearchWithSuggestions } from "src/utils/hooks"

interface IProps {
  allValues: string[]
  selectedValues: string[]
  suggestionLimit?: number
  onSelect: (value: string) => void
}

const SearchWithSuggestions = ({
  allValues,
  suggestionLimit,
  selectedValues,
  onSelect,
}: IProps) => {
  const outsideClickElRef = React.useRef<HTMLDivElement>(null)

  const {
    inputValue,
    suggestionList,
    selectedIndex,
    handleInputChange,
    handleInputFocus,
    handleKeyDown,
    handleClickSuggestion,
  } = useSearchWithSuggestions({
    allValues,
    selectedValues,
    suggestionLimit,
    onSelectSuggestion: onSelect,
    outsideClickElRef,
  })

  return (
    <div
      className="relative z-50 w-full bg-white border border-gray-300 rounded"
      ref={outsideClickElRef}
    >
      <input
        className="w-full py-2 pl-3 border-none rounded focus:outline-0 focus:ring-primary"
        type="text"
        name="search-bar"
        id="search-with-suggestions"
        placeholder="+ Type to Add..."
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={handleInputFocus}
      />
      {!!suggestionList.length && (
        <div className="absolute left-0 flex flex-col w-full overflow-y-scroll border top-10 drop-shadow max-h-40">
          {suggestionList.map((item: string, index: number) => {
            return (
              <button
                key={item}
                value={item}
                className={`${
                  index === selectedIndex ? "bg-gray-200" : "bg-white"
                } w-full py-2 pl-3 text-left border-none shadow-lg hover:bg-gray-200 focus:outline-0 active:bg-gray-300`}
                name="suggestion"
                id="search-with-suggestions-suggestion"
                onClick={() => handleClickSuggestion(item)}
              >
                {item}
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default SearchWithSuggestions
