import * as React from "react"

import {
  Button,
  ButtonVariantEnum,
  FilterOutline,
} from "@behaviour-lab/blab-component-library"

import { useSection } from "src/context/section-context"

const FilterPanelButton = () => {
  const { showFilterPanel, setShowFilterPanel } = useSection()

  const togglePanel = () => setShowFilterPanel(!showFilterPanel)

  return (
    <Button onClick={togglePanel} variant={ButtonVariantEnum.White}>
      <p className="hidden font-semibold xl:block">More Filters</p>
      <FilterOutline className="w-5 h-5 text-gray-900" />
    </Button>
  )
}

export default FilterPanelButton
