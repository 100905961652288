import * as React from "react"

import {
  ArrowRightIcon,
  ArrowLeftIcon,
  LogoutOutline,
  RefreshIcon,
  ButtonVariantEnum,
  Button,
  useAuth,
} from "@behaviour-lab/blab-component-library"
import { useDispatch } from "react-redux"

import { LogoWithText } from "src/components/Logo/LogoWithText"
import { UserInfo } from "src/components/UserInfo"
import ORDERED_LINKS from "src/data/navigation/orderedLinks"
import { OVERVIEW_ROUTE } from "src/data/navigation/routes"
import { toggleInternalSidebar } from "src/redux/UI/actions"
import { TopLevelNavigationLinkType } from "src/types/common"
import { createInternalRoute, useClientSettings } from "src/utils/hooks"

import SidebarLink from "../../Links/SidebarLink"

interface IPropTypes {
  collapsed: boolean
}

/**
 * Creates an OpenSidebar component that contains a profile section, a collapse button and a navigation
 * * REQUIRED PROPS
 * @param  {boolean} collapsed Boolean that indicates whether the sidebar is collapsed or not
 * @return {JSX.Element} Returns the OpenSidebar component
 */
const OpenSidebar = ({ collapsed }: IPropTypes) => {
  const dispatch = useDispatch()
  const { user, initials, organisation, logout } = useAuth()

  const { clientID, portfolioID, portfolio } = useClientSettings()
  return (
    <div className="relative z-30 flex flex-col w-full h-screen px-4 overflow-y-scroll bg-white border-r border-gray-200 shadow items-between">
      {/* Top of Sidebar - Logo, Profile and Collapse Toggle */}
      <div className="flex items-center justify-between mt-6">
        <div className="grow">
          <Button
            href={createInternalRoute(
              OVERVIEW_ROUTE,
              `${clientID}`,
              `${portfolioID}`
            )}
            variant={ButtonVariantEnum.Pure}
          >
            <LogoWithText />
          </Button>
          {/* pl-[52px] needed to align portfolio.name NOT with the logo, but the text inside LogoWithText*/}
          <div className="flex items-center gap-x-2 pl-[52px]">
            {portfolio?.name && (
              <div
                className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[130px]"
                title={portfolio.name}
              >
                {portfolio.name}
              </div>
            )}
            <Button
              href="/"
              variant={ButtonVariantEnum.Pure}
              className="shadow px-0.5 py-0.5 hover:bg-gray-200"
            >
              <RefreshIcon className="w-4 h-4" />
            </Button>
          </div>
        </div>
        <Button
          onClick={() => dispatch(toggleInternalSidebar())}
          variant={ButtonVariantEnum.Grey}
          className="justify-center w-12 h-8 px-0 py-0 shrink-0"
        >
          {collapsed ? (
            <ArrowRightIcon className="w-4 h-4 text-gray-500" />
          ) : (
            <ArrowLeftIcon className="w-4 h-4 text-gray-500" />
          )}
        </Button>
      </div>
      <div className="my-8">
        {initials && user?.attributes?.email && organisation && (
          <UserInfo
            initials={initials}
            organisation={organisation}
            email={user.attributes.email}
          />
        )}
      </div>
      {/* Main body of Sidebar - Links and subLinks */}
      <nav className="flex flex-col h-full">
        <div className="flex-grow">
          {ORDERED_LINKS.map(
            (
              { label, to, Icon, subMenuItems }: TopLevelNavigationLinkType,
              i
            ) => (
              <SidebarLink
                key={i}
                label={label}
                to={to}
                Icon={Icon}
                subMenuItems={subMenuItems}
              />
            )
          )}
        </div>

        {/* Bottom of Sidebar - Logout Button */}
        <div>
          <Button
            onClick={logout}
            variant={ButtonVariantEnum.Pure}
            className="w-full p-4 my-4 gap-x-3 hover:bg-gray-200"
          >
            <LogoutOutline className="w-6 h-6" />
            <p className="font-medium text-left">Logout</p>
          </Button>
        </div>
      </nav>
    </div>
  )
}

export default OpenSidebar
