import * as React from "react"

import { Panel } from "../Panel"

interface IPropTypes {
  children: React.ReactNode
}

/**
 * Creates a content component that shows the tabs, panels and content inside those panels from a section
 * @param {React.ReactNode} children react children prop
 * @returns {JSX.Element} returns Content component
 */
const Content = ({ children }: IPropTypes) => {
  return (
    <div className="relative flex flex-col flex-grow h-full p-4 gap-x-4">
      {children}
      <Panel type="filter" />
    </div>
  )
}

export default Content
