import * as d3 from "d3"
/**
 * Helper function to add a line graph bisector tooltip with html content to a visualization
 * This logic can be quite tricky: the function detects whether the mouse
 * is in the left or right half of the container. Depending on the quadrant,
 * adjustments are made to the positioning of the tooltip so that it
 * does not move outside the container.
 *
 * * REQUIRED PARAMETERS:
 * @param {d3.Selection<HTMLInputElement, unknown, null, undefined>} bisector - d3 selection of the tooltip element
 * @param {React.MouseEvent} event - The mouse event
 * @param {string} contentLeft - The html content that will be formatted with the left alignment
 * @param {DOMRect} dimensions - The bounding rect (dimensions) of the container
 * @param {string} contentRight - The html content that will be formatted with the right alignment
 *
 * * RETURNS
 * @return {void}
 */
export function addBisector({
  bisector,
  event,
  contentLeft,
  dimensions,
  contentRight,
}: {
  bisector: d3.Selection<HTMLInputElement, unknown, null, undefined>
  event: React.MouseEvent
  contentLeft: string
  dimensions: DOMRect
  contentRight: string
}) {
  const { width, left, right, top, bottom } = dimensions
  const [mouseX] = d3.pointer(event)

  /**
   * Position the tooltip such that it is always inside the visualization by
   * moving it to the left, right, top or bottom of the mouse depending on
   * the quadrant the mouse is in.
   */
  mouseX <= width / 2
    ? bisector
        .style("opacity", 1)
        .style("position", "absolute")
        .style("top", `${top}px`)
        .style("bottom", `${bottom}px`)
        .style("left", mouseX <= width / 2 ? `${left + mouseX}px` : "initial")
        .style(
          "right",
          mouseX > width / 2 ? `${right - mouseX - 128}px` : "initial"
        )
        .html(contentLeft)
    : bisector
        .style("opacity", 1)
        .style("position", "absolute")
        .style("top", `${top}px`)
        .style("bottom", `${bottom}px`)
        .style("left", mouseX <= width / 2 ? `${left + mouseX}px` : "initial")
        .style("right", mouseX > width / 2 ? `${right - mouseX}px` : "initial")
        .html(contentRight)
}
