import * as React from "react"

import {
  SomethingBrokenIcon,
  RefreshIcon,
  Button,
  ButtonVariantEnum,
} from "@behaviour-lab/blab-component-library"

export interface ISomethingBrokeProps {
  numberOfRetries: number
  maxRetries: number
  onRetry?: () => void | Promise<void>
  className?: string
}

const SomethingBroke = ({
  onRetry,
  numberOfRetries,
  maxRetries,
  className = "",
}: ISomethingBrokeProps) => {
  return (
    <div
      className={`flex flex-col items-center justify-center flex-grow w-full h-full ${className}`}
    >
      <SomethingBrokenIcon className="w-64 h-48 mx-auto" />
      <div className="text-center">
        <p className="text-3xl font-bold text-gray-100">Something Broke!</p>
        <p className="text-lg text-gray-100">We&apos;re working on it.</p>
      </div>
      {numberOfRetries !== maxRetries && (
        <Button
          variant={ButtonVariantEnum.Outlined}
          className="mt-2"
          onClick={onRetry}
        >
          <RefreshIcon className="w-5 h-5" />
          <span>{`Click to Retry (${numberOfRetries}/${maxRetries})`}</span>
        </Button>
      )}
    </div>
  )
}

export default SomethingBroke
