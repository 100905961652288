import * as React from "react"

interface IPropTypes {
  initials: string
  email?: string
  organisation?: string
}

/**
 * Returns a UserInfo component that displays the user's initials, organisation and email address. Omit the email and organisation on the collapsed sidebar to only show the badge with the initials.
 * REQUIRED PROPS
 * @param {string} initials - The user's initials
 * OPTIONAL PROPS
 * @param {string} email - The user's email address
 * @param {string} organisation - The user's organisation
 * @returns {JSX.Element} Returns the UserInfo component
 */
const UserInfo = ({ initials, email, organisation }: IPropTypes) => {
  return (
    <div className="flex flex-col items-center justify-center gap-y-3">
      <div className="flex items-center font-bold w-12 h-12 p-2.5 bg-gray-300 justify-center rounded-full align-center drop-shadow">
        {initials}
      </div>
      {email && organisation && (
        <div className="text-center">
          <p className="font-bold">{email}</p>
          <p className="">{organisation}</p>
        </div>
      )}
    </div>
  )
}

export default UserInfo
