import * as d3 from "d3"

import { SplitRequestEnum } from "src/types/common"

export type SelectionItemType = {
  selectTag: string
  filterFn: d3.ValueFn<d3.BaseType, any, boolean>
  filterOnNodes?: boolean
  // Nested select is used for HorizontalStackedBarGraph  and VerticalStackedBarGraph interactions, when click on visualization should highlight only one rect inside the whole bar
  nestedSelect?: {
    selectTag: string
    filterFn: d3.ValueFn<d3.BaseType, any, boolean>
    filterOnNodes?: boolean
  }
}

export const applyInteractionToSelection = ({
  selectionItem,
  split,
  sectionRef,
}: {
  selectionItem: SelectionItemType
  split?: SplitRequestEnum
  sectionRef: React.RefObject<HTMLDivElement>
}) => {
  const { selectTag, filterOnNodes, filterFn, nestedSelect } = selectionItem

  const allSelection = selectByTagAndSplit(sectionRef, selectTag, split)
  const selectionItems = filterOnNodes ? allSelection.nodes() : allSelection

  // Apply current interaction
  const filteredNodes = selectionItems.filter(filterFn)
  const withNestedSelect = nestedSelect
    ? d3
        .selectAll(d3.selectAll(filteredNodes).nodes())
        .selectAll(nestedSelect.selectTag)
        .filter(nestedSelect.filterFn)
    : filteredNodes

  d3.selectAll(withNestedSelect).style("opacity", 0.2)

  if (!nestedSelect && split && split !== SplitRequestEnum.STANDARD) {
    const oppositeSplit =
      split === SplitRequestEnum.SPLIT_A
        ? SplitRequestEnum.SPLIT_B
        : SplitRequestEnum.SPLIT_A

    const allSelectionOfOppositeSplit = selectByTagAndSplit(
      sectionRef,
      selectTag,
      oppositeSplit
    )
    const oppositeSplitSelectionItems = filterOnNodes
      ? allSelectionOfOppositeSplit.nodes()
      : allSelectionOfOppositeSplit

    d3.selectAll(oppositeSplitSelectionItems).style("opacity", 0.2)
  }
}

export const resetAllInteractions = ({
  selectionData,
  sectionRef,
}: {
  selectionData: SelectionItemType[]
  sectionRef: React.RefObject<HTMLDivElement>
}) => {
  const allTags = Array.from(
    selectionData.reduce((acc, selectionItem) => {
      acc.add(selectionItem.selectTag)

      if (selectionItem.nestedSelect)
        acc.add(selectionItem.nestedSelect.selectTag)

      return acc
    }, new Set())
  ) as string[]

  const allSelection = allTags.reduce((acc: d3.BaseType[], tag: string) => {
    return [...acc, ...selectByTagAndSplit(sectionRef, tag).nodes()]
  }, [])

  if (!allSelection.length) return

  // Reset all previous interaction to all tags of interaction
  d3.selectAll(allSelection).style("opacity", 1)
}

export const selectByTagAndSplit = (
  sectionRef: React.RefObject<HTMLDivElement>,
  tag: string,
  split?: SplitRequestEnum
) => d3.select(sectionRef.current).selectAll(split ? `.${split} ${tag}` : tag)
