import * as React from "react"

import Link from "next/link"

interface IProps {
  route: string
  Icon: (arg: { className?: string }) => React.ReactElement
  isActive: boolean
  isSubLink?: boolean
  onMouseEnter?: (event: React.MouseEvent) => void
  onMouseLeave?: (event: React.MouseEvent) => void
}

const IconLink = ({
  route,
  Icon,
  isActive,
  isSubLink,
  onMouseEnter,
  onMouseLeave,
}: IProps) => {
  return (
    <Link href={route}>
      <div
        className={`justify-center px-5 py-5 cursor-pointer align-center ${
          isActive
            ? `${
                isSubLink
                  ? "bg-gray-600 text-white"
                  : "bg-gray-300 text-gray-500"
              }`
            : "bg-white hover:bg-gray-200 text-gray-500"
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Icon className="w-full h-7" />
      </div>
    </Link>
  )
}

export default IconLink
