import * as React from "react"

import { LoadingSpinner } from "@behaviour-lab/blab-component-library"
import { useSelector } from "react-redux"

import {
  IErrorLoadingDataProps,
  SomethingBroke,
  ISomethingBrokeProps,
} from "src/components/Errors"
import { useSection } from "src/context/section-context"
import { getCurrentAnalyticsSettingsSelector } from "src/redux/analytics-settings/selectors"
import { PluginResultData } from "src/redux/types"
import {
  PluginNameEnum,
  SplitRequestEnum,
  InjectedParamsType,
} from "src/types/common"

import { ParentPanelEnum } from "../../types"
import { ExternalWrapper } from "../ExternalWrapper"
import { SectionWrapper } from "../SectionWrapper"

interface IPropTypes {
  stateId: PluginNameEnum
  render: (data: PluginResultData, split?: SplitRequestEnum) => React.ReactNode
  injectedParams?: InjectedParamsType
  noSplit?: boolean
  notInSection?: boolean
  LoadingState?: React.FC
  ErrorState?: React.FC<ISomethingBrokeProps & IErrorLoadingDataProps>
  parentPanel?: ParentPanelEnum
}

const MAX_RETRIES = 2

/**
 * The DataQuery forms the foundation of every interaction the Front-End makes with the analytics infrastructure.
 * It can be used both in and out of the Generic Section Component that makes up the majority of Polygon, and it
 * allows us to abstract parameters, loading and error state handling, API calls, and more from individual section
 * implementations.
 * @param {PluginNameEnum} stateId - The stateId of the data query, referencing the unique id of the data query
 * @param {RenderFunctionType} render - Render function that can be used to intercept the response from the API call to visualize the data using any desired visualization
 * * OPTIONAL  PROPS
 * @param {InjectedParamsType} injectedParams - Any parameters that should be injected into the API call above and beyond those that are inherited from the section or the global redux store
 * @param {boolean} noSplit - A boolean that indicates whether the data query can split or not
 * @param {boolean} notInSection - If true, the data query is not in a section component and therefore does not inherit properties from the section context provider
 * @param {(props: any) => JSX.Element} LoadingState - A function that can be used to render a custom loading state that overrides the default
 * @param {(props: any) => JSX.Element} ErrorState - A function that can be used to render a custom error state that overrides the default
 * @param {ParentPanelEnum} parentPanel - The panel type the data query is in
 * @returns {JSX.Element} Returns the DataQuery component
 */
const DataQuery = ({
  stateId,
  injectedParams,
  noSplit = false,
  render,
  notInSection,
  LoadingState = LoadingSpinner,
  ErrorState = SomethingBroke,
  parentPanel,
}: IPropTypes) => {
  const { isSplitActive, lastSplit } = useSection()

  const currGlobalSettings = useSelector(getCurrentAnalyticsSettingsSelector)
  const [numberOfRetries, setNumberOfRetries] = React.useState<number>(0)

  const handleRetry = React.useCallback(() => {
    setNumberOfRetries(numberOfRetries + 1)
  }, [numberOfRetries])

  // If the data query is not in a section it cannot split, so just call the render function once inside ExternalWrapper
  if (notInSection) {
    return (
      <ExternalWrapper
        stateId={stateId}
        injectedParams={injectedParams}
        analyticsSettings={currGlobalSettings}
        LoadingState={LoadingState}
        ErrorState={ErrorState}
        numberOfRetries={numberOfRetries}
        maxRetries={MAX_RETRIES}
        onRetry={handleRetry}
      >
        {({ data }) => render(data)}
      </ExternalWrapper>
    )
  }

  // If split is not active or the section cannot split, call the render function once with the standard split request type
  if (!isSplitActive || noSplit) {
    return (
      <SectionWrapper
        stateId={stateId}
        injectedParams={injectedParams}
        analyticsSettings={currGlobalSettings}
        LoadingState={LoadingState}
        splitReqType={SplitRequestEnum.STANDARD}
        parentPanel={parentPanel}
        ErrorState={ErrorState}
        maxRetries={MAX_RETRIES}
        numberOfRetries={numberOfRetries}
        onRetry={handleRetry}
      >
        {({ data }) => render(data)}
      </SectionWrapper>
    )
  }

  // If the data query is in a tertiary panel, split is active and there is a last split value, call the render function once with the last split request type
  if (parentPanel === ParentPanelEnum.TERTIARY && isSplitActive && lastSplit) {
    return (
      <SectionWrapper
        stateId={stateId}
        injectedParams={injectedParams}
        analyticsSettings={currGlobalSettings}
        LoadingState={LoadingState}
        splitReqType={lastSplit}
        parentPanel={parentPanel}
        ErrorState={ErrorState}
        maxRetries={MAX_RETRIES}
        numberOfRetries={numberOfRetries}
        onRetry={handleRetry}
      >
        {({ data }) => render(data)}
      </SectionWrapper>
    )
  }

  return (
    // If split is active, call the render function twice, once for each split request type
    <>
      <SectionWrapper
        stateId={stateId}
        injectedParams={injectedParams}
        analyticsSettings={currGlobalSettings}
        LoadingState={LoadingState}
        splitReqType={SplitRequestEnum.SPLIT_A}
        parentPanel={parentPanel}
        ErrorState={ErrorState}
        maxRetries={MAX_RETRIES}
        numberOfRetries={numberOfRetries}
        onRetry={handleRetry}
      >
        {({ data }) => render(data, SplitRequestEnum.SPLIT_A)}
      </SectionWrapper>
      <SectionWrapper
        stateId={stateId}
        injectedParams={injectedParams}
        analyticsSettings={currGlobalSettings}
        LoadingState={LoadingState}
        splitReqType={SplitRequestEnum.SPLIT_B}
        parentPanel={parentPanel}
        ErrorState={ErrorState}
        maxRetries={MAX_RETRIES}
        numberOfRetries={numberOfRetries}
        onRetry={handleRetry}
      >
        {({ data }) => render(data, SplitRequestEnum.SPLIT_B)}
      </SectionWrapper>
    </>
  )
}

export default DataQuery
