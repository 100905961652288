import * as React from "react"

interface IPropTypes {
  title?: string
  text: string
  className?: string
}

const PageSummary = React.forwardRef(
  (
    { title = "Summary", text, className = "" }: IPropTypes,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div ref={ref} className={`col-span-12 mb-4 space-y-1 ${className}`}>
        <h2 className="text-xl font-semibold">{title}</h2>
        <p className="font-light text-md">{text}</p>
      </div>
    )
  }
)

PageSummary.displayName = "PageSummary"

export default PageSummary
