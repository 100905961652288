import * as React from "react"

import { TooltipWithIcon } from "@behaviour-lab/blab-component-library"

import { useSection } from "src/context/section-context"
import { SkewEnum } from "src/types/common"
import { SkewIcons } from "src/utils/helpers/mapSkewToSkewIcon"

type KeyMetricType = {
  title: string
  value: string
  skew: SkewEnum
}

interface IPropTypes {
  data: KeyMetricType
}

const KeyMetric = ({ data }: IPropTypes) => {
  const { title, skew, value } = data

  const { tooltips } = useSection()

  const Icon = SkewIcons[skew]

  return (
    // Setting a fixed width to ensure the skew always looks the same since the title is not always "Total Impact" and its lenght will affect the skew dimensions
    <div className="w-40 space-y-1">
      <div className="flex gap-x-1">
        <div className="flex-initial">
          <h6 className="text-sm text-white font-extralight whitespace-nowrap">
            {title}
          </h6>
        </div>
        {tooltips?.totalImpact && (
          <TooltipWithIcon
            className="text-white"
            tooltipClassName="min-w-[300px] z-[60]"
          >
            {tooltips.totalImpact}
          </TooltipWithIcon>
        )}
      </div>
      <div className="flex items-center text-white gap-x-2">
        <div className="flex-initial">
          <p className="text-lg font-bold leading-none">
            {isNaN(parseFloat(value)) ? (
              <span className="whitespace-nowrap">-- %</span>
            ) : (
              parseFloat(value).toFixed(2) + "%"
            )}
          </p>
        </div>
        {!isNaN(parseFloat(value)) && <Icon width={60} height={40} />}
      </div>
    </div>
  )
}

export default KeyMetric
