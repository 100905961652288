import * as React from "react"

import { useSelector } from "react-redux"

import { useFilterPanel } from "src/context/filter-panel-context"
import { useSection } from "src/context/section-context"
import { getAnalyticsSettingsAllByIdSelector } from "src/redux/analytics-settings/selectors"
import { getSectionSettingsSelector } from "src/redux/section-settings/selectors"
import { SectionSettingsStoreType } from "src/redux/types"
import { SectionNameEnum } from "src/types/common"
import { useSelectorWithArguments } from "src/utils/hooks"

import { Filter } from "../Filter"

interface IProps {
  className?: string
}

const FiltersArea = ({ className = "" }: IProps) => {
  const { filterIds, id } = useSection()
  const { filterState, setFilterState } = useFilterPanel()

  const filtersData = useSelector(getAnalyticsSettingsAllByIdSelector)

  const sectionSettings = useSelectorWithArguments<
    SectionNameEnum,
    SectionSettingsStoreType | undefined
  >(getSectionSettingsSelector, id)

  return (
    <div className={`flex flex-col gap-y-4 h-full ${className}`}>
      {filterIds.map(filterId => {
        const filterData = filtersData?.[filterId]
        const options = filterData?.data?.options?.filter(
          option => !option.default
        )
        const isMainFilterSameAsSplitFilter =
          filterId === sectionSettings?.splitFilterId

        const handleUpdate = (selectedValue: string) => {
          if (!selectedValue) return

          setFilterState({
            ...filterState,
            [filterId]: [...(filterState?.[filterId] || []), selectedValue],
          })
        }

        const handleDelete = (deletedValue: string) => {
          setFilterState({
            ...filterState,
            [filterId]: filterState?.[filterId]?.filter(
              value => value !== deletedValue
            ),
          })
        }

        return (
          options?.length && (
            <Filter
              key={filterData?.id}
              title={filterData?.data?.displayName || ""}
              values={filterState?.[filterId] || []}
              updateFn={handleUpdate}
              deleteFn={handleDelete}
              options={options}
              description={filterData?.description}
              fullWidth
              disabled={isMainFilterSameAsSplitFilter}
            />
          )
        )
      })}
    </div>
  )
}

export default FiltersArea
