import * as React from "react"

import { TabGroup, TabGroupIProps } from "@behaviour-lab/blab-component-library"

import { useSection } from "src/context/section-context"

const SectionTabGroup = ({
  children,
  breakpointWidth,
  onChange,
  panelsClassName,
}: TabGroupIProps) => {
  const { showTertiaryPanel, setShowTertiaryPanel, primaryPanelOnly } =
    useSection()

  const handleOnChange = (tabIndex: number) => {
    if (!primaryPanelOnly && showTertiaryPanel) setShowTertiaryPanel(false)

    onChange?.(tabIndex)
  }

  return (
    <TabGroup
      breakpointWidth={breakpointWidth || 768}
      onChange={handleOnChange}
    >
      {React.Children.map(children, (child, index) => {
        return (
          <TabGroup.Tab
            key={index}
            panelClassName={`flex flex-grow h-full gap-x-12${
              panelsClassName ? ` ${panelsClassName}` : ""
            }`}
            {...child.props}
          >
            {child.props.children}
          </TabGroup.Tab>
        )
      })}
    </TabGroup>
  )
}

export default SectionTabGroup
