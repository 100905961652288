import * as React from "react"

import { getSectionSettingFiltersSelector } from "src/redux/section-settings/selectors"
import { FilterNameEnum, SectionNameEnum } from "src/types/common"
import { useSelectorWithArguments } from "src/utils/hooks"

import { useSection } from "./section-context"

interface IFilterPanelProviderProps {
  children: React.ReactNode
}

export type FilterStateType = {
  [key in FilterNameEnum]?: string[]
}

type FilterPanelContextProps = {
  filterState: FilterStateType
  setFilterState: React.Dispatch<React.SetStateAction<FilterStateType>>
  resetState: () => void
}

const initialState = {
  [FilterNameEnum.BUCKET_FILTER]: [],
  [FilterNameEnum.COUNTRY_FILTER]: [],
  [FilterNameEnum.FUND_MANAGER_FILTER]: [],
  [FilterNameEnum.SECTOR_FILTER]: [],
  [FilterNameEnum.STYLE_FILTER]: [],
  [FilterNameEnum.THEME_FILTER]: [],
}

const FilterPanelContext = React.createContext<FilterPanelContextProps>({
  filterState: initialState,
  setFilterState: () => undefined,
  resetState: () => undefined,
})

const FilterPanelProvider = ({ children }: IFilterPanelProviderProps) => {
  const { filterIds, id } = useSection()

  const sectionFilters = useSelectorWithArguments(
    getSectionSettingFiltersSelector,
    id as SectionNameEnum
  )

  const [filterState, setFilterState] =
    React.useState<FilterStateType>(initialState)

  const resetState = () => setFilterState(initialState)

  // Initialise filterState with redux data and update every time sectionFilters changes
  React.useEffect(() => {
    const newState = { ...filterState }

    filterIds?.forEach(filterId => {
      const filterValue = sectionFilters?.[filterId]

      newState[filterId] = filterValue || []
    })

    setFilterState(newState)
  }, [filterIds, sectionFilters])

  return (
    <FilterPanelContext.Provider
      value={{ filterState, setFilterState, resetState }}
    >
      {children}
    </FilterPanelContext.Provider>
  )
}

const useFilterPanel = () => React.useContext(FilterPanelContext)

export { FilterPanelProvider, useFilterPanel }
