import * as React from "react"

import {
  PrimaryPanel,
  SecondaryPanel,
  TertiaryPanel,
  FilterPanel,
} from "./variations"

type IPropTypes = {
  title?: string
  position?: "absolute" | "relative"
} & (
  | {
      type: "primary" | "secondary" | "tertiary"
      children: React.ReactElement | React.ReactElement[]
    }
  | {
      type: "filter"
      children?: never
    }
)

const Panel = ({
  type,
  title,
  children,
  position = "absolute",
}: IPropTypes) => {
  switch (type) {
    case "primary":
      return <PrimaryPanel>{children}</PrimaryPanel>
    case "secondary":
      return <SecondaryPanel title={title}>{children}</SecondaryPanel>
    case "tertiary":
      return (
        <TertiaryPanel title={title} position={position}>
          {children}
        </TertiaryPanel>
      )
    case "filter":
      return <FilterPanel />
    default:
      return <PrimaryPanel>{children}</PrimaryPanel>
  }
}

export default Panel
