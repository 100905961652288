import { Checker, createCheckers } from "ts-interface-checker"

import { PluginNameEnum } from "src/types/common"

import ResponseTypesTi from "../pluginResponseValidation/pluginResponseTypes-ti"

export const isDataCorrespondentWithInterface = (
  data: unknown,
  Interface?: Checker
): boolean => !!Interface?.test(data)

// Currently, we use this function only for plugins, but if it will be extended, then the type of interfaceName should be extended as well
export const isInterfaceDefined = (interfaceName: PluginNameEnum): boolean =>
  interfaceName in responseInterfaces

export const responseInterfaces = createCheckers(ResponseTypesTi)

export const generateErrorMessageForBrokenPluginResponse = (
  stateId: PluginNameEnum
) => {
  return !isInterfaceDefined(stateId)
    ? `Response interface validation not implemented for ${stateId}.`
    : `Response interface doesn't correspond with the interface for ${stateId}.`
}
