import * as React from "react"

import { LogoIcon } from "@behaviour-lab/blab-component-library"

interface IPropTypes {
  text?: string
}
/**
 * Behaviour Lab Logo with Polygon text
 * @returns {JSX.Element}
 */
const LogoWithText = ({ text = "Polygon" }: IPropTypes) => {
  return (
    <div className="flex items-center">
      <LogoIcon className="text-gray-900" />
      <h4 className="text-lg font-bold">{text}</h4>
    </div>
  )
}

export default LogoWithText
