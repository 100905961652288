// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO: investigate Trie code to fix
function TrieNode(letter: string) {
  // properties
  this.letter = letter
  this.prevLetter = null
  this.nextLetters = {} // an object for the following letters
  this.isComplete = false // check whether letter is last of word

  //methods
  this.getWord = getWord

  // iterates through nodes to get word prediction
  function getWord() {
    // TODO: check if we can avoid eslint @typescript-eslint/no-this-alias
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let node = this
    const wordLetters = []
    while (node.prevLetter) {
      wordLetters.unshift(node.letter)
      node = node.prevLetter // set the previous letter as node
    }

    return wordLetters.join("")
  }
}

function Trie() {
  // properties
  this.root = new TrieNode(null)

  // methods
  this.insert = insert // insert new word in trie
  this.contains = contains // check if word exists
  this.find = find // find words similar with previous letters

  // insert new word in Trie
  function insert(word) {
    let node = this.root // set first node to root node
    for (let i = 0; i < word.length; i++) {
      const current_letter = word[i]
      if (!node.nextLetters[current_letter]) {
        // if letter not in next letters
        node.nextLetters[current_letter] = new TrieNode(current_letter) // make it node
        node.nextLetters[current_letter].prevLetter = node // add it as a child node
      }

      node = node.nextLetters[current_letter] // reset node to current letter & continue iteration

      // check whether whole word is inserted
      if (i === word.length - 1) {
        node.isComplete = true
      }
    }
  }

  // check if word exists
  function contains(word) {
    let node = this.root // set first node to root node
    for (let i = 0; i < word.length; i++) {
      const current_letter = word[i]
      const next_node = node.nextLetters[current_letter]
      if (next_node) {
        // if letter is one of next letters
        node = next_node // set it as a next node
      } else {
        return false
      }
    }

    return node.isComplete // definitely returns 'true'
  }

  // find words with similar previous letters
  function find(clue_letters) {
    let node = this.root // set first node to root node
    const output = []
    for (let i = 0; i < clue_letters.length; i++) {
      const clue_letter = clue_letters[i]
      const next_node = node.nextLetters[clue_letter]
      if (next_node) {
        // if clue letter is one of next letters
        node = next_node // set it as next node
      } else {
        return output
      }
    }

    // use the last node to find the next possible words
    findAllWords(node, output)
    return output
  }

  // function that finds next possible words
  function findAllWords(node, arr) {
    if (node.isComplete) {
      // check if node is end node
      arr.unshift(node.getWord()) // get all words and add them to array
    }

    // otherwise recursively call the next nodes
    for (const next_letter in node.nextLetters) {
      findAllWords(node.nextLetters[next_letter], arr)
    }
  }
}

export default Trie
