export type MetadataType = {
  title: string
  pageSource: string
  location: string
  description: string
}

export enum ParentPanelEnum {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
}

// Making this an object to allow for more flexibility in the future should we have new tooltips.
// Types need to be stronger so TS throws an error if a different key is used
export type SectionTooltipsType = {
  totalImpact?: string
}
