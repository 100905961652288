import * as React from "react"

import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "@behaviour-lab/blab-component-library"
import Link from "next/link"
import { useRouter } from "next/router"
import ReactDOM from "react-dom"
import { useDispatch } from "react-redux"

import { updateSidebarStatus } from "src/redux/UI/actions"
import { getSidebarSettingsByLabelSelector } from "src/redux/UI/selectors"
import { TopLevelNavigationLinkType } from "src/types"
import { useInternalRoutes, useSelectorWithArguments } from "src/utils/hooks"

import IconLink from "./IconLink"
import SubLinks from "./SubLinks"

interface IProps extends TopLevelNavigationLinkType {
  isIconView?: boolean
  onToggleTooltip?: (args: {
    showTooltip: boolean
    tooltipLabel: string
    event: React.MouseEvent
    areSubMenuItems?: boolean
  }) => void
}

/**
 * Creates a SidebarLink component that returns a navigation link
 * * REQUIRED PROPS
 * @param  {string} isIconView Defines the view: true for CollapsedSidebar, false - for Mobile and OpenedSidebar
 * @param  {string} label Name of the page
 * @param  {string} to Link path
 * @param  {Function} Icon Link Icon
 * @param  {Array<{}>} subMenuItems Array of objects containing the sublinks
 * @return {JSX.Element} Returns the SidebarLink component
 */
const SidebarLink = ({
  isIconView,
  label,
  to,
  Icon,
  subMenuItems,
  onToggleTooltip,
}: IProps) => {
  const dispatch = useDispatch()

  const router = useRouter()
  const { route } = useInternalRoutes(subMenuItems ? subMenuItems[0].to : to)

  const [{ showSubMenuItems, submenuY }, setSubmenuItemsState] =
    React.useState<{
      showSubMenuItems: boolean
      submenuY: number
    }>({
      showSubMenuItems: false,
      submenuY: 0,
    })

  // Get Sidebar link open-state from redux
  const sidebarLinkSetting = useSelectorWithArguments(
    getSidebarSettingsByLabelSelector,
    label
  )

  // If this link is set to open in redux, open it up
  React.useEffect(() => {
    if (sidebarLinkSetting)
      setSubmenuItemsState({
        showSubMenuItems: true,
        submenuY,
      })
  }, [])

  // On link click, dispatch state to redux and update local state.
  const handleToggleSubMenuItems = () => {
    if (!subMenuItems?.length) return

    setSubmenuItemsState({
      showSubMenuItems: !showSubMenuItems,
      submenuY,
    })

    dispatch(updateSidebarStatus(label))
  }

  const handleShowTooltip = (event: React.MouseEvent) => {
    onToggleTooltip?.({
      showTooltip: true,
      event,
      tooltipLabel: label,
      areSubMenuItems: !!subMenuItems?.length,
    })
  }

  const handleHideTooltip = (event: React.MouseEvent) => {
    onToggleTooltip?.({
      showTooltip: false,
      event,
      tooltipLabel: label,
      areSubMenuItems: !!subMenuItems?.length,
    })
  }

  const handleHover = (event: React.MouseEvent) => {
    setSubmenuItemsState({
      showSubMenuItems: true,
      submenuY:
        event.currentTarget.getBoundingClientRect().top +
        (event.pageY - event.clientY),
    })
  }

  const handleUnHover = () => {
    setSubmenuItemsState({
      showSubMenuItems: false,
      submenuY: 0,
    })
  }

  const isActiveMenuItem =
    !subMenuItems?.length || to === "/"
      ? router.pathname === to
      : router.pathname.includes(to)

  return isIconView ? (
    <div
      className="relative"
      onMouseEnter={handleHover}
      onMouseLeave={handleUnHover}
    >
      <IconLink
        route={route}
        Icon={Icon}
        isActive={isActiveMenuItem}
        onMouseEnter={onToggleTooltip ? handleShowTooltip : undefined}
        onMouseLeave={onToggleTooltip ? handleHideTooltip : undefined}
      />
      {subMenuItems &&
        showSubMenuItems &&
        ReactDOM.createPortal(
          <SubLinks
            subMenuItems={subMenuItems}
            style={{ top: `${submenuY}px` }}
            isIconView
          />,
          document.querySelector("#submenu") as Element
        )}
    </div>
  ) : (
    <div>
      <div
        onClick={handleToggleSubMenuItems}
        className={`p-4 my-1 rounded-md cursor-pointer font-medium 
            ${isActiveMenuItem ? "bg-gray-300" : "hover:bg-gray-200"}`}
      >
        {subMenuItems ? (
          <div className="flex">
            <Icon className="flex-initial w-6 h-6 mr-3" />
            <p className="flex-auto">{label}</p>
            <div className="flex-end">
              {showSubMenuItems ? (
                <ChevronUpIcon className="inline-block w-4 h-4" />
              ) : (
                <ChevronDownIcon className="inline-block w-4 h-4" />
              )}
            </div>
          </div>
        ) : (
          <Link href={route}>
            <div className="flex">
              <Icon className="flex-initial w-6 h-6 mr-3" />
              <p className="flex-auto">{label}</p>
            </div>
          </Link>
        )}
      </div>
      {subMenuItems?.length && showSubMenuItems && (
        <SubLinks subMenuItems={subMenuItems} />
      )}
    </div>
  )
}

export default React.memo(SidebarLink)
