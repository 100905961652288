import { PropType } from "src/types/common"

import {
  SectionSettingsActionEnum,
  ClearSplitAndCompareType,
  DisableSplitAndCompareType,
  EnableSplitAndCompareType,
  InitSectionConfigsSelectedValuesType,
  UpdateSectionConfigsSelectedValuesType,
  UpdateSectionFilterSelectedValuesType,
  UpdateSplitAndCompareType,
} from "./types"

export const clearSplitAndCompare = (
  payload: PropType<ClearSplitAndCompareType, "payload">
): ClearSplitAndCompareType => ({
  type: SectionSettingsActionEnum.CLEAR_SPLIT_AND_COMPARE,
  payload,
})

export const disableSplitAndCompare = (
  payload: PropType<DisableSplitAndCompareType, "payload">
): DisableSplitAndCompareType => ({
  type: SectionSettingsActionEnum.DISABLE_SPLIT_AND_COMPARE,
  payload,
})

export const enableSplitAndCompare = (
  payload: PropType<EnableSplitAndCompareType, "payload">
): EnableSplitAndCompareType => ({
  type: SectionSettingsActionEnum.ENABLE_SPLIT_AND_COMPARE,
  payload,
})

export const initSectionConfigsSelectedValues = (
  payload: PropType<InitSectionConfigsSelectedValuesType, "payload">
): InitSectionConfigsSelectedValuesType => ({
  type: SectionSettingsActionEnum.INIT_SECTION_CONFIGS_SELECTED_VALUES,
  payload,
})

export const updateSectionConfigsSelectedValues = (
  payload: PropType<UpdateSectionConfigsSelectedValuesType, "payload">
): UpdateSectionConfigsSelectedValuesType => ({
  type: SectionSettingsActionEnum.UPDATE_SECTION_CONFIGS_SELECTED_VALUES,
  payload,
})

export const updateSectionFilterSelectedValues = (
  payload: PropType<UpdateSectionFilterSelectedValuesType, "payload">
): UpdateSectionFilterSelectedValuesType => ({
  type: SectionSettingsActionEnum.UPDATE_SECTION_FILTER_SELECTED_VALUES,
  payload,
})

export const updateSplitAndCompare = (
  payload: PropType<UpdateSplitAndCompareType, "payload">
): UpdateSplitAndCompareType => ({
  type: SectionSettingsActionEnum.UPDATE_SPLIT_AND_COMPARE,
  payload,
})
