import { useClientSettings } from "../useClientSettings"

export const createInternalRoute = (
  base: string,
  clientID: string,
  portfolioID: string
): string => `${base}?clientID=${clientID}&portfolioID=${portfolioID}`

// This hook is used to attach clientID and portfolioID to internal pages routes
export const useInternalRoutes = (base: string) => {
  const { clientID, portfolioID } = useClientSettings()

  return {
    route: base
      ? createInternalRoute(base, `${clientID}`, `${portfolioID}`)
      : "",
  }
}
