import * as React from "react"

import {
  Button,
  ButtonVariantEnum,
  XMarkIcon,
  classNames,
  useBreakpoint,
} from "@behaviour-lab/blab-component-library"

import { splitGroup } from "src/components/Section/Filters"
import { Title } from "src/components/Section/Panel/Title"
import { ParentPanelEnum } from "src/components/Section/types"
import { useSection } from "src/context/section-context"

interface IPropTypes {
  title?: string
  children: React.ReactElement | React.ReactElement[]
  position?: "absolute" | "relative"
}

const CONTENT_MAX_HEIGHT = 450

const TertiaryPanel = ({
  title,
  children,
  position = "absolute",
}: IPropTypes) => {
  const [contentHeight, setContentHeight] =
    React.useState<number>(CONTENT_MAX_HEIGHT)

  const titleRef = React.useRef<HTMLDivElement>(null)
  const {
    tertiaryPanelRef,
    showTertiaryPanel,
    setShowTertiaryPanel,
    lastSplit,
    primaryPanelOnly,
  } = useSection()

  const currentBreakpoint = useBreakpoint()
  const isNarrowScreenView = ["xs", "sm"].includes(currentBreakpoint)

  React.useEffect(() => {
    if (!showTertiaryPanel || !tertiaryPanelRef?.current?.offsetHeight) return

    setContentHeight(
      tertiaryPanelRef.current?.offsetHeight -
        (titleRef?.current?.offsetHeight || CONTENT_MAX_HEIGHT)
    )
  }, [showTertiaryPanel])

  return showTertiaryPanel ? (
    <div
      ref={tertiaryPanelRef}
      className={classNames("bg-gray-50 w-full lg:w-[48%] shadow-2xl", {
        [position]: !isNarrowScreenView,
        // z-index should be bigger than secondary panel 31 and smaller than MoreOptions z-40
        "z-[35] right-0 bottom-0 top-0": position === "absolute",
        "z-[35] absolute top-0 left-0 bottom-0 right-0": isNarrowScreenView,
        "w-full md:w-[65%] md:min-w-[65%] xl:w-[50%] xl:min-w-[50%]":
          primaryPanelOnly,
      })}
    >
      <div className="relative w-full h-full text-lg rounded-sm">
        <div
          ref={titleRef}
          className={`flex sticky top-0 left-0 z-[35] bg-gray-50 pt-4 px-4 pb-4 shadow ${
            title ? "justify-between" : "justify-end"
          } items-center gap-x-3`}
        >
          <Title>
            {lastSplit
              ? [splitGroup[lastSplit], title].filter(Boolean).join(" - ")
              : title || ""}
          </Title>
          <Button
            onClick={() => setShowTertiaryPanel(false)}
            variant={ButtonVariantEnum.Transparent}
          >
            <XMarkIcon className="w-5 h-5 text-gray-800" />
          </Button>
        </div>
        <div
          className="overflow-auto"
          style={{
            maxHeight: `${contentHeight}px`,
          }}
        >
          {React.Children.map(
            children,
            (child: React.ReactElement<React.PropsWithChildren<any>>) => {
              return React.cloneElement(child, {
                parentPanel: ParentPanelEnum.TERTIARY,
              })
            }
          )}
        </div>
      </div>
    </div>
  ) : null
}

export default TertiaryPanel
