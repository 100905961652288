import * as React from "react"

import {
  Button,
  ButtonVariantEnum,
  LogoutOutline,
  useOnClickOutside,
  useAuth,
} from "@behaviour-lab/blab-component-library"

import { Logo } from "src/components/Logo"
import ORDERED_LINKS from "src/data/navigation/orderedLinks"
import { TopLevelNavigationLinkType } from "src/types/common"

import MobileNavLink from "../Links/MobileNavLink"

import { CollapseButton } from "./CollapseButon"

/**
 * Creates a MobileNav component with a logo, a button and an open or collapsed mobile navigation menu depending on component's state.
 * @return {JSX.Element} Returns the MobileNav component
 */
const MobileNav = () => {
  const { logout } = useAuth()
  const [collapsed, setCollapsed] = React.useState(true)
  const ref = React.useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside<HTMLDivElement>(ref, () => setCollapsed(true))

  return (
    <div ref={ref}>
      <div className="flex justify-between bg-white 2xl:hidden">
        <Button href="/" variant={ButtonVariantEnum.Pure} className="mx-4">
          <Logo />
        </Button>
        <CollapseButton collapsed={collapsed} setCollapsed={setCollapsed} />
      </div>
      {!collapsed && (
        <nav
          // h-[calc(100vh-76px)] - 76px is header height, not extract 76 to constant as tailwind doesn't calculate it properly
          className="relative max-h-[calc(100vh-76px)] border-t overflow-y-auto p-3 shadow-md rounded-b-md bg-white"
        >
          {ORDERED_LINKS.map(
            (
              { label, to, Icon, subMenuItems }: TopLevelNavigationLinkType,
              i: number
            ) => (
              <MobileNavLink
                key={i}
                label={label}
                to={to}
                Icon={Icon}
                subMenuItems={subMenuItems}
              />
            )
          )}
          <Button
            onClick={logout}
            variant={ButtonVariantEnum.Transparent}
            className="w-full px-6 py-5 bg-white rounded-md gap-x-3"
          >
            <LogoutOutline className="w-6 h-6" />
            <p className="font-medium text-left">Logout</p>
          </Button>
        </nav>
      )}
    </div>
  )
}

export default MobileNav
