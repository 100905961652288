import {
  SettingUnitType,
  ParamNameEnum,
  SpecialParamValues,
  AnalyticsSettingEnum,
} from "src/types/common"
import isNumber from "src/utils/helpers/isNumber"

type FormatValueDataType = {
  value: string | number
  unit?: SettingUnitType
  paramId?: AnalyticsSettingEnum
}

const getSpecialParam = (
  paramId: AnalyticsSettingEnum,
  value: string | number
) => {
  switch (paramId) {
    case ParamNameEnum.BUY_SELECTION_HORIZON:
      return SpecialParamValues.UNTIL_SELL
    default:
      return value
  }
}

/**
 * Formats the value of a parameter so it can be displayed nicely to the user
 * @param {string | number} value - The value of the parameter
 * @param {SettingUnitType} unit - The unit of the parameter
 * @param {ParamNameEnum} paramId - The id of the parameter
 * @returns {string} Returns the formated param value to show the user
 */
export const formatValue = ({ value, unit, paramId }: FormatValueDataType) => {
  switch (unit) {
    case SettingUnitType.PERCENTAGE: {
      return `${Number(value) * 100}%`
    }

    case SettingUnitType.BPS: {
      return `${value}bps`
    }

    case SettingUnitType.DAYS: {
      if (isNumber(value)) {
        return `${value} ${Number(value) > 1 ? "days" : "day"}`
      }

      return `${value}`
    }

    case SettingUnitType.MONTHS: {
      if (isNumber(value) && paramId && value === -1) {
        return getSpecialParam(paramId, value)
      }

      if (isNumber(value) && value !== -1) {
        return `${value} ${Number(value) > 1 ? "months" : "month"}`
      }

      return `${value}`
    }

    case SettingUnitType.YEARS: {
      if (isNumber(value)) {
        return `${value} ${Number(value) > 1 ? "years" : "year"}`
      }

      return `${value}`
    }

    default:
      return value
  }
}

export default formatValue
