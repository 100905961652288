import * as React from "react"

import {
  IntersectionObserverWrapper,
  Listbox,
  ListboxDefaultOptionType,
} from "@behaviour-lab/blab-component-library"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"

import { PageSettings } from "src/components/PageSettings"
import { SidePanelToggle, SidePanel } from "src/components/SidePanel"
import { updateAnalyticsSettingSelectedValue } from "src/redux/analytics-settings/actions"
import { getAnalyticsSettingsAllByIdSelector } from "src/redux/analytics-settings/selectors"
import {
  getClientSettingsClientIDSelector,
  getClientSettingsPortfolioIDSelector,
  getClientSettingsPortfoliosSelector,
} from "src/redux/client-settings/selectors"
import { ConfigNameEnum } from "src/types/common"
import { createInternalRoute } from "src/utils/hooks"

import { settingsIdsPerPage } from "./constants"

interface IProps {
  maxWidth?: number
}

const Header = ({ maxWidth }: IProps) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const id = router.pathname

  const [areFiltersHidden, setAreFiltersHidden] = React.useState<boolean>(false)
  const [sidePanelOpened, setSidePanelOpened] = React.useState<boolean>(false)

  const handleCloseSidePanel = () => setSidePanelOpened(false)

  const selectedClientID = useSelector(getClientSettingsClientIDSelector)
  const selectedPortfolioID = useSelector(getClientSettingsPortfolioIDSelector)
  const portfolios = useSelector(getClientSettingsPortfoliosSelector)
  const benchmarkSetting = useSelector(getAnalyticsSettingsAllByIdSelector)[
    ConfigNameEnum.BENCHMARK_SEDOL
  ]

  const portfolioList = React.useMemo(
    () =>
      portfolios.map(item => {
        return { label: item.name, value: item.id.toString() }
      }),
    [portfolios]
  )

  const benchmarkList = React.useMemo(
    () =>
      benchmarkSetting?.data?.options?.map(item => ({
        label: item.displayValue || item.value || "",
        value: item.value || "",
      })),
    [benchmarkSetting]
  )

  const handleChangePortfolioSelection = ({
    value,
  }: ListboxDefaultOptionType) => {
    if (selectedClientID)
      router.push(
        createInternalRoute(router.pathname, selectedClientID, `${value}`)
      )
  }

  const handleChangeBenchmarkSelection = ({
    value,
  }: ListboxDefaultOptionType) => {
    dispatch(
      updateAnalyticsSettingSelectedValue({
        stateId: ConfigNameEnum.BENCHMARK_SEDOL,
        selected: value as string,
      })
    )
  }

  const handleElementsHidden = (hidden: string[]) => {
    const nextState = !!hidden.length
    if (areFiltersHidden !== nextState) {
      setAreFiltersHidden(nextState)
    }
  }

  const fundListBox = (props = {}) => (
    <Listbox
      light
      label="Fund"
      selectedValue={selectedPortfolioID as string}
      options={portfolioList}
      onChange={handleChangePortfolioSelection}
      {...props}
    />
  )

  const benchMarkListBox = (props = {}) => (
    <Listbox
      light
      label="Benchmark"
      selectedValue={benchmarkSetting?.selected as string}
      options={benchmarkList as ListboxDefaultOptionType[]}
      onChange={handleChangeBenchmarkSelection}
      {...props}
    />
  )

  const pageSettingsItems = (props = {}) => (
    <PageSettings settingIds={settingsIdsPerPage[id] || []} {...props} />
  )

  // TODO: If the data is not valid in the header then there is something seriously wrong
  // so we should protect against this and show a page wide error
  if (
    !!selectedPortfolioID &&
    portfolioList &&
    benchmarkSetting?.selected &&
    benchmarkList
  ) {
    return (
      <div
        className="flex gap-x-4"
        style={{
          maxWidth: maxWidth && maxWidth > 0 ? `${maxWidth}px` : "auto",
        }}
      >
        <IntersectionObserverWrapper
          maxWidth={!maxWidth || maxWidth <= 80 ? 1 : maxWidth - 80} // 80px is space for SidePanelToggle
          onElementsHidden={handleElementsHidden}
        >
          <div data-observed="Fund">{fundListBox()}</div>
          <div data-observed="Benchmark">{benchMarkListBox()}</div>
          <div data-observed="PageSettings" className="divide-x">
            {/* This empty div is needed to add a divider with divide-x className */}
            <div />
            {pageSettingsItems({ className: "pl-4" })}
          </div>
        </IntersectionObserverWrapper>
        {areFiltersHidden && (
          <SidePanelToggle
            opened={sidePanelOpened}
            setOpen={setSidePanelOpened}
          />
        )}
        <SidePanel
          opened={sidePanelOpened}
          onClose={handleCloseSidePanel}
          title="Settings"
        >
          <div className="flex flex-col gap-y-8">
            {fundListBox({ fullWidth: true })}
            {benchMarkListBox({ fullWidth: true })}
            {pageSettingsItems({
              className: "flex-col gap-y-8",
              itemsFullWidth: true,
            })}
          </div>
        </SidePanel>
      </div>
    )
  }

  return <div /> // Needed to take the left-side of the Header and align OverallFundPerformance to the right
}

export default Header
