import * as React from "react"

import {
  Listbox,
  ListboxDefaultOptionType,
} from "@behaviour-lab/blab-component-library"
import { useSelector, useDispatch } from "react-redux"

import { updateAnalyticsSettingSelectedValue } from "src/redux/analytics-settings/actions"
import { getAnalyticsSettingsAllByIdSelector } from "src/redux/analytics-settings/selectors"
import {
  ParamNameEnum,
  SpecialParamValues,
  AnalyticsSettingEnum,
  AnalyticsSettingsValueType,
} from "src/types/common"

import { formatValue } from "./helpers"
interface IPropTypes {
  settingIds: Array<ParamNameEnum>
  itemsFullWidth?: boolean
  className?: string
}

/**
 * Creates a PageSettings component that shows a description and any number of dropdowns with any number of options
 * * REQUIRED PROPS
 * @param  {Array<ParamNameEnum>} settingIds Array of settings-ids which should be visible as Listbox
 * * OPTIONAL PROPS
 * @param  {boolean} itemsFullWidth Flag that defines if ListBox should be fullWidth
 * @param  {string} className ClassName set from outside the component
 * @return {JSX.Element} Returns the PageSettings component
 */
const PageSettings = ({
  settingIds,
  itemsFullWidth,
  className = "",
}: IPropTypes) => {
  const dispatch = useDispatch()

  const allAnalyticsSettings = useSelector(getAnalyticsSettingsAllByIdSelector)

  const pageSettingsItems = settingIds?.reduce(
    (acc: AnalyticsSettingsValueType[], curr: AnalyticsSettingEnum) => {
      acc.push(allAnalyticsSettings[curr] as AnalyticsSettingsValueType)

      return acc
    },
    []
  )

  // Some values like "Until Sell" must be sent to the backed as -1. This function intercepts the selected values from the listbox and set these special ones at -1
  const formatSelectedValue = (value: string | number): string => {
    switch (value) {
      case SpecialParamValues.UNTIL_SELL:
        return "-1"

      default:
        return `${value}`
    }
  }

  const handleChange = (
    selectedOption: ListboxDefaultOptionType,
    settingId: AnalyticsSettingEnum
  ) => {
    dispatch(
      updateAnalyticsSettingSelectedValue({
        stateId: settingId,
        selected: formatSelectedValue(selectedOption.value),
      })
    )
  }

  return !pageSettingsItems?.length ? null : (
    <div className={`flex gap-y-1 ${className}`}>
      {pageSettingsItems.map(
        ({ id, selected, data }: AnalyticsSettingsValueType, index) => {
          const options = (data?.options?.map(item => ({
            label: formatValue({
              value: item.displayValue || item.value || "",
              unit: data.unit,
              paramId: id,
            }),
            value: item.value || "",
          })) || []) as ListboxDefaultOptionType[]

          return (
            <div className="mr-4" key={index}>
              <Listbox<ListboxDefaultOptionType>
                label={data?.displayName || ""}
                selectedValue={
                  selected === "-1" ? "Until Sell" : (selected as string)
                }
                options={options}
                onChange={selectedOptionArg => {
                  handleChange(
                    selectedOptionArg,
                    data?.name as AnalyticsSettingEnum
                  )
                }}
                light
                fullWidth={itemsFullWidth}
              />
            </div>
          )
        }
      )}
    </div>
  )
}

export default PageSettings
