import * as React from "react"

import { SomethingBroke } from "../SomethingBroke"

interface IProps {
  numberOfRetries: number
  maxRetries: number
  height: number
  noErrorLabel?: boolean
  onRetry?: () => void | Promise<void>
  className?: string
}

/**
 * Dedicated error state for the BestAndWorstAreaCards component
 * @ REQUIRED PROPS
 * @param {number} numberOfRetries The number of times the user has retried
 * @param {number} maxRetries The maximum number of times the user can retry
 * @param {number} height The height of the component
 * @param {boolean} noErrorLabel If true, the error label will not be displayed
 * @param {() => void | Promise<void>} onRetry The function to call when the user clicks retry
 * @param {string} className Any additional classes to add to the component
 * @returns {JSX.Element} Returns the BestAndWorstAreaCardsError component
 */
const BestAndWorstAreaCardsError = ({
  onRetry,
  numberOfRetries,
  height,
  maxRetries,
  className = "",
}: IProps) => {
  return (
    <div
      className={`bg-gray-700 rounded-lg p-4 ${className}`}
      style={{ height }}
    >
      <div className="flex items-center justify-center h-full">
        <SomethingBroke
          onRetry={onRetry}
          numberOfRetries={numberOfRetries}
          maxRetries={maxRetries}
        />
      </div>
    </div>
  )
}

export default BestAndWorstAreaCardsError
