import * as React from "react"

import {
  UserAddOutline,
  DatabaseOutline,
  Button,
  useAuth,
} from "@behaviour-lab/blab-component-library"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"

import { Seo } from "src/components/Seo"
import { Navigation } from "src/components/Setup/Navigation"
import { PageTitle } from "src/components/Titles"
import { fetchClientsRequest } from "src/redux/client-settings/actions"
import { ClientType, PortfolioType } from "src/types/common"
import { useClientSettings } from "src/utils/hooks"

interface IPropTypes {
  heading: string
  children: React.ReactNode
  title?: string
  description?: string
  adminOnly?: boolean
  withButtons?: boolean
}

/**
 * Creates a DefaultLayout component that contains information about the current page
 * * REQUIRED PROPS
 * @param {string} heading The heading of the page
 * @param {React.ReactNode} children The children of the component
 * * OPTIONAL PROPS
 * @param {string} title The title of the page
 * @param {string} description The description of the page
 * @param {boolean} adminOnly Boolean that indicates whether the page is only accessible by an admin
 * @param {boolean} withButtons Boolean that indicates whether the component should have buttons to create new clients and portfolios
 * @returns {JSX.Element} Returns the DefaultLayout component
 */
const DefaultLayout = ({
  heading,
  title = "Behaviour Lab Analytics",
  description,
  adminOnly,
  withButtons,
  children,
}: IPropTypes) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { isAdvisoryOrSuperAdmin } = useAuth()
  const [clientID, setClientID] = React.useState<number | undefined>(undefined)
  const [portfolioID, setPortfolioID] = React.useState<number | undefined>(
    undefined
  )
  const [clientName, setClientName] = React.useState<string | undefined>(
    undefined
  )
  const [portfolioName, setPortfolioName] = React.useState<string | undefined>(
    undefined
  )
  const { clients } = useClientSettings()

  React.useEffect(() => {
    const { clientID: activeClientID, portfolioID: activePortfolioID } =
      router.query

    if (router.isReady) {
      setClientID(
        typeof activeClientID === "string"
          ? parseInt(activeClientID)
          : undefined
      )
      setPortfolioID(
        typeof activePortfolioID === "string"
          ? parseInt(activePortfolioID)
          : undefined
      )
    }
  }, [router.isReady])

  React.useEffect(() => {
    if (!clientID || !clients.length) return

    const foundClient = clients.find(
      (client: ClientType) => client.id === clientID
    )

    setClientName(foundClient?.name)

    if (portfolioID) {
      setPortfolioName(
        foundClient?.portfolios?.find(
          (portfolio: PortfolioType) => portfolio.id === portfolioID
        )?.name
      )
    }
  }, [clientID, portfolioID, clients])

  // Fetch the page settings when the page loads -> this will do this everywhere the hook is used and we want to do it once on a page so let's shift it into the layout component
  React.useEffect(() => {
    dispatch(fetchClientsRequest())
  }, [])

  if (adminOnly && !isAdvisoryOrSuperAdmin) {
    router.push("/")
  }

  return !adminOnly || (adminOnly && isAdvisoryOrSuperAdmin) ? (
    <div className="relative flex h-screen overflow-y-scroll">
      <Seo title={title} />
      <Navigation />
      <div className="relative flex-grow p-6 bg-gray-50 overflow-x-hidden xl:p-10 mt-[76px] 2xl:mt-0">
        <div className="flex flex-col pb-5 mb-8 border-b 2xl:items-center 2xl:justify-between 2xl:flex-row gap-y-4">
          <div>
            {clientName && !portfolioName ? (
              <PageTitle title={`${heading} - ${clientName}`} />
            ) : clientName && portfolioName ? (
              <PageTitle title={`${heading} - ${portfolioName}`} />
            ) : (
              <PageTitle title={heading} />
            )}
            <p>{description}</p>
          </div>
          {isAdvisoryOrSuperAdmin && withButtons && (
            <div className="flex flex-col sm:flex-row gap-y-3 sm:gap-x-3">
              <Button href="/setup/client">
                <UserAddOutline className="w-5 h-5" />
                Create New Organization
              </Button>
              <Button href="/setup/portfolios">
                <DatabaseOutline className="w-5 h-5" />
                Create New Portfolio
              </Button>
            </div>
          )}
        </div>
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as React.ReactElement, {
              clientID,
              portfolioID,
            })
          }

          return child
        })}
      </div>
    </div>
  ) : null
}

export default DefaultLayout
