import * as React from "react"

import { ExclamationOutline } from "@behaviour-lab/blab-component-library"

interface IPropTypes {
  text: string
  className?: string
}

/**
 * Creates an ErrorMessage component that shows
 * * REQUIRED PROPS
 * @param {string} text - The text to show in the error message
 * * OPTIONAL PROPS
 * @param {string} className - The class names to apply to the component
 * @returns {JSX.Element} Returns the ErrorMessage component
 */
const ErrorMessage = ({ text, className }: IPropTypes) => {
  return (
    <div
      className={`flex items-center text-red-600 gap-x-2 ${
        className ? className : ""
      }`}
    >
      <ExclamationOutline width={20} height={20} />
      <p>{text}</p>
    </div>
  )
}

export default ErrorMessage
