import * as React from "react"

import { useDispatch, useSelector } from "react-redux"

import { useSection } from "src/context/section-context"
import { getAnalyticsSettingsAllByIdSelector } from "src/redux/analytics-settings/selectors"
import {
  clearSplitAndCompare,
  updateSplitAndCompare,
} from "src/redux/section-settings/actions"
import { getSectionSettingsSelector } from "src/redux/section-settings/selectors"
import { SectionSettingsStoreType } from "src/redux/types"
import { SplitRequestEnum, SectionNameEnum } from "src/types/common"
import { useSelectorWithArguments } from "src/utils/hooks"

import { FilterWithPopover } from "../FilterWithPopover"
import { createAppliedFiltersLabel } from "../functions"

interface IProps {
  splitRequest: SplitRequestEnum
}

const SplitFilter = ({ splitRequest }: IProps) => {
  const dispatch = useDispatch()
  const { id } = useSection()
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const settings = useSelector(getAnalyticsSettingsAllByIdSelector)

  const [selectedFilters, setSelectedFilters] = React.useState<string[]>([])

  const sectionSettings = useSelectorWithArguments<
    SectionNameEnum,
    SectionSettingsStoreType | undefined
  >(getSectionSettingsSelector, id)

  const filterId = sectionSettings?.splitFilterId

  // Get the active split filters only if the split request is either splitA or splitB
  const activeSplitFilters =
    splitRequest !== SplitRequestEnum.STANDARD
      ? sectionSettings?.[splitRequest] || []
      : []

  React.useEffect(() => {
    if (!activeSplitFilters) return

    setSelectedFilters(activeSplitFilters)
  }, [activeSplitFilters, filterId])

  const alignStateOnClose = () => {
    if (JSON.stringify(activeSplitFilters) === JSON.stringify(selectedFilters))
      return

    setSelectedFilters(activeSplitFilters)
  }

  if (!filterId) return null

  const filterData = settings[filterId]?.data

  const handleUpdate = (selectedValue: string) => {
    if (!selectedValue) return
    setSelectedFilters([...selectedFilters, selectedValue])
  }

  const handleDelete = (deletedValue: string) => {
    setSelectedFilters(selectedFilters.filter(value => value !== deletedValue))
  }

  const closePopover = () => {
    buttonRef.current?.click()
  }

  const handleClear = () => {
    dispatch(
      clearSplitAndCompare({
        sectionId: id,
        reqType: splitRequest,
      })
    )

    closePopover()
  }

  const handleSubmit = () => {
    dispatch(
      updateSplitAndCompare({
        sectionId: id,
        reqType: splitRequest,
        splitValue: selectedFilters,
      })
    )

    closePopover()
  }

  return (
    <FilterWithPopover
      ref={buttonRef}
      title={splitRequest === SplitRequestEnum.SPLIT_A ? "Split A" : "Split B"}
      values={selectedFilters}
      options={filterData?.options || []}
      buttonClassName="font-extralight"
      appliedFiltersLabel={createAppliedFiltersLabel(activeSplitFilters)}
      handleUpdate={handleUpdate}
      handleDelete={handleDelete}
      handleClear={handleClear}
      handleSubmit={handleSubmit}
      horizontal
      onClose={alignStateOnClose}
    />
  )
}

export default SplitFilter
