import { createSelector } from "reselect"

import { SectionNameEnum } from "src/types/common"

import { AppState } from "../rootReducer"

export const getSectionSettingConfigsSelector = () =>
  createSelector(
    [
      (state: AppState) => state.sectionSettings.byId,
      (_: AppState, sectionId: SectionNameEnum) => sectionId,
    ],
    (byId, sectionId) => byId[sectionId]?.configs
  )

export const getSectionSettingFiltersSelector = () =>
  createSelector(
    (state: AppState) => state.sectionSettings.byId,
    (_: AppState, sectionId: SectionNameEnum) => sectionId,
    (byId, sectionId) => byId[sectionId]?.filters
  )

export const getSectionSettingsSelector = () =>
  createSelector(
    [
      (state: AppState) => state.sectionSettings.byId,
      (_state: AppState, sectionId: SectionNameEnum) => sectionId,
    ],
    (byId, sectionId) => byId[sectionId]
  )
