import * as React from "react"

import { XMarkIcon } from "@behaviour-lab/blab-component-library"

interface IProps {
  text: string
  onClick: (text: string) => void
}

const RemovableItemButton = ({ text, onClick }: IProps) => {
  const handleClick = () => onClick(text)

  return (
    <button
      value={text}
      onClick={handleClick}
      className="flex items-center py-0 my-0 rounded-md align-center bg-citrus-500 hover:bg-citrus-700 focus:outline-0"
    >
      <span className="px-1 font-extralight">{text}</span>
      <XMarkIcon className="w-4 h-4 text-gray-800" />
    </button>
  )
}

export default RemovableItemButton
