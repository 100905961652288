import {
  toTitleCase,
  toPercentage,
  toBpsOrPercentage,
} from "@behaviour-lab/blab-component-library"

import {
  AxisFormatEnum,
  DataItemType,
  HorizontalWaterfallItemType,
  ScatterPlotDataItem,
  TimingGraphDataPointType,
  VerticalBarDataItemType,
} from "src/types/common"

import {
  GraphTypeEnum,
  StackedBarGraphItemType,
  TooltipAdditionalProps,
} from "./types"

export const generateTooltipHtml = (
  fields?: {
    [key: string]: number | string | null | undefined | Date
  },
  title?: string
) => {
  if (!fields && !title) return null

  const notEmptyRows = Object.entries(fields || {}).filter(
    ([_name, value]) => value !== undefined && value !== null && value !== ""
  )

  const titleContent = !title
    ? ""
    : `<p class="text-base font-medium whitespace-nowrap${
        notEmptyRows.length ? " pb-2" : ""
      }">${title}</p>`

  return `<div class='tooltip' data-testid='tooltip-div'>
            ${titleContent}
            ${notEmptyRows
              .map(
                ([name, value]) =>
                  `
            <p>${
              name.trim()
                ? `<span class="font-medium">${name.trim()}: </span>`
                : ""
            }<span class="whitespace-nowrap">${value}</span>
            </p>
            `
              )
              .join("\n")}
          </div>`
}

export const getRowsDataByGraphType: {
  [key in GraphTypeEnum]?: (
    item: TimingGraphDataPointType &
      ScatterPlotDataItem &
      (StackedBarGraphItemType & DataItemType) &
      HorizontalWaterfallItemType &
      VerticalBarDataItemType,
    d: React.MouseEvent<HTMLElement>,
    additionalArgs?: TooltipAdditionalProps
  ) => { [key: string]: number | string | null | undefined | Date }
} = {
  [GraphTypeEnum.TimingGraph]: (item: TimingGraphDataPointType) => ({
    ["Ticker"]: item?.factsetTicker,
    ["Buy Date"]: item?.buyDate,
    ["Sell Date"]: item?.sellDate,
    ["Impact"]:
      typeof item?.impact !== "undefined"
        ? `${toPercentage(item.impact)}%`
        : null,
    ["Relative Perf. Before Sell"]: item?.x ? `${toPercentage(item.x)}%` : null,
    ["Relative Perf. After Sell"]: item?.y ? `${toPercentage(item.y)}%` : null,
  }),

  [GraphTypeEnum.ScatterPlot]: (
    item: ScatterPlotDataItem,
    _d,
    additionalArgs?: TooltipAdditionalProps
  ) => ({
    ["Name"]: item?.name,
    ["Ticker"]: item?.factsetTicker || item?.sedol || item?.Sedol,
    ["Buy Date"]: item?.buyDate,
    ["Sell Date"]: item?.sellDate,
    [additionalArgs?.xLabel as string]: isNaN(item?.x as number)
      ? item?.x
      : (item.x as number)?.toFixed(additionalArgs?.xNumOfDecimals ?? 4),
    [additionalArgs?.monthsPostBuy as string]: item?.monthsPostBuy,
    [additionalArgs?.yLabel as string]: isNaN(item?.y as number)
      ? item?.y
      : additionalArgs?.yAxisFormat === AxisFormatEnum.PERCENTAGE
      ? `${toPercentage(item.y as number)}%`
      : item.y?.toFixed(additionalArgs?.yNumOfDecimals ?? 4),
  }),

  [GraphTypeEnum.HorizontalStackedBarGraph]: (
    item: StackedBarGraphItemType & DataItemType
  ) => ({
    ["Count"]:
      typeof item[1] === "undefined" || typeof item[0] === "undefined"
        ? null
        : `${item[1] - item[0]} / ${sumOfStacks(item as DataItemType)}`,
  }),

  [GraphTypeEnum.VerticalGroupedBarGraphWithTrendline]: (
    item: VerticalBarDataItemType,
    _d,
    additionalArgs?: TooltipAdditionalProps
  ) => ({
    ["Year"]: item?.name,
    [additionalArgs?.yLabel as string]: !isNaN(item?.value as number)
      ? `${toPercentage(item.value as number)} %`
      : item?.value,
  }),

  [GraphTypeEnum.VerticalBarGraph]: (
    item: VerticalBarDataItemType,
    _d,
    additionalArgs?: TooltipAdditionalProps
  ) => ({
    [additionalArgs?.xLabel as string]: item?.name || item.year,
    [additionalArgs?.yLabel as string]:
      additionalArgs?.yAxisFormat === AxisFormatEnum.PERCENTAGE
        ? `${toPercentage(item.value as number)}%`
        : item?.value,
  }),

  [GraphTypeEnum.VerticalStackedBarGraph]: (
    item: StackedBarGraphItemType,
    d,
    additionalArgs?: TooltipAdditionalProps
  ) => {
    return {
      [additionalArgs?.xLabel as string]: item?.data?.[
        additionalArgs?.key as string
      ]
        ? `${item?.data?.[additionalArgs?.key as string]} ago`
        : "",
      [`${
        (d?.target as HTMLElement)?.parentElement?.getAttribute("data-key")
          ? `${toTitleCase(
              (d?.target as HTMLElement)?.parentElement?.getAttribute(
                "data-key"
              ) as string
            )} `
          : ""
      }Count`]:
        typeof item[1] === "undefined" || typeof item[0] === "undefined"
          ? null
          : `${item[1] - item[0]} / ${sumOfStacks(
              item as unknown as DataItemType
            )}`,
    }
  },
  [GraphTypeEnum.VerticalStackedBarGraphExposure]: (
    item: StackedBarGraphItemType
  ) => ({
    [" "]:
      typeof item[1] === "undefined" || typeof item[0] === "undefined"
        ? null
        : item[1] >= 0 && item[0] >= 0
        ? `${toBpsOrPercentage(item[1] - item[0])}`
        : `-${toBpsOrPercentage(item[1] - item[0])}`,
    // Removing this temporarily by advisory request
    // +
    // ` /  ` +
    // `${toPercentage(sumOfStacks(item, ["Year"]))}%`,
  }),

  [GraphTypeEnum.HorizontalWaterfall]: (item: HorizontalWaterfallItemType) => ({
    [" "]: !isNaN(item?.value as number)
      ? `${Number(item.value) > 0 ? "+" : ""}${toPercentage(
          item.value as number
        )}%`
      : item?.value,
  }),
}

export const sumOfStacks = (item: DataItemType, excludeFields?: string[]) => {
  return (
    item.data &&
    Object.entries(item.data).reduce(
      (acc: number, [fieldName, fieldValue]: [string, number]) => {
        if (
          typeof fieldValue !== "string" &&
          (!excludeFields || !excludeFields.includes(fieldName))
        ) {
          // eslint-disable-next-line no-param-reassign
          acc += fieldValue
        }

        return acc
      },
      0
    )
  )
}
