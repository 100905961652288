import * as React from "react"

import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "@behaviour-lab/blab-component-library"
import Link from "next/link"
import { useRouter } from "next/router"

import SubLinks from "src/components/Navigation/Links/SubLinks"
import { TopLevelNavigationLinkType } from "src/types"
import { useInternalRoutes } from "src/utils/hooks"

/**
 * Creates a MobileNavLink component that returns a navigation link
 * * REQUIRED PROPS
 * @param  {string} label Name of the page
 * @param  {string} to Link path
 * @param  {Function} Icon Link Icon
 * @param  {Array<{}>} subMenuItems Array of objects containing the sublinks
 * @return {JSX.Element} Returns the MobileNavLink component
 */
const MobileNavLink = ({
  label,
  to,
  Icon,
  subMenuItems,
}: TopLevelNavigationLinkType) => {
  const router = useRouter()
  const { route } = useInternalRoutes(to)

  const [submenuItemsShown, setSubmenuItemsShown] =
    React.useState<boolean>(false)

  const handleToggleSubmenuItems = () => {
    if (!subMenuItems?.length) return
    setSubmenuItemsShown(!submenuItemsShown)
  }

  return (
    <>
      <div
        className={`px-6  py-5 mb-1 bg-white cursor-pointer rounded-md font-medium hover:bg-gray-200 ${
          to !== "/" && router.asPath.includes(to)
            ? "bg-gray-300"
            : "hover:bg-gray-200"
        } ${
          router.asPath === to
            ? "bg-gray-600 text-white hover:bg-gray-600"
            : "text-white-500 font-medium"
        }`}
      >
        {subMenuItems ? (
          <div className="flex" onClick={handleToggleSubmenuItems}>
            <Icon className="flex-initial w-6 h-6 mr-3" />
            <p className="flex-auto">{label}</p>
            <div className="flex-end">
              {submenuItemsShown ? (
                <ChevronUpIcon className="inline-block w-4 h-4" />
              ) : (
                <ChevronDownIcon className="inline-block w-4 h-4" />
              )}
            </div>
          </div>
        ) : (
          <Link href={route}>
            <div className="flex">
              <Icon className="flex-initial w-6 h-6 mr-3" />
              <p className="flex-auto">{label}</p>
              {subMenuItems && (
                <div className="flex-end" onClick={handleToggleSubmenuItems}>
                  {submenuItemsShown ? (
                    <ChevronUpIcon className="inline-block w-4 h-4" />
                  ) : (
                    <ChevronDownIcon className="inline-block w-4 h-4" />
                  )}
                </div>
              )}
            </div>
          </Link>
        )}
      </div>
      {!!subMenuItems?.length && submenuItemsShown && (
        <SubLinks subMenuItems={subMenuItems} />
      )}
    </>
  )
}

export default MobileNavLink
