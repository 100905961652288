import * as React from "react"

import {
  FilterBody,
  FilterFooter,
  FilterMenu,
  Popover,
} from "@behaviour-lab/blab-component-library"

import { RemovableItems } from "src/components/RemovableItems"
import { SearchWithSuggestions } from "src/components/SearchWithSuggestions"
import { OptionType } from "src/types"

interface IProps {
  title: string
  values: string[]
  appliedFiltersLabel: string
  options: OptionType[]
  className?: string
  buttonClassName?: string
  handleUpdate: (value: string) => void
  handleDelete: (value: string) => void
  handleClear: () => void
  handleSubmit: () => void
  horizontal?: boolean
  disabled?: boolean
  onClose?: () => void
}

const FilterWithPopover = React.forwardRef(
  (
    {
      title,
      values,
      appliedFiltersLabel,
      options,
      className = "",
      buttonClassName,
      handleUpdate,
      handleDelete,
      handleClear,
      handleSubmit,
      horizontal,
      disabled,
      onClose,
    }: IProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <Popover
        label={title}
        selectedLabel={appliedFiltersLabel}
        ref={ref}
        disabled={disabled}
        horizontal={horizontal}
        // 230px is the max width the popopover takes in the main filter panel so we need to set the same here so they both look the same
        className={`max-w-[230px] ${className}`}
        buttonClassName={buttonClassName}
        onClose={onClose}
      >
        <FilterMenu>
          <FilterBody>
            <div className="flex flex-col w-full gap-y-2">
              <div className="text-lg font-bold text-gray-700">{title}</div>
              {!!values.length && (
                <RemovableItems items={values} onRemove={handleDelete} />
              )}
              <SearchWithSuggestions
                allValues={options
                  .map(o => o.displayValue || o.value || "")
                  .filter(v => v?.toLowerCase() !== "all")}
                selectedValues={values}
                onSelect={handleUpdate}
              />
            </div>
          </FilterBody>
          <FilterFooter
            closeFilter={() => null}
            clearFn={handleClear}
            applyFn={handleSubmit}
          />
        </FilterMenu>
      </Popover>
    )
  }
)

FilterWithPopover.displayName = "FilterWithPopover"

export default FilterWithPopover
