import * as React from "react"

import { ChevronRightIcon } from "@behaviour-lab/blab-component-library"
import Link from "next/link"

import { BreadCrumbType } from "src/types"

interface IProps {
  breadCrumbs: BreadCrumbType[]
  className?: string
}

const BreadCrumbs = ({ breadCrumbs, className = "" }: IProps) => {
  return (
    <div className={`flex items-center gap-x-3 ${className}`}>
      {breadCrumbs.map((crumb: BreadCrumbType, index: number) => (
        <div className="flex items-center gap-x-3" key={crumb.href}>
          {index !== 0 && <ChevronRightIcon className="w-3 h-3" />}
          <Link
            href={crumb.href}
            className={`${
              crumb.active ? "underline underline-offset-4" : "hover:opacity-70"
            } focus:outline-0`}
          >
            {crumb.label}
          </Link>
        </div>
      ))}
    </div>
  )
}

export default BreadCrumbs
