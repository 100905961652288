import { createSelector } from "reselect"

import { AppState } from "../rootReducer"

export const getExternalSidebarCollapsedSelector = createSelector(
  (state: AppState) => state.UI.externalSidebar.collapsed,
  collapsed => collapsed
)

export const getInternalSidebarCollapsedSelector = createSelector(
  (state: AppState) => state.UI.internalSidebar.collapsed,
  collapsed => collapsed
)

export const getSidebarSettingsByLabelSelector = () =>
  createSelector(
    (state: AppState) => state.UI.openedSidebarMenu,
    (_: AppState, menuLabel: string) => menuLabel,
    (sideBarSettings, menuLabel) => sideBarSettings[menuLabel]
  )

export const getSidebarSettingsSelector = createSelector(
  (state: AppState) => state.UI.openedSidebarMenu,
  openedSidebarMenu => openedSidebarMenu
)
