import { MouseOverlayInteractionArgsType } from "@behaviour-lab/blab-component-library"

import { AxisFormatEnum } from "src/types/common"

import { bisectLine } from "./bisectLine"
import { BisectorAdditionalProps } from "./types"

export enum TooltipContentAlignmentEnum {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

const formatYValue = ({
  y,
  yAxisFormat,
  yNumOfDecimals,
}: {
  y: number
  yAxisFormat?: AxisFormatEnum
  yNumOfDecimals?: number
}) => {
  const isYNaN = isNaN(y)

  if (yAxisFormat === AxisFormatEnum.PERCENTAGE && !isYNaN) {
    return `${(y * 100).toFixed(yNumOfDecimals || 2)} %`
  }

  if (yAxisFormat === AxisFormatEnum.TRIM && !isYNaN) {
    return `${y.toFixed(yNumOfDecimals || 2)}`
  }

  if (yNumOfDecimals !== undefined && !isYNaN) {
    return y.toFixed(yNumOfDecimals)
  }

  return y
}

export const getBisectorTooltipContent = (
  { xScale, d }: MouseOverlayInteractionArgsType,
  additionalArgs: BisectorAdditionalProps
) => {
  const legend = additionalArgs.data
    ?.map(line => bisectLine(line.data, additionalArgs.xAxisFormat, xScale, d))
    .reduce((acc, item, i) => {
      if (!item) return acc

      acc.push({
        ...item,
        color: additionalArgs.colors[i],
      })
      return acc
    }, [])

  const legendTooltip = `${legend
    .map((line: { x: string; y: number; color: string }) => {
      return `<div class="flex gap-x-1 whitespace-nowrap">
                <div class="w-4 h-4 mt-1 rounded-sm"
                  style="background-color: ${line.color}"
                  />
                </div>
                <div>
                  ${formatYValue({
                    y: line.y,
                    yAxisFormat: additionalArgs.yAxisFormat,
                    yNumOfDecimals: additionalArgs.yNumOfDecimals,
                  })}
                </div>
              </div>`
    })
    .join("")}`

  const title = additionalArgs.title?.(legend) || ""
  const titleContent = !title
    ? ""
    : `<p class="overflow-hidden text-ellipsis">${title}</p>`

  return `<div class="flex flex-col gap-y-1 w-72 px-3 py-3 bg-gray-600 text-sm text-gray-50 font-light border border-gray-200 rounded-md overflow-hidden">
            ${titleContent}
            <div class="flex flex-row flex-wrap gap-x-4 gap-y-1">
              ${legendTooltip}
            </div>
          </div>`
}

export const wrapBisectorTooltipContent = (
  side: TooltipContentAlignmentEnum,
  bisectionLegend: string
) =>
  `<div class="flex flex-col flow-root overflow-hidden">
      ${bisectionLegend} 
      <div class="bg-white border border-white h-[23rem] w-0.5 rounded-md overflow-hidden ${
        side === TooltipContentAlignmentEnum.RIGHT ? "float-right" : ""
      }"></div>
    </div>`
