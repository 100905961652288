import * as React from "react"

import { classNames } from "@behaviour-lab/blab-component-library"
import { useDispatch, useSelector } from "react-redux"

import {
  ISomethingBrokeProps,
  IErrorLoadingDataProps,
} from "src/components/Errors"
import { useSection } from "src/context/section-context"
import { fetchSectionDataRequest } from "src/redux/section-data/actions"
import {
  getSectionDataErrorSelector,
  getSectionDataPendingSelector,
  getSectionDataSelector,
  isSectionDataFetchEnabledSelector,
} from "src/redux/section-data/selectors"
import { getSectionSettingsSelector } from "src/redux/section-settings/selectors"
import { PluginResultData, SectionSettingsStoreType } from "src/redux/types"
import {
  PluginNameEnum,
  SectionNameEnum,
  SplitRequestEnum,
  InjectedParamsType,
  CurrentAnalyticsSettingsType,
} from "src/types/common"
import { useSelectorWithArguments } from "src/utils/hooks"

import { SplitFilter, splitGroup } from "../../Filters"
import { ParentPanelEnum } from "../../types"
import {
  isDataCorrespondentWithInterface,
  responseInterfaces,
  generateErrorMessageForBrokenPluginResponse,
} from "../pluginResponseValidation"

interface IPropTypes {
  stateId: PluginNameEnum
  analyticsSettings: CurrentAnalyticsSettingsType
  children: (data: { data: PluginResultData }) => React.ReactNode
  LoadingState: React.FC
  ErrorState: React.FC<ISomethingBrokeProps & IErrorLoadingDataProps>
  numberOfRetries: number
  maxRetries: number
  onRetry: () => void
  splitReqType: SplitRequestEnum
  parentPanel?: ParentPanelEnum
  injectedParams?: InjectedParamsType
}

const calculatedHeight = ({
  isStandardRequest,
  primaryPanelRef,
  primaryPanelOnly,
  pending,
  error,
}: {
  isStandardRequest: boolean
  primaryPanelRef: React.MutableRefObject<HTMLDivElement | null>
  primaryPanelOnly: boolean
  pending: boolean
  error: string | null
}) => {
  const topPadding = primaryPanelOnly ? 40 : 68
  const spaceBetweenSplitParts = 48 // 3rem;
  const splitFilterHeight = 64

  const baseHeight = 400

  return isStandardRequest || pending || error
    ? `${baseHeight}px`
    : `${Math.max(
        (primaryPanelRef?.current?.offsetHeight || 0) / 2 -
          spaceBetweenSplitParts -
          splitFilterHeight * 2 -
          topPadding,
        baseHeight
      )}px`
}

const SectionWrapper = ({
  stateId,
  analyticsSettings,
  children,
  LoadingState,
  ErrorState,
  numberOfRetries,
  maxRetries,
  onRetry,
  splitReqType,
  parentPanel,
  injectedParams,
}: IPropTypes) => {
  const dispatch = useDispatch()
  const { id: sectionId, primaryPanelRef, primaryPanelOnly } = useSection()

  const withTabsInSecondary = [
    SectionNameEnum.PC_EXPOSURE_OVER_TIME,
    SectionNameEnum.PC_SELECTION,
    SectionNameEnum.PC_ALLOCATION,
  ].includes(sectionId)

  const data = useSelectorWithArguments<
    { stateId: PluginNameEnum; reqType: SplitRequestEnum },
    PluginResultData
  >(getSectionDataSelector, { stateId, reqType: splitReqType })
  const pending = useSelectorWithArguments<
    { stateId: PluginNameEnum; reqType: SplitRequestEnum },
    boolean
  >(getSectionDataPendingSelector, { stateId, reqType: splitReqType })
  const error = useSelectorWithArguments<
    { stateId: PluginNameEnum; reqType: SplitRequestEnum },
    string | null
  >(getSectionDataErrorSelector, { stateId, reqType: splitReqType })

  const sectionSettings = useSelectorWithArguments<
    SectionNameEnum,
    SectionSettingsStoreType | undefined
  >(getSectionSettingsSelector, sectionId)

  const {
    splitFilterId: filterId,
    configs: sectionConfigs = {},
    filters: sectionFilters = {},
    splitA,
    splitB,
  } = sectionSettings || {}

  const isSectionDataFetchEnabled = useSelector(
    isSectionDataFetchEnabledSelector
  )
  // Putting some conditions in variables to make the JSX code more readable
  const isInPrimaryPanel = parentPanel === ParentPanelEnum.PRIMARY
  const isInSecondaryPanel = parentPanel === ParentPanelEnum.SECONDARY
  const isInTertiaryPanel = parentPanel === ParentPanelEnum.TERTIARY

  const isStandardRequest = splitReqType === SplitRequestEnum.STANDARD
  const isSplitARequest = splitReqType === SplitRequestEnum.SPLIT_A
  const isSplitBRequest = splitReqType === SplitRequestEnum.SPLIT_B

  // useEffect for standard request type
  React.useEffect(() => {
    if (
      !isStandardRequest ||
      !Object.keys(sectionConfigs).length ||
      !isSectionDataFetchEnabled
    )
      return

    dispatch(
      fetchSectionDataRequest({
        stateId,
        globalSettings: analyticsSettings,
        sectionConfigs: sectionConfigs,
        sectionFilters: sectionFilters,
        injectedParams: injectedParams,
        reqType: splitReqType,
      })
    )
  }, [
    stateId,
    sectionConfigs,
    sectionFilters,
    injectedParams,
    isSectionDataFetchEnabled,
  ])

  // useEffect for split A request type
  React.useEffect(() => {
    if (
      !isSplitARequest ||
      !Object.keys(sectionConfigs).length ||
      !isSectionDataFetchEnabled ||
      !filterId
    )
      return

    // Get the split filter values and combine them with the section filters. If no split filters are selected, use the section filters
    const reqFiltersA = !splitA?.length
      ? sectionFilters
      : {
          ...sectionFilters,
          [filterId]: splitA,
        }

    dispatch(
      fetchSectionDataRequest({
        stateId,
        globalSettings: analyticsSettings,
        sectionConfigs: sectionConfigs,
        sectionFilters: reqFiltersA,
        injectedParams: injectedParams,
        reqType: splitReqType,
      })
    )
  }, [
    stateId,
    sectionConfigs,
    sectionFilters,
    splitA,
    isSectionDataFetchEnabled,
    filterId,
    injectedParams,
  ])

  // useEffect for split B request type
  React.useEffect(() => {
    if (
      !isSplitBRequest ||
      !Object.keys(sectionConfigs).length ||
      !isSectionDataFetchEnabled ||
      !filterId
    )
      return

    // Get the split filter values and combine them with the section filters. If no split filters are selected, use the section filters
    const reqFiltersB = !splitB?.length
      ? sectionFilters
      : {
          ...sectionFilters,
          [filterId]: splitB,
        }

    dispatch(
      fetchSectionDataRequest({
        stateId,
        globalSettings: analyticsSettings,
        sectionConfigs: sectionConfigs,
        sectionFilters: reqFiltersB,
        injectedParams: injectedParams,
        reqType: splitReqType,
      })
    )
  }, [
    stateId,
    sectionConfigs,
    sectionFilters,
    splitB,
    isSectionDataFetchEnabled,
    filterId,
    injectedParams,
  ])

  const handleRetry = () => {
    dispatch(
      fetchSectionDataRequest({
        stateId,
        globalSettings: analyticsSettings,
        sectionConfigs: sectionConfigs,
        sectionFilters: sectionFilters,
        injectedParams: injectedParams,
        reqType: splitReqType,
      })
    )

    onRetry()
  }

  const isPluginInterfaceBrokenError =
    !error &&
    !pending &&
    !isDataCorrespondentWithInterface(data, responseInterfaces[stateId])

  if (isPluginInterfaceBrokenError) {
    console.error(generateErrorMessageForBrokenPluginResponse(stateId))
  }

  return (
    <div className="flex flex-col flex-grow w-full">
      {!isStandardRequest && !isInTertiaryPanel && (
        <div
          className={classNames("mb-6", {
            "mt-[80px]":
              isInPrimaryPanel && withTabsInSecondary && isSplitARequest, // to align vis with table (not with tabs)
          })}
        >
          {isInPrimaryPanel && <SplitFilter splitRequest={splitReqType} />}
          {isInSecondaryPanel && splitGroup[splitReqType] && (
            <p className="px-4 font-bold text-gray-700">
              {splitGroup[splitReqType]}
            </p>
          )}
        </div>
      )}
      <div
        className="flex flex-col"
        style={
          isInPrimaryPanel || isInTertiaryPanel
            ? {
                height: "100%",
                minHeight: calculatedHeight({
                  isStandardRequest,
                  primaryPanelRef,
                  primaryPanelOnly,
                  pending,
                  error,
                }),
              }
            : isInSecondaryPanel
            ? {
                overflow: "auto",
                height: calculatedHeight({
                  isStandardRequest,
                  primaryPanelRef,
                  primaryPanelOnly,
                  pending,
                  error,
                }),
              }
            : {}
        }
      >
        {pending ? (
          <LoadingState />
        ) : error || isPluginInterfaceBrokenError ? (
          <ErrorState
            onRetry={handleRetry}
            numberOfRetries={numberOfRetries}
            maxRetries={maxRetries}
          />
        ) : (
          <>{children({ data })}</>
        )}
      </div>
    </div>
  )
}

export default SectionWrapper
