import * as React from "react"

import {
  Button,
  ButtonVariantEnum,
  RefreshIcon,
} from "@behaviour-lab/blab-component-library"

import ErrorWithSkewIcon from "./ErrorWithSkewIcon"

interface IProps {
  numberOfRetries: number
  maxRetries: number
  onRetry?: () => void | Promise<void>
  noErrorLabel?: boolean
  className?: string
}

const ErrorWithSkew = ({
  onRetry,
  numberOfRetries,
  maxRetries,
  noErrorLabel,
  className = "",
}: IProps) => {
  return (
    <div
      className={`flex items-center w-full gap-x-2 max-w-[300px] ${className}`}
    >
      <ErrorWithSkewIcon className="w-full h-full" />
      <div className="space-y-1.5 text-right">
        {numberOfRetries !== maxRetries && (
          <Button
            variant={ButtonVariantEnum.OutlinedRed}
            className="px-1.5 py-0.5"
            onClick={onRetry}
          >
            <RefreshIcon className="w-5 h-5" />
            <span>{`Retry ${numberOfRetries}/${maxRetries}`}</span>
          </Button>
        )}
        {(!noErrorLabel || numberOfRetries === maxRetries) && (
          <div className="text-3xl font-bold text-[#BF7373]">Error</div>
        )}
      </div>
    </div>
  )
}

export default ErrorWithSkew
