import * as React from "react"

import { Logo } from "../Logo"

/** Creates a ComingSoon component that shows a message to the user when a page is not ready
 * @returns {JSX.Element} Returns the ComingSoon component
 */
const ComingSoon = () => {
  return (
    <div className="col-span-12 mt-12 lg:col-start-3 lg:col-end-11">
      <div className="justify-center px-8 py-20 text-center bg-white rounded-md shadow-lg">
        <div className="flex justify-center">
          <Logo className="w-16 h-16" />
        </div>
        <h1 className="mt-4 mb-3 text-4xl font-bold">Coming Soon</h1>
        <p className="mb-4 text-xl">
          We are working hard to finish the development of this page. Thank you
          for your patience.
        </p>
      </div>
    </div>
  )
}

export default ComingSoon
