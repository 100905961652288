import * as React from "react"

import { useSection } from "src/context/section-context"

interface IPropTypes {
  children: Array<React.ReactNode>
}

const Body = ({ children }: IPropTypes) => {
  const { sectionRef } = useSection()

  return (
    <div
      className="flex flex-col bg-gray-700 divide-y divide-slate-50/[.35] rounded-lg shadow-xl"
      ref={sectionRef}
    >
      {children}
    </div>
  )
}

export default Body
