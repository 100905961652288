import * as React from "react"

import { IntersectionObserverWrapper } from "@behaviour-lab/blab-component-library"

import { FilterPanelButton, MainFilter } from "src/components/Section/Filters"
import { useSection } from "src/context/section-context"

interface IProps {
  maxWidth: number
}

const HeaderFilters = ({ maxWidth }: IProps) => {
  const { filterIds } = useSection()

  const [isAnyFilterHidden, setIsAnyFilterHidden] =
    React.useState<boolean>(false)

  const handleElementsHiddenChange = (hiddenElements: string[]) => {
    const newState = !!hiddenElements.length

    if (newState !== isAnyFilterHidden) {
      setIsAnyFilterHidden(newState)
    }
  }

  // 1279 is a breakpoint for tailwind's xl
  const isFilterButtonWithText = window.innerWidth > 1279

  // 150px/60px is space for FilterPanelButton
  // 16px is a browser's 1rem in px
  const calculatedMaxWidth =
    maxWidth -
    (isFilterButtonWithText ? 150 : 60) -
    (isAnyFilterHidden ? 16 : 0)

  return (
    <div
      className="flex items-center gap-x-4"
      style={{ maxWidth: maxWidth ? `${maxWidth}px` : "auto" }}
    >
      <IntersectionObserverWrapper
        maxWidth={calculatedMaxWidth >= 0 ? calculatedMaxWidth : 1}
        onElementsHidden={handleElementsHiddenChange}
      >
        {filterIds.map(filterId => (
          <div data-observed={filterId} key={filterId}>
            <MainFilter filterId={filterId} />
          </div>
        ))}
      </IntersectionObserverWrapper>
      {isAnyFilterHidden && <FilterPanelButton />}
    </div>
  )
}

export default HeaderFilters
