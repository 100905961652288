import * as React from "react"

import { MobileNav } from "./MobileNav"
import { Sidebar } from "./Sidebar"

/**
 * Create a Navigation component that displays the appropiate navigation bar depending on the screen size
 * @returns {JSX.Element} Returns the Navigation component
 */
const Navigation = () => {
  return (
    <>
      <div className="fixed top-0 left-0 block w-full shadow 2xl:hidden z-[70]">
        <MobileNav />
      </div>
      <div className="hidden 2xl:block">
        <Sidebar />
      </div>
    </>
  )
}

export default Navigation
