import * as React from "react"

import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@behaviour-lab/blab-component-library"
import Link from "next/link"

interface IProps {
  currentStockName: string
  prevStockLink: string
  nextStockLink: string
}

const LINK_CLASS_NAME =
  "px-4 py-2 transition duration-300 rounded-md focus:outline-0 hover:bg-gray-200"

const StockPageTitle = ({
  currentStockName,
  prevStockLink,
  nextStockLink,
}: IProps) => {
  return (
    <div className="flex items-center pt-4">
      {!!prevStockLink && (
        <Link href={prevStockLink} className={LINK_CLASS_NAME}>
          <ChevronLeftIcon className="w-4 h-4" />
        </Link>
      )}
      <h1 className="mx-2 text-3xl font-bold line-clamp-3">
        {currentStockName}
      </h1>
      {!!nextStockLink && (
        <Link href={nextStockLink} className={LINK_CLASS_NAME}>
          <ChevronRightIcon className="w-4 h-4" />
        </Link>
      )}
    </div>
  )
}

export default StockPageTitle
