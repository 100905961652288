import * as React from "react"

import { useBreakpoint } from "@behaviour-lab/blab-component-library"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"

import { MetadataType, SectionTooltipsType } from "src/components/Section/types"
import metaDataSelector from "src/data/sectionMetaData/sectionMetaData"
import { getStockNameBySedolSelector } from "src/redux/analytics-settings/selectors"
import { disableSplitAndCompare } from "src/redux/section-settings/actions"
import { getSectionSettingsSelector } from "src/redux/section-settings/selectors"
import { SectionSettingsStoreType } from "src/redux/types"
import {
  KeyMetricItemType,
  SectionNameEnum,
  FilterNameEnum,
  ConfigNameEnum,
  SplitRequestEnum,
} from "src/types/common"
import {
  applyInteractionToSelection,
  resetAllInteractions,
  SelectionItemType,
} from "src/utils/helpers/visualizationInteractions"
import { useSelectorWithArguments } from "src/utils/hooks"

type SectionContextProps = {
  id: SectionNameEnum
  filterIds: FilterNameEnum[]
  configIds: ConfigNameEnum[]
  keyMetricIds: KeyMetricItemType[]
  small: boolean // determines the height of the Section to reduce CLS
  minHeight: boolean // determines the height of the Section to reduce CLS
  noSplit: boolean // determines if the section has split and compare functionality
  primaryPanelOnly: boolean // determines if the section has only a primary panel
  tooltips?: SectionTooltipsType
  metadata: MetadataType
  sectionRef: React.MutableRefObject<HTMLDivElement | null>
  primaryPanelRef: React.MutableRefObject<HTMLDivElement | null>
  secondaryPanelRef: React.MutableRefObject<HTMLDivElement | null>
  tertiaryPanelRef: React.MutableRefObject<HTMLDivElement | null>
  filterPanelRef: React.RefObject<HTMLDivElement>
  selectedTab: number | null
  setSelectedTab: (selectedTab: number | null) => void
  showSecondaryPanel: boolean
  setShowSecondaryPanel: (showSecondaryPanel: boolean) => void
  showTertiaryPanel: boolean
  setShowTertiaryPanel: (showTertiaryPanel: boolean) => void
  showFilterPanel: boolean
  setShowFilterPanel: (showFilterPanel: boolean) => void
  isSplitActive: boolean
  lastSplit?: SplitRequestEnum
  setLastSplit: (split: SplitRequestEnum | undefined) => void
  showHideInteraction: (params: {
    show: boolean
    split?: SplitRequestEnum
    selectionData: SelectionItemType[]
  }) => void
}

interface ISectionContextProviderProps {
  children?: React.ReactNode
  id: SectionNameEnum
  filterIds: FilterNameEnum[]
  configIds: ConfigNameEnum[]
  keyMetricIds: KeyMetricItemType[]
  small: boolean
  minHeight: boolean
  noSplit: boolean
  primaryPanelOnly?: boolean
  tooltips?: SectionTooltipsType
}

const SectionContext = React.createContext<SectionContextProps>({
  id: SectionNameEnum.BBS_BUY_SELECTION_DISTRIBUTION,
  filterIds: [],
  configIds: [],
  keyMetricIds: [],
  small: false,
  minHeight: false,
  noSplit: false,
  primaryPanelOnly: false,
  tooltips: undefined,
  metadata: {
    title: "",
    pageSource: "",
    location: "",
    description: "",
  },
  sectionRef: { current: null },
  primaryPanelRef: { current: null },
  secondaryPanelRef: { current: null },
  tertiaryPanelRef: { current: null },
  filterPanelRef: { current: null },
  selectedTab: null,
  setSelectedTab: () => undefined,
  showSecondaryPanel: true,
  setShowSecondaryPanel: () => undefined,
  showTertiaryPanel: false,
  setShowTertiaryPanel: () => undefined,
  showFilterPanel: false,
  setShowFilterPanel: () => undefined,
  isSplitActive: true,
  lastSplit: undefined,
  setLastSplit: () => undefined,
  showHideInteraction: () => undefined,
})

function SectionProvider({
  children,
  id,
  filterIds,
  configIds,
  keyMetricIds,
  small,
  minHeight,
  noSplit,
  primaryPanelOnly,
  tooltips,
}: ISectionContextProviderProps) {
  const dispatch = useDispatch()
  const sectionRef = React.useRef<HTMLDivElement>(null)
  const primaryPanelRef = React.useRef<HTMLDivElement>(null)
  const secondaryPanelRef = React.useRef<HTMLDivElement>(null)
  const tertiaryPanelRef = React.createRef<HTMLDivElement>()
  const filterPanelRef = React.createRef<HTMLDivElement>()

  const currentBreakpoint = useBreakpoint()
  const isScreenLargeToSmall = ["xs", "sm", "md", "lg"].includes(
    currentBreakpoint
  )

  const sectionSettings = useSelectorWithArguments<
    SectionNameEnum,
    SectionSettingsStoreType | undefined
  >(getSectionSettingsSelector, id)
  const { splitFilterId } = sectionSettings || {}
  const isSplitActive = !!splitFilterId

  React.useEffect(() => {
    if (!isScreenLargeToSmall || !isSplitActive) return
    dispatch(disableSplitAndCompare({ sectionId: id }))
  }, [isScreenLargeToSmall])

  const [lastSplit, setLastSplit] = React.useState<
    SplitRequestEnum | undefined
  >(undefined)

  // When split is disabled, set lastSplit to undefined
  React.useEffect(() => {
    if (!isSplitActive) {
      setLastSplit(undefined)
    }
  }, [isSplitActive])

  React.useEffect(() => {
    if (showTertiaryPanel) {
      setShowTertiaryPanel(false)
    }
  }, [isSplitActive])

  const router = useRouter()
  const stockId = React.useMemo(() => {
    return router.isReady ? (router.query.stock as string) : ""
  }, [router.query])

  const stockName = useSelectorWithArguments<string, string | undefined>(
    getStockNameBySedolSelector,
    stockId
  )

  const [showSecondaryPanel, setShowSecondaryPanel] = React.useState(true)
  const [showTertiaryPanel, setShowTertiaryPanel] = React.useState(false)
  const [showFilterPanel, setShowFilterPanel] = React.useState(false)

  const [selectedTab, setSelectedTab] = React.useState<number | null>(null)

  const metadata: MetadataType = metaDataSelector({ stockName })[id]

  /**
   * Helper function to handle interaction with visualization and persist it
   * @param  {boolean} show - Flag that defines whether interaction should be shown or hidden
   * @param {SplitRequestEnum} split - To which split (standard, a, b) to handle interactions
   * @param {SelectionItemType[]} selectionData - The visualization's data we receive from interaction event
   */
  const showHideInteraction = ({
    show,
    split,
    selectionData,
  }: {
    show: boolean
    split?: SplitRequestEnum
    selectionData: SelectionItemType[]
  }) => {
    if (!sectionRef?.current || !selectionData?.length) return

    if (
      !isSplitActive || // always reset for standard (= no split) views
      (!show && !lastSplit) || // split is active, but no last split is set
      (lastSplit && lastSplit === split) // split is active and apply interaction for the active split
    )
      resetAllInteractions({ selectionData, sectionRef })

    if (!show) return

    for (const selectionItem of selectionData) {
      applyInteractionToSelection({
        sectionRef,
        selectionItem,
        split: split,
      })
    }
  }

  return (
    <SectionContext.Provider
      value={{
        id,
        filterIds,
        configIds,
        keyMetricIds,
        small,
        minHeight,
        noSplit,
        primaryPanelOnly: !!primaryPanelOnly,
        metadata,
        sectionRef,
        primaryPanelRef,
        secondaryPanelRef,
        tertiaryPanelRef,
        filterPanelRef,
        showSecondaryPanel,
        setShowSecondaryPanel,
        showTertiaryPanel,
        setShowTertiaryPanel,
        showFilterPanel,
        setShowFilterPanel,
        selectedTab,
        setSelectedTab,
        isSplitActive,
        lastSplit,
        setLastSplit,
        tooltips,
        showHideInteraction,
      }}
    >
      {children}
    </SectionContext.Provider>
  )
}

/**
 * Hook that provides access to section context
 * @returns SectionContext
 */
const useSection = () => React.useContext(SectionContext)

export { SectionProvider, useSection }
