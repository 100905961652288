import * as React from "react"

import { XMarkIcon } from "@behaviour-lab/blab-component-library"
import { useDispatch } from "react-redux"

import { useSection } from "src/context/section-context"
import { updateSectionFilterSelectedValues } from "src/redux/section-settings/actions"
import { getSectionSettingFiltersSelector } from "src/redux/section-settings/selectors"
import { FiltersStoreType } from "src/redux/types"
import { FilterNameEnum, SectionNameEnum } from "src/types/common"
import { useSelectorWithArguments } from "src/utils/hooks"

import { filtersDictionary } from "../constants"

const AppliedFilters = () => {
  const dispatch = useDispatch()
  const { id } = useSection()
  const [isShowing, setIsShowing] = React.useState(false)

  const activeFilters = useSelectorWithArguments<
    SectionNameEnum,
    FiltersStoreType | undefined
  >(getSectionSettingFiltersSelector, id)

  const activeFilterIds = activeFilters
    ? (Object.keys(activeFilters) as FilterNameEnum[])
    : []

  React.useEffect(() => {
    if (activeFilters && Object.keys(activeFilters).length > 0) {
      setIsShowing(true)
    }
  }, [activeFilters])

  const handleClearAllFilters = () => {
    dispatch(
      updateSectionFilterSelectedValues({
        sectionId: id,
        data: {},
      })
    )
  }

  const handleClearFilter = (key: FilterNameEnum) => {
    const newFilters = { ...activeFilters }
    delete newFilters[key]

    dispatch(
      updateSectionFilterSelectedValues({
        sectionId: id,
        data: newFilters,
      })
    )
  }

  return (
    <>
      {isShowing && (
        <div className="flex flex-row w-full px-5 py-4 space-x-2 border-t border-slate-50/[.35]">
          <p className="font-medium text-white">Applied Filters:</p>
          <div className="flex flex-wrap gap-3 pr-3">
            {activeFilterIds.map(key => {
              return (
                activeFilters?.[key] && (
                  <button
                    key={key}
                    className="flex items-center px-1 text-gray-900 rounded-lg bg-citrus-500 gap-x-2"
                    onClick={() => handleClearFilter(key)}
                  >
                    <span className="text-sm">
                      {filtersDictionary[key]}:{" "}
                      {activeFilters[key]?.join(" - ")}
                    </span>
                    <XMarkIcon className="w-4 h-4 text-gray-800" />
                  </button>
                )
              )
            })}
            {activeFilterIds.length ? (
              <button
                onClick={handleClearAllFilters}
                className="flex items-center px-1 text-white gap-x-2"
              >
                Clear All Filters <XMarkIcon className="w-4 h-4 text-white" />
              </button>
            ) : (
              <p className="text-white">None</p>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default AppliedFilters
