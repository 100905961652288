import { FilterStateType } from "src/context/filter-panel-context"
import { FilterNameEnum } from "src/types/common"

const formatFilterPayload = (filterState: FilterStateType) => {
  return Object.keys(filterState).reduce<Record<FilterNameEnum, string[]>>(
    (acc, curr) => {
      const key = curr as FilterNameEnum

      if (filterState[key]?.length) {
        acc[key] = filterState[key] as string[]
      }

      return acc
    },
    {} as Record<FilterNameEnum, string[]>
  )
}

export default formatFilterPayload
