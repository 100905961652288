import * as React from "react"

import { classNames } from "@behaviour-lab/blab-component-library"

import { OptionType } from "src/types"
import { useSearchWithSuggestions } from "src/utils/hooks"

import { ActiveCategoryButton } from "./ActiveCategoryButton"

interface IPropTypes {
  options: OptionType[]
  updateFn: (item: string) => void
  deleteFn: (item: string) => void
  filterData: { activeCategories: string[] }
  disabled?: boolean
}

const MultiCategorySelect = ({
  options,
  updateFn,
  deleteFn,
  filterData,
  disabled,
}: IPropTypes) => {
  const allValues = options.map((option: OptionType) => option.value)
  const filterRef = React.useRef<HTMLDivElement>(null)

  const {
    inputValue,
    suggestionList,
    selectedIndex,
    handleInputChange,
    handleInputFocus,
    handleKeyDown,
    handleClickSuggestion,
  } = useSearchWithSuggestions({
    allValues: allValues as string[],
    selectedValues: filterData.activeCategories,
    onSelectSuggestion: updateFn,
    outsideClickElRef: filterRef,
  })

  return (
    <div className="relative" ref={filterRef}>
      <div className="flex flex-wrap items-center gap-2 px-2 py-2 bg-white border border-gray-200 rounded-md align-center">
        {filterData.activeCategories?.map((text: string, index: number) => {
          return (
            <div key={index}>
              <ActiveCategoryButton
                text={text}
                clickFn={() => deleteFn(text)}
              />
            </div>
          )
        })}
        <input
          autoComplete="off"
          type="text"
          name="search-bar"
          placeholder="Search..."
          value={inputValue}
          onFocus={handleInputFocus}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          className="border-none focus:outline-none focus:ring-transparent"
        />
      </div>
      <div
        className={classNames("relative left-0 bottom-0", {
          "shadow-md": !!suggestionList.length,
        })}
      >
        {!!suggestionList.length && (
          <ul className="absolute top-0 left-0 z-50 w-full bg-white border-t shadow max-h-[250px] overflow-y-scroll">
            {suggestionList.map((suggestion, index) => {
              return (
                <li
                  key={suggestion + index}
                  onClick={() => handleClickSuggestion(suggestion)}
                  className={`w-full px-3 py-1.5 hover:bg-gray-100 hover:cursor-pointer ${
                    index === selectedIndex ? "bg-gray-100" : "bg-white"
                  }`}
                >
                  {suggestion}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}

export default MultiCategorySelect
