import * as React from "react"

import { useDispatch, useSelector } from "react-redux"

import { FilterPanelProvider } from "src/context/filter-panel-context"
import { SectionProvider } from "src/context/section-context"
import { getAnalyticsSettingsAllByIdSelector } from "src/redux/analytics-settings/selectors"
import { initSectionConfigsSelectedValues } from "src/redux/section-settings/actions"
import {
  ConfigNameEnum,
  FilterNameEnum,
  SectionNameEnum,
  KeyMetricItemType,
  AppliedSettingsType,
} from "src/types/common"

import { Body } from "../Body"
import { Content } from "../Content"
import { Header } from "../Header"
import { Metadata } from "../Metadata"
import { SectionTooltipsType } from "../types"

interface IPropTypes {
  children: React.ReactNode
  id: SectionNameEnum
  configIds?: ConfigNameEnum[]
  filterIds?: FilterNameEnum[]
  keyMetricIds?: Array<KeyMetricItemType>
  small?: boolean
  minHeight?: boolean
  noSplit?: boolean
  primaryPanelOnly?: boolean
  tooltips?: SectionTooltipsType
}

/**
 * Creates a Section component that is used to wrap a section of the app and accepts a number of configuration options
 * * REQUIRED PROPS
 * @param {React.ReactNode} children - The children of the section
 * @param {SectionNameEnum} id - The id of the section
 * * OPTIONAL PROPS
 * @param {ConfigNameEnum[]} configIds - Array of config ids
 * @param {FilterNameEnum[]} filterIds - Array of filter ids
 * @param {KeyMetricItemType[]} keyMetricIds - Array of keyMetric ids
 * @param {boolean} small - Boolean to indicate if the section should be small
 * @param {boolean} minHeight - Boolean to indicate if the section has a min height
 * @param {boolean} noSplit - Boolean to indicate if the section can split
 * @param {boolean} primaryPanelOnly - Boolean to indicate if the section only has a primary panel
 * @param {SectionTooltipsType} tooltips - Object with the content for the tooltips
 * @returns {JSX.Element} - Returns the Section component
 */
const Section = ({
  children,
  id,
  configIds = [],
  filterIds = [],
  keyMetricIds = [],
  small = false,
  minHeight = false,
  noSplit = false,
  primaryPanelOnly,
  tooltips,
}: IPropTypes) => {
  const dispatch = useDispatch()
  const analyticsSettings = useSelector(getAnalyticsSettingsAllByIdSelector)

  React.useEffect(() => {
    // Initialise the values of the configs for the section
    if (!configIds.length) return

    const analyticsSettingConfigValues = configIds.reduce(
      (acc: AppliedSettingsType, curr) => {
        // Only initialise if a selected value has been provided
        const selectedValue = analyticsSettings[curr]?.selected
        if (selectedValue) acc[curr] = selectedValue

        return acc
      },
      {}
    )

    dispatch(
      initSectionConfigsSelectedValues({
        sectionId: id,
        data: analyticsSettingConfigValues,
      })
    )
  }, [analyticsSettings])

  return (
    <SectionProvider
      id={id}
      filterIds={filterIds}
      configIds={configIds}
      keyMetricIds={keyMetricIds}
      small={small}
      minHeight={minHeight}
      noSplit={noSplit}
      primaryPanelOnly={primaryPanelOnly}
      tooltips={tooltips}
    >
      <FilterPanelProvider>
        <Metadata />
        <Body>
          <Header />
          <Content>{children}</Content>
        </Body>
      </FilterPanelProvider>
    </SectionProvider>
  )
}

export default Section
