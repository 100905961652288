import {
  OVERVIEW_ROUTE,
  PORTFOLIO_CONSTRUCTION_ROUTE,
  BUY_SELECTION_ROUTE,
  BUY_TIMING_ROUTE,
  WEIGHTING_ROUTE,
  SELL_TIMING_ROUTE,
  VALUE_CREATION_OVER_TIME_ROUTE,
  STOCKS_ROUTE,
} from "src/data/navigation/routes"

type MetaDataType = {
  title: string
  pageSource: string
  location: string
  description: string
}

interface MetadataSelectorProps {
  stockName: string | undefined
}

type SectionMetaDataType = (props: MetadataSelectorProps) => {
  [index: string]: MetaDataType
}

// TODO: Use section name enums for the keys of the object below
const metaDataSelector: SectionMetaDataType = ({
  stockName = "",
}: MetadataSelectorProps) => ({
  // Section Names for Overview Page (4)
  O_FUND_PERFORMANCE: {
    title: "Overall Fund Performance",
    pageSource: "Overview",
    location: OVERVIEW_ROUTE,
    description:
      "How did my individual stock selection and weighting decisions contribute to my fund performance?",
  },
  // Section Names for Portfolio Construction Page (4)
  PC_EXPOSURE_OVER_TIME: {
    title: "Exposure Over Time",
    pageSource: "Portfolio Construction",
    location: PORTFOLIO_CONSTRUCTION_ROUTE,
    description:
      "What was my portfolio weight to different categories over time, and what was the impact?",
  },
  PC_PERFORMANCE: {
    title: "Performance vs Benchmark",
    pageSource: "Portfolio Construction",
    location: PORTFOLIO_CONSTRUCTION_ROUTE,
    description: "How did my portfolio perform over time?",
  },
  PC_RISK: {
    title: "Risk",
    pageSource: "Portfolio Construction",
    location: PORTFOLIO_CONSTRUCTION_ROUTE,
    description: "How exposed was my portfolio to market risk?",
  },
  PC_SELECTION: {
    title: "Selection Across Categories",
    pageSource: "Portfolio Construction",
    location: PORTFOLIO_CONSTRUCTION_ROUTE,
    description:
      "What was the impact of my selection decisions relative to other stocks in the same category?",
  },
  PC_ALLOCATION: {
    title: "Allocation Across Categories",
    pageSource: "Portfolio Construction",
    location: PORTFOLIO_CONSTRUCTION_ROUTE,
    description: "What is the impact of my active allocation decisions?",
  },
  // Section Names for Weighting Page (5)
  // TODO: Area cards might still have metadata down the line for search purposes but it is not necessarily visible
  W_WEIGHTING_OF_ADDS_AND_TRIMS: {
    title: "Adds and Trims",
    pageSource: "Weighting",
    location: WEIGHTING_ROUTE,
    description:
      "How did my individual conviction and opportunistic add and trim decisions contribute to my overall fund performance over time, and did these occur during noteworthy events?",
  },
  W_INITIAL_WEIGHTING: {
    title: "Initial Portfolio Weighting",
    pageSource: "Weighting",
    location: WEIGHTING_ROUTE,
    description:
      "What was the relationship between my initial stock weightings, and their impact on my fund, versus my selected benchmark throughout the holding period?",
  },
  W_WEIGHTING_ACROSS_THE_JOURNEY: {
    title: "Weighting Across the Journey",
    pageSource: "Weighting",
    location: WEIGHTING_ROUTE,
    description:
      "What was the relationship between my stocks' average weighting, and their impact on my  fund, versus my selected benchmark throughout the holding period?",
  },
  // Section Names for Buying - Buy Selection Page (4)
  BBS_BUY_SELECTION_SUMMARY: {
    title: "Summary",
    pageSource: "Buy Selection",
    location: BUY_SELECTION_ROUTE,
    description:
      "Understand how successful your stock selection has been and how much impact the selection of individual names and categories has had on your portfolio.\n\
       How success i.e. great, ok and suboptimal decisions are defined depends on your choice of benchmark, performance, and time thresholds.\n\
       The impact is calculated by taking a neutral weighting as per the benchmark of your choice, to ensure that the weighting decision is kept separate from the decision of selecting a specific name.",
  },
  BBS_BUY_SELECTION_PERFORMANCE: {
    title: "Buy Selection Success Over Time",
    pageSource: "Buy Selection",
    location: BUY_SELECTION_ROUTE,
    description:
      "How successful were my selection decisions versus my selected benchmark, and what was the impact of these?",
  },
  BBS_BUY_SELECTION_DISTRIBUTION: {
    title: "Buy Selection Success Distribution",
    pageSource: "Buy Selection",
    location: BUY_SELECTION_ROUTE,
    description:
      "How was the success of my selection decisions distributed across time and individual stocks?",
  },
  BBS_SELECTION_BY_CATEGORY: {
    title: "Buy Selection Success Comparison",
    pageSource: "Buy Selection",
    location: BUY_SELECTION_ROUTE,
    description:
      "What was the success of my selection decisions versus my selected benchmark, and what was the impact on my fund within categories?",
  },
  // Section Names for Buying - Buy Timing Page (6)
  BBT_BUY_TIMING_PERFORMANCE: {
    location: BUY_TIMING_ROUTE,
    pageSource: "Buy Timing Outcomes",
    title: "Buy Timing Performance",
    description: "Buy Timing Performance description to go here",
  },
  BBT_BUY_TIMING_BY_CATEGORY: {
    title: "Buy Timing Outcomes By Category",
    pageSource: "Buy Timing",
    location: BUY_TIMING_ROUTE,
    description: "Buy Timing Performance By Category description to go here",
  },
  // Section Names for Selling - Sell Timing (6)
  SST_SELL_TIMING_PERFORMANCE: {
    title: "Sell Timing Outcomes",
    pageSource: "Sell Timing",
    location: SELL_TIMING_ROUTE,
    description:
      "How much value did my stocks generate in the period before and after sell?",
  },
  SST_SELL_TIMING_BY_CATEGORY: {
    title: "Sell Timing Outcomes By Category",
    pageSource: "Sell Timing",
    location: SELL_TIMING_ROUTE,
    description:
      "How much value did my stocks generate in the period before and after sell? Broken down by categories.",
  },
  SST_PEAKS_AND_TROUGHS: {
    title: "Peaks and Troughs",
    pageSource: "Sell Timing",
    location: SELL_TIMING_ROUTE,
    description:
      "How much value did my stocks generate from their peak or trough during the holding period?",
  },
  // Section Names for Value Creation Over Time Page (3)
  VCOT_HOLDING_PERIOD_ANALYSIS: {
    location: VALUE_CREATION_OVER_TIME_ROUTE,
    pageSource: "Value Creation Over Time",
    title: "Holding Period Analysis",
    description:
      "When over the holding period did my stocks generate value, and what was the impact on the portfolio?",
  },
  STOCK_GRAPH: {
    location: STOCKS_ROUTE,
    pageSource: "Stocks",
    title: `${stockName} Graph`,
    description: ``,
  },
})

export default metaDataSelector
