import { SplitRequestEnum, FilterNameEnum } from "src/types/common"

export const splitGroup = {
  [SplitRequestEnum.SPLIT_A]: "Split A",
  [SplitRequestEnum.SPLIT_B]: "Split B",
  [SplitRequestEnum.STANDARD]: "",
}

export const filtersDictionary: {
  [filterName in FilterNameEnum]: string
} = {
  [FilterNameEnum.BUCKET_FILTER]: "Bucket",
  [FilterNameEnum.COUNTRY_FILTER]: "Geography",
  [FilterNameEnum.SECTOR_FILTER]: "Sector",
  [FilterNameEnum.STYLE_FILTER]: "Style",
  [FilterNameEnum.THEME_FILTER]: "Theme",
  [FilterNameEnum.FUND_MANAGER_FILTER]: "Fund Manager",
}
