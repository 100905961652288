import * as React from "react"

import { ErrorWithSkew } from "../ErrorWithSkew"

interface IProps {
  numberOfRetries: number
  maxRetries: number
  noErrorLabel?: boolean
  onRetry?: () => void | Promise<void>
  className?: string
}

const AreaCardError = ({
  onRetry,
  numberOfRetries,
  noErrorLabel,
  maxRetries,
  className = "",
}: IProps) => {
  return (
    <div
      className={`h-32 w-full bg-gray-700 rounded-lg p-4 max-w-[300px] ${className}`}
    >
      <ErrorWithSkew
        onRetry={onRetry}
        numberOfRetries={numberOfRetries}
        maxRetries={maxRetries}
        noErrorLabel={noErrorLabel}
        className="h-full p-2 bg-white gap-x-2 border-2 border-[#BF7373] rounded"
      />
    </div>
  )
}

export default AreaCardError
