import * as React from "react"

import {
  EllipsisHorizontalIcon,
  usePrint,
} from "@behaviour-lab/blab-component-library"
import { Menu, Transition } from "@headlessui/react"

import { useSection } from "src/context/section-context"

import { Option } from "../Option"

const MoreOptions = () => {
  const { sectionRef, primaryPanelRef, secondaryPanelRef, tertiaryPanelRef } =
    useSection()
  const { saveToPng: saveToPngSection } = usePrint(
    sectionRef as React.MutableRefObject<HTMLElement>
  )
  const { saveToPng: saveToPngPrimary } = usePrint(
    primaryPanelRef as React.MutableRefObject<HTMLElement>
  )
  const { saveToPng: saveToPngSecondary } = usePrint(
    secondaryPanelRef as React.MutableRefObject<HTMLElement>
  )
  const { saveToPng: saveToPngTertiary } = usePrint(
    tertiaryPanelRef as React.MutableRefObject<HTMLElement>
  )

  const secondaryPanelScreenshotItem = {
    onClick: () => saveToPngSecondary("Table Screenshot"),
    content: "Screenshot Table",
  }
  const tertiaryPanelScreenshotItem = {
    onClick: () => saveToPngTertiary("Table Screenshot"),
    content: "Screenshot Table",
  }

  const menuItems = [
    {
      onClick: () => saveToPngSection("Whole Section Screenshot"),
      content: "Screenshot Whole Section",
    },
    {
      onClick: () => saveToPngPrimary("Graph Screenshot"),
      content: "Screenshot Graph",
    },
  ].concat(
    secondaryPanelRef?.current
      ? !tertiaryPanelRef?.current
        ? [secondaryPanelScreenshotItem]
        : [tertiaryPanelScreenshotItem]
      : tertiaryPanelRef?.current
      ? [tertiaryPanelScreenshotItem]
      : []
  )

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button className="inline-block px-3 py-3 bg-white border-none rounded-md shadow-sm hover:bg-gray-50">
            <EllipsisHorizontalIcon className="text-gray-700 w-7 h-7" />
          </Menu.Button>

          <div id="section-more-options-menu">
            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 z-40 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  {menuItems.map(item => {
                    return <Option {...item} key={item.content} />
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </div>
        </>
      )}
    </Menu>
  )
}

export default MoreOptions
