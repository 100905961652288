import * as React from "react"

import { ExclamationOutline } from "@behaviour-lab/blab-component-library"

interface IPropTypes {
  message?: string
  short?: boolean
  className?: string
}
/**
 * Creates an ErrorMessage component that shows an error message
 * * OPTIONAL PROPS
 * @param {boolean} message - Error message
 * @param {boolean} short - Whether to show the error message in a short format
 * @param {string} className - The class names to apply to the component
 * @returns {JSX.Element} Returns the ErrorMessage component
 */
const ErrorMessage = ({ message, short, className }: IPropTypes) => {
  return (
    <div
      className={`flex items-center text-red-600 gap-x-2 ${
        className ? className : ""
      }`}
    >
      <ExclamationOutline width={26} height={26} />
      <p className="text-lg">
        {message && message}
        {!message &&
          (short
            ? "Something went wrong"
            : "Something went wrong, please try reloading the page or contact the engineering team")}
      </p>
    </div>
  )
}

export default ErrorMessage
