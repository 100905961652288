import * as React from "react"

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  ButtonVariantEnum,
  LogoutOutline,
  Tooltip,
  useAuth,
} from "@behaviour-lab/blab-component-library"
import { useDispatch } from "react-redux"

import { Logo } from "src/components/Logo"
import SidebarLink from "src/components/Navigation/Links/SidebarLink"
import { UserInfo } from "src/components/UserInfo"
import ORDERED_LINKS from "src/data/navigation/orderedLinks"
import { toggleInternalSidebar } from "src/redux/UI/actions"
import { TopLevelNavigationLinkType } from "src/types/common"

interface IPropTypes {
  collapsed: boolean
}

/**
 * Creates a CollapsedSidebar component that contains a profile section, a collapse button and a navigation
 * * REQUIRED PROPS
 * @param  {boolean} collapsed Boolean that indicates whether the sidebar is collapsed or not
 * @return {JSX.Element} Returns the CollapsedSidebar component
 */
const CollapsedSidebar = ({ collapsed }: IPropTypes) => {
  const dispatch = useDispatch()
  const { initials, logout } = useAuth()

  const [
    { showTooltip, tooltipLabel, referencedElement, offsetCrossAxis },
    setTooltip,
  ] = React.useState<{
    referencedElement: HTMLElement | null
    tooltipLabel: string
    showTooltip: boolean
    offsetCrossAxis: number | undefined
  }>({
    referencedElement: null,
    tooltipLabel: "",
    showTooltip: false,
    offsetCrossAxis: undefined,
  })

  const handleToogleTooltip = React.useCallback(
    ({
      showTooltip,
      event,
      tooltipLabel,
      areSubMenuItems,
    }: {
      showTooltip: boolean
      event: React.MouseEvent
      tooltipLabel: string
      areSubMenuItems?: boolean
    }) => {
      setTooltip({
        showTooltip,
        tooltipLabel,
        referencedElement: event.currentTarget as HTMLElement,
        offsetCrossAxis: areSubMenuItems ? -40 : undefined,
      })
    },
    []
  )

  const showLogoutTooltip = (event: React.MouseEvent) =>
    handleToogleTooltip({
      showTooltip: true,
      tooltipLabel: "Logout",
      event,
    })

  const hideLogoutTooltip = (event: React.MouseEvent) =>
    handleToogleTooltip({
      showTooltip: false,
      tooltipLabel: "Logout",
      event,
    })

  return (
    <div className="relative">
      <div className="flex flex-col h-screen overflow-y-scroll bg-white border-r border-gray-200 shadow items-between">
        <div className="flex flex-col items-center my-4 gap-y-4">
          <Button
            href="/"
            variant={ButtonVariantEnum.Grey}
            className="w-12 h-12 pt-0.5 pt-0.5 pl-0.5 pr-0.5"
          >
            <Logo className="w-full h-full text-gray-800" />
          </Button>
          <Button
            onClick={() => dispatch(toggleInternalSidebar())}
            variant={ButtonVariantEnum.Grey}
          >
            {collapsed ? (
              <ArrowRightIcon className="w-4 h-4 text-gray-500" />
            ) : (
              <ArrowLeftIcon className="w-4 h-4 text-gray-500" />
            )}
          </Button>
        </div>
        <div className="mb-8">
          {initials && <UserInfo initials={initials} />}
        </div>
        <nav className="flex flex-col">
          {ORDERED_LINKS.map(
            ({ label, to, Icon, subMenuItems }: TopLevelNavigationLinkType) => (
              <SidebarLink
                key={label}
                label={label}
                to={to}
                Icon={Icon}
                subMenuItems={subMenuItems}
                isIconView
                onToggleTooltip={handleToogleTooltip}
              />
            )
          )}
          <Button
            onClick={logout}
            variant={ButtonVariantEnum.Pure}
            className="relative items-center justify-center w-full px-4 py-6 rounded-none gap-x-3 hover:bg-gray-200"
            onMouseEnter={showLogoutTooltip}
            onMouseLeave={hideLogoutTooltip}
          >
            <LogoutOutline className="w-6 h-6 text-gray-500" />
          </Button>
        </nav>
      </div>
      {showTooltip && referencedElement && (
        <Tooltip
          referencedElement={referencedElement}
          className="whitespace-nowrap"
          offsetPx={2}
          offsetCrossAxis={offsetCrossAxis}
        >
          {tooltipLabel}
        </Tooltip>
      )}
      {/* Should be outside the div that has overflow-y-scroll, as submenu items overflow the navbar*/}
      <div id="submenu" />
    </div>
  )
}

export default CollapsedSidebar
