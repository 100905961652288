/* eslint-disable  sort-exports/sort-exports */
export const OVERVIEW_ROUTE = "/overview"

export const PORTFOLIO_CONSTRUCTION_ROUTE = "/portfolio-construction"

export const VALUE_CREATION_OVER_TIME_ROUTE = "/value-creation-over-time"

// ToDo: to revert back the nested sub menu, check commit 775537ee8f569c3e1b415cb8ee3a33db26a44538
export const BUYING_ROUTE = "" // /buying
export const BUY_SELECTION_ROUTE = `${BUYING_ROUTE}/buy-selection`
export const BUY_TIMING_ROUTE = `${BUYING_ROUTE}/buy-timing`

export const WEIGHTING_ROUTE = "/weighting"

// ToDo: to revert back the nested sub menu, check commit 775537ee8f569c3e1b415cb8ee3a33db26a44538
export const SELLING_ROUTE = "" // /selling
export const SELL_TIMING_ROUTE = `${SELLING_ROUTE}/sell-timing`

export const STOCKS_ROUTE = "/stocks"
