import * as React from "react"

interface IPropTypes {
  title: string
  description: string
  className?: string
}

/**
 * Creates an ErrorState component that shows an error with a title and description
 * * REQUIRED PROPS
 * @param {string} title - The title of the error
 * @param {string} description - The description of the error
 * @returns {JSX.Element} Returns the ErrorState component
 */
const ErrorState = ({
  title = "Something went wrong",
  description = "Please try reloading the page or contact the engineering team",
  className = "",
}: IPropTypes) => {
  return (
    <div
      className={`flex flex-col items-center justify-center mx-auto ${className}`}
    >
      <div className="text-center">
        <h1 className="text-4xl font-bold">{title}</h1>
        <p className="text-lg">{description}</p>
      </div>
    </div>
  )
}

export default ErrorState
