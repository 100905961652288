import { useSelector } from "react-redux"

import {
  getAnalyticsSettingsPendingSelector,
  getAnalyticsSettingsErrorSelector,
  getAnalyticsSettingsAllByIdSelector,
} from "src/redux/analytics-settings/selectors"

const useAnalyticsSettings = () => {
  const pending = useSelector(getAnalyticsSettingsPendingSelector)
  const error = useSelector(getAnalyticsSettingsErrorSelector)
  const data = useSelector(getAnalyticsSettingsAllByIdSelector)
  return {
    pending,
    error,
    data,
  }
}

export default useAnalyticsSettings
