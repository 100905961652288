import * as React from "react"

import { useDispatch, useSelector } from "react-redux"

import {
  ISomethingBrokeProps,
  IErrorLoadingDataProps,
} from "src/components/Errors"
import { getClientSettingsPortfolioIDSelector } from "src/redux/client-settings/selectors"
import { fetchCustomizationRequest } from "src/redux/customizations/actions"
import { fetchSectionDataRequest } from "src/redux/section-data/actions"
import {
  getSectionDataErrorSelector,
  getSectionDataPendingSelector,
  getSectionDataSelector,
  isSectionDataFetchEnabledSelector,
} from "src/redux/section-data/selectors"
import { PluginResultData } from "src/redux/types"
import {
  PluginNameEnum,
  SplitRequestEnum,
  InjectedParamsType,
  CurrentAnalyticsSettingsType,
} from "src/types/common"
import { useSelectorWithArguments } from "src/utils/hooks"

import {
  isDataCorrespondentWithInterface,
  responseInterfaces,
  generateErrorMessageForBrokenPluginResponse,
} from "../pluginResponseValidation"

interface IPropTypes {
  stateId: PluginNameEnum
  injectedParams?: InjectedParamsType
  analyticsSettings: CurrentAnalyticsSettingsType
  children: (data: { data: PluginResultData }) => React.ReactNode
  LoadingState: React.FC
  ErrorState: React.FC<ISomethingBrokeProps & IErrorLoadingDataProps>
  numberOfRetries: number
  maxRetries: number
  onRetry: () => void
}

const ExternalWrapper = ({
  stateId,
  injectedParams,
  analyticsSettings,
  children,
  LoadingState,
  ErrorState,
  onRetry,
  numberOfRetries,
  maxRetries,
}: IPropTypes) => {
  const dispatch = useDispatch()
  const portfolioID = useSelector(getClientSettingsPortfolioIDSelector)
  const isSectionDataFetchEnabled = useSelector(
    isSectionDataFetchEnabledSelector
  )

  const handleLoadData = () => {
    dispatch(
      fetchSectionDataRequest({
        stateId,
        globalSettings: analyticsSettings,
        sectionFilters: {},
        sectionConfigs: {},
        injectedParams: injectedParams,
        reqType: SplitRequestEnum.STANDARD,
      })
    )

    // TODO: temporary condition, fetch customization only for stock-table. In future maybe better to move the condition logic to the saga
    if (stateId === PluginNameEnum.STOCK_TABLE && portfolioID) {
      dispatch(
        fetchCustomizationRequest({ stateId, portfolioId: +portfolioID })
      )
    }
  }

  const handleRetry = () => {
    handleLoadData()
    onRetry()
  }

  React.useEffect(() => {
    if (!isSectionDataFetchEnabled) return

    handleLoadData()
  }, [stateId, analyticsSettings, injectedParams, isSectionDataFetchEnabled])

  const data = useSelectorWithArguments<
    { stateId: PluginNameEnum; reqType: SplitRequestEnum },
    PluginResultData
  >(getSectionDataSelector, { stateId, reqType: SplitRequestEnum.STANDARD })
  const pending = useSelectorWithArguments<
    { stateId: PluginNameEnum; reqType: SplitRequestEnum },
    boolean
  >(getSectionDataPendingSelector, {
    stateId,
    reqType: SplitRequestEnum.STANDARD,
  })
  const error = useSelectorWithArguments<
    { stateId: PluginNameEnum; reqType: SplitRequestEnum },
    string | null
  >(getSectionDataErrorSelector, {
    stateId,
    reqType: SplitRequestEnum.STANDARD,
  })

  if (pending) {
    return <LoadingState />
  }

  const isPluginInterfaceBrokenError =
    !error &&
    !isDataCorrespondentWithInterface(data, responseInterfaces[stateId])

  // We want to dispatch the same action regardless of whether or not we are in a section,
  // but if we are not we just want those section specific values to be empty
  if (error || isPluginInterfaceBrokenError) {
    if (isPluginInterfaceBrokenError) {
      console.error(generateErrorMessageForBrokenPluginResponse(stateId))
    }

    return (
      <ErrorState
        onRetry={handleRetry}
        numberOfRetries={numberOfRetries}
        maxRetries={maxRetries}
      />
    )
  }

  return <>{children({ data })}</>
}

export default ExternalWrapper
