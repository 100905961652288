import * as React from "react"

import {
  Button,
  ButtonVariantEnum,
  RefreshIcon,
} from "@behaviour-lab/blab-component-library"

import ErrorLoadingIcon from "./ErrorLoadingIcon"

export interface IErrorLoadingDataProps {
  numberOfRetries: number
  maxRetries: number
  onRetry: () => void | Promise<void>
  className?: string
}

const ErrorLoadingData = ({
  numberOfRetries,
  className = "",
  onRetry,
  maxRetries,
}: IErrorLoadingDataProps) => {
  return (
    <div
      className={`flex flex-col grow items-center justify-center w-full h-full gap-y-2 ${className}`}
    >
      <ErrorLoadingIcon />
      <div className="text-center">
        <p className="text-3xl font-bold text-[#BF7373]">Error Loading Data</p>
        <p className="text-lg text-[#BF7373]">
          We&apos;re working on finding out why.
        </p>
      </div>
      {numberOfRetries !== maxRetries && (
        <Button
          variant={ButtonVariantEnum.OutlinedRed}
          className="mt-2"
          onClick={onRetry}
        >
          <RefreshIcon className="w-5 h-5" />
          <span>{`Click to Retry (${numberOfRetries}/${maxRetries})`}</span>
        </Button>
      )}
    </div>
  )
}

export default ErrorLoadingData
