/**
 * Helper function to create the label for the applied filter values shown in the FilterWithPopover component
 * * REQUIRED:
 * @param {string[]} filterValues - The array of filter values
 * * OPTIONAL:
 * @param {number} max - The maximum number of visible selected filters
 * @returns {string} - The label for the applied filter values
 */
const createAppliedFiltersLabel = (filterValues: string[], max = 1) => {
  return filterValues.length > max
    ? "Multiple"
    : filterValues.join(", ") || "All"
}

export default createAppliedFiltersLabel
