/**
 * Simple helper function that checks if a string can be parsed to a number (integer or float)
 * @param {string} value - The string to check
 * @returns {boolean}
 */
const isNumber = (value: string | number) => {
  if (typeof value === "number") {
    return true
  }

  // Valid integer (positive or negative) || valid float
  if (value.match(/^-?\d+$/) || value.match(/^\d+\.\d+$/)) {
    return true
  }

  return false
}

export default isNumber
