import * as React from "react"

import {
  classNames,
  useOnClickOutside,
} from "@behaviour-lab/blab-component-library"

import { useSection } from "src/context/section-context"

import { Controls } from "./Controls"
import { FiltersArea } from "./FiltersArea"
import { Header } from "./Header"

const FilterPanel = () => {
  const { filterPanelRef, showFilterPanel, setShowFilterPanel } = useSection()

  const handleClickOutside = () => {
    setShowFilterPanel(false)
  }

  useOnClickOutside(filterPanelRef, handleClickOutside)

  return (
    <div
      className={classNames(
        "absolute right-0 w-full md:w-[70%] xl:w-[49%] top-4 bottom-4 z-40 rounded-sm bg-gray-50 shadow-lg",
        {
          hidden: !showFilterPanel,
        }
      )}
      ref={filterPanelRef}
    >
      <div className="relative flex flex-col h-full gap-y-4">
        <Header className="sticky top-0 z-50 p-4 shadow bg-gray-50" />
        <FiltersArea className="px-4 overflow-auto" />
        <Controls className="px-4 pt-2 pb-4 border-t border-gray-200 bg-gray-50" />
      </div>
    </div>
  )
}

export default FilterPanel
