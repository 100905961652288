import * as React from "react"

import { ClockOutline } from "@behaviour-lab/blab-component-library"

import { SELL_TIMING_ROUTE } from "../routes"

type IconProps = {
  className?: string
}

// ToDo: to revert back the nested sub menu, check commit 775537ee8f569c3e1b415cb8ee3a33db26a44538
const sellingLink = {
  label: "Sell Timing",
  to: SELL_TIMING_ROUTE,
  Icon: ({ className }: IconProps) => <ClockOutline className={className} />,
}

export default sellingLink
