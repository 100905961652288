import * as React from "react"

import { PageSummary } from "src/components/PageSummary"
import { useSection } from "src/context/section-context"

const Metadata = () => {
  const {
    metadata: { title, description },
  } = useSection()

  return <PageSummary title={title} text={description} />
}

export default Metadata
