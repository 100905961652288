import * as React from "react"

import { OptionType } from "src/types/common"

import { MultiCategorySelect } from "./variations"

interface IPropTypes {
  title: string
  values: string[]
  updateFn: (item: string) => void
  deleteFn: (item: string) => void
  options: OptionType[]
  description?: string
  fullWidth?: boolean
  disabled?: boolean
}

const Filter = ({
  title,
  values,
  updateFn,
  deleteFn,
  options,
  description,
  fullWidth,
  disabled,
}: IPropTypes) => {
  return (
    <div className={`flex flex-col ${disabled ? "opacity-50" : ""}`}>
      <h6 className="text-lg font-bold text-gray-700">{title}</h6>
      <div
        className={`flex ${
          fullWidth
            ? "flex-col gap-y-2"
            : "items-center justify-between gap-x-2"
        }`}
      >
        <p className="text-gray-800">{description}</p>
        <MultiCategorySelect
          options={options}
          updateFn={updateFn}
          deleteFn={deleteFn}
          filterData={{ activeCategories: values }}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default Filter
