import * as React from "react"

import RemovableItemButton from "./RemovableItemButton"

interface IProps {
  items: string[]
  onRemove: (item: string) => void
}

const RemovableItems = ({ items, onRemove }: IProps) => {
  return (
    <div className="flex-grow p-3 border border-gray-300 rounded-md">
      <div className="flex flex-wrap overflow-y-auto gap-x-3 gap-y-3">
        {items.map((text: string, index: number) => {
          return (
            <RemovableItemButton key={index} text={text} onClick={onRemove} />
          )
        })}
      </div>
    </div>
  )
}

export default RemovableItems
