import * as React from "react"

import { useWindowSize } from "@behaviour-lab/blab-component-library"

import { SplitAndCompareButton } from "src/components/Buttons/SplitAndCompareButton"
import { ErrorWithSkew } from "src/components/Errors"
import { AppliedFilters } from "src/components/Section/Filters"
import { MoreOptions } from "src/components/Section/MoreOptions"
import { useSection } from "src/context/section-context"
import {
  getKeyMetricsErrorSelector,
  getKeyMetricsPendingSelector,
} from "src/redux/section-data/selectors"
import {
  KeyMetricItemType,
  PluginNameEnum,
  SplitRequestEnum,
  InjectedParamsType,
} from "src/types/common"
import { useSelectorWithArguments } from "src/utils/hooks"

import { DataQuery } from "../DataQuery"
import { KeyMetric } from "../KeyMetric"

import { HeaderConfigs } from "./HeaderConfigs"
import { HeaderFilters } from "./HeaderFilters"

const Header = () => {
  const { noSplit, keyMetricIds, configIds, filterIds } = useSection()
  const pending = useSelectorWithArguments<
    { keyMetricIds: KeyMetricItemType[]; reqType: SplitRequestEnum },
    boolean
  >(getKeyMetricsPendingSelector, {
    keyMetricIds,
    reqType: SplitRequestEnum.STANDARD,
  })

  const error = useSelectorWithArguments<
    { keyMetricIds: KeyMetricItemType[]; reqType: SplitRequestEnum },
    boolean
  >(getKeyMetricsErrorSelector, {
    keyMetricIds,
    reqType: SplitRequestEnum.STANDARD,
  })

  const noConfigs = configIds.length === 0
  const noFilters = filterIds.length === 0

  const { width: windowWidth } = useWindowSize()

  const containerRef = React.useRef<HTMLDivElement>(null)
  const headerConfigsRef = React.useRef<HTMLDivElement>(null)
  const rightSideContentRef = React.useRef<HTMLDivElement>(null)
  const leftSideContentRef = React.useRef<HTMLDivElement>(null)

  const [filtersMaxWidth, setFilterMaxWidth] = React.useState<
    number | undefined
  >()

  React.useLayoutEffect(() => {
    if (noFilters) return

    // To calculate the max possible width for filters-block we also need to take into account the spaces between flex items
    const spacesBetweenBlocks = leftSideContentRef?.current?.children?.length
      ? leftSideContentRef.current.children.length + 1
      : 0

    const calculatedMaxWidth =
      (containerRef?.current?.offsetWidth || 0) -
      (headerConfigsRef?.current?.offsetWidth || 0) -
      (rightSideContentRef?.current?.offsetWidth || 0) -
      spacesBetweenBlocks * 2 * 16 // 16px is a default 1rem value of browser; 2 is a coefficient as gap-x-8 means 2rem

    setFilterMaxWidth(calculatedMaxWidth <= 0 ? undefined : calculatedMaxWidth)
  }, [
    noFilters,
    containerRef,
    headerConfigsRef,
    rightSideContentRef,
    leftSideContentRef,
    windowWidth,
    pending,
    error,
  ])

  return (
    <>
      {!(noConfigs && noFilters && noSplit) && (
        <div>
          <div className="w-full p-4">
            <div
              className="flex items-center justify-between w-full align-center gap-x-6"
              ref={containerRef}
            >
              <div
                className="flex items-center w-full align-center gap-x-6"
                ref={leftSideContentRef}
              >
                {!noConfigs && (
                  <div ref={headerConfigsRef}>
                    <HeaderConfigs />
                  </div>
                )}
                {!noFilters && filtersMaxWidth && (
                  <HeaderFilters maxWidth={filtersMaxWidth} />
                )}
              </div>
              {/* This will require a lot more time to get into a functional state - out of scope for now */}
              <div
                className="flex items-center gap-x-6"
                ref={rightSideContentRef}
              >
                {!noSplit && !!filterIds.length && <SplitAndCompareButton />}
                {keyMetricIds?.map((keyMetricId: KeyMetricItemType) => {
                  return (
                    <DataQuery
                      key={
                        (keyMetricId as { stateId: PluginNameEnum })?.stateId ||
                        (keyMetricId as string)
                      }
                      stateId={
                        (keyMetricId as { stateId: PluginNameEnum })?.stateId ||
                        (keyMetricId as PluginNameEnum)
                      }
                      injectedParams={
                        (
                          keyMetricId as {
                            stateId: PluginNameEnum
                            injectedParams?: InjectedParamsType
                          }
                        )?.injectedParams
                      }
                      render={({ result }) => {
                        return <KeyMetric data={result} />
                      }}
                      ErrorState={props => (
                        <ErrorWithSkew
                          {...props}
                          noErrorLabel
                          className="w-[200px] h-[52px]" // 52px is height of MoreOptions button, this height is needed to prevent jumping and overflow of ErrorWithSkew
                        />
                      )}
                      noSplit // because we don't split and compare total impacts (not here anyway)
                    />
                  )
                })}
                <MoreOptions />
              </div>
            </div>
          </div>
          {!noFilters && <AppliedFilters />}
        </div>
      )}
    </>
  )
}

export default Header
