import * as React from "react"

import {
  Button,
  ButtonVariantEnum,
  CogOutline,
} from "@behaviour-lab/blab-component-library"

interface IProps {
  opened: boolean
  setOpen: (value: boolean) => void
}

const SidePanelToggle = ({ opened, setOpen }: IProps) => {
  return (
    <div className="flex items-center align-center">
      <Button
        onClick={() => setOpen(!opened)}
        variant={ButtonVariantEnum.Transparent}
      >
        <CogOutline className="w-6 h-6" />
      </Button>
    </div>
  )
}

export default SidePanelToggle
