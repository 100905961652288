import * as React from "react"

const ErrorWithSkewIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="84"
      height="43"
      viewBox="0 0 84 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M29.5825 39.0522H6.41623C5.96965 39.0522 5.557 38.8139 5.33372 38.4272C5.11044 38.0404 5.11044 37.564 5.33373 37.1772L16.9162 17.1772C17.1397 16.7911 17.552 16.5533 17.9981 16.5533C18.4442 16.5533 18.8565 16.7911 19.08 17.1772L30.6625 37.1772C30.8857 37.5638 30.8858 38.04 30.6628 38.4267C30.4398 38.8133 30.0276 39.0518 29.5812 39.0522H29.5825ZM18 20.3022L8.58623 36.5522H27.4162L18 20.3022ZM19.2437 31.5535H16.7437V25.3022H19.2437V31.5535Z"
        fill="#BF7373"
      />
      <path d="M16.75 32.8047H19.25V35.3047H16.75V32.8047Z" fill="#BF7373" />
      <g opacity="0.3">
        <path
          opacity="0.5"
          d="M31.5 10.6094C21.5 30.2094 9.5 36.0001 4 37.6094L80 37C47.5 37 44 -13.8906 31.5 10.6094Z"
          fill="#E5B1AC"
        />
        <g filter="url(#filter0_d_1010_39958)">
          <path
            d="M4 35.6094C9 34.7761 21.5 28.2094 31.5 8.60944C44 -15.8906 47.5 35.6094 80 35.6094"
            stroke="#BF7373"
            strokeLinecap="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1010_39958"
          x="0.499878"
          y="0.5"
          width="83.0001"
          height="40.6095"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.641667 0 0 0 0 0.641667 0 0 0 0 0.641667 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1010_39958"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1010_39958"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default ErrorWithSkewIcon
