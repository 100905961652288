import * as React from "react"

import {
  SparkLine,
  LoadingSpinner,
} from "@behaviour-lab/blab-component-library"

import { ErrorWithSkew } from "src/components/Errors"
import { DataQuery } from "src/components/Section"
import { PluginNameEnum } from "src/types/common"

const OverallFundPerformance = () => {
  return (
    <div className="h-full px-4 w-min space-y-1.5">
      <span className="font-light whitespace-nowrap">
        Overall Fund Performance
      </span>
      <DataQuery
        stateId={PluginNameEnum.FUND_OVERALL_PERFORMANCE_LINE_AND_IMPACT}
        ErrorState={props => (
          // className needed to preventing UI jump from loading state to error state, as LoadingState has 30px height
          <ErrorWithSkew {...props} className="h-[30px]" noErrorLabel />
        )}
        render={({ result }) => {
          const { line, value } = result
          return (
            <div className="flex gap-x-4">
              <div className="h-[30px] w-[60%]]">
                <SparkLine data={line} graphID="spark-line" color="#180770" />
              </div>
              <span className="text-xl font-bold whitespace-nowrap">
                {value}
              </span>
            </div>
          )
        }}
        LoadingState={() => (
          // className needed to preventing UI jump from loading state to SparkLine, as SparkLine has 30px height
          <div className="h-[26px]">
            <LoadingSpinner size={26} />
          </div>
        )}
        notInSection
      />
    </div>
  )
}

export default OverallFundPerformance
