import * as React from "react"

import { AnimatePresence, m as motion } from "framer-motion"
import { useSelector } from "react-redux"

import { getExternalSidebarCollapsedSelector } from "src/redux/UI/selectors"

import { CollapsedSidebar } from "./CollapsedSidebar"
import { OpenSidebar } from "./OpenSidebar"

/**
 * Creates a Sidebar component that displays a navigation bar on the left side of the screen
 * @returns {JSX.Element} Returns the Sidebar component
 */
const Sidebar = () => {
  const collapsed = useSelector(getExternalSidebarCollapsedSelector)

  const sidebarConfig = {
    open: {
      width: 300,
      x: 0,
      opacity: 1,
    },
    closed: {
      width: 80,
      x: 0,
      opacity: 1,
    },
  }

  return (
    <AnimatePresence initial={false}>
      <motion.div
        initial={{ x: -200, opacity: 0 }}
        variants={sidebarConfig}
        animate={!collapsed ? "open" : "closed"}
        transition={{ type: "spring", bounce: 0.3, duration: 0.5 }}
        className="h-full overflow-y-scroll bg-white border-r border-gray-200 shadow"
      >
        {collapsed ? (
          <CollapsedSidebar collapsed={collapsed} />
        ) : (
          <OpenSidebar collapsed={collapsed} />
        )}
      </motion.div>
    </AnimatePresence>
  )
}

export default Sidebar
