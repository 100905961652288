import * as React from "react"

import {
  ArrowRightIcon,
  LogoutOutline,
  ArrowLeftIcon,
  ButtonVariantEnum,
  Button,
  useAuth,
} from "@behaviour-lab/blab-component-library"
import { useDispatch } from "react-redux"

import { Logo } from "src/components/Logo"
import { UserInfo } from "src/components/UserInfo"
import { toggleExternalSidebar } from "src/redux/UI/actions"

interface IPropTypes {
  collapsed: boolean
}

/**
 * Creates a CollapsedSidebar component that displays collapsed variation of the sidebar
 * * REQUIRED PROPS
 * @param {boolean} collapsed - Boolean that indicates whether the sidebar is collapsed
 * @returns {JSX.Element} Returns the CollapsedSidebar component
 */
const CollapsedSidebar = ({ collapsed }: IPropTypes) => {
  const dispatch = useDispatch()
  const { initials, logout } = useAuth()

  return (
    <div className="flex flex-col justify-between w-full h-full overflow-x-hidden">
      <div className="flex flex-col items-center my-4 gap-y-4">
        <Button
          href="/"
          variant={ButtonVariantEnum.Grey}
          className="w-12 h-12 pt-0.5 pt-0.5 pl-0.5 pr-0.5"
        >
          <Logo className="w-full h-full text-gray-800" />
        </Button>
        <Button
          onClick={() => dispatch(toggleExternalSidebar())}
          variant={ButtonVariantEnum.Grey}
        >
          {collapsed ? (
            <ArrowRightIcon className="w-4 h-4 text-gray-500" />
          ) : (
            <ArrowLeftIcon className="w-4 h-4 text-gray-500" />
          )}
        </Button>
        {initials && <UserInfo initials={initials} />}
      </div>

      <div className="flex justify-center my-4 gap-y-4">
        <Button onClick={logout} variant={ButtonVariantEnum.Grey}>
          <LogoutOutline className="w-6 h-6" />
        </Button>
      </div>
    </div>
  )
}

export default CollapsedSidebar
