import * as React from "react"

import {
  ChevronDownIcon,
  XMarkIcon,
  CompareSolid,
  classNames,
} from "@behaviour-lab/blab-component-library"
import { Menu } from "@headlessui/react"
import { useDispatch } from "react-redux"

import { useSection } from "src/context/section-context"
import {
  disableSplitAndCompare,
  enableSplitAndCompare,
} from "src/redux/section-settings/actions"
import { getSectionSettingsSelector } from "src/redux/section-settings/selectors"
import { SectionSettingsStoreType } from "src/redux/types"
import { FilterNameEnum, SectionNameEnum } from "src/types/common"
import { useSelectorWithArguments } from "src/utils/hooks"

const buttonBaseClassNames =
  "hidden font-medium text-white rounded-lg xl:flex bg-primary whitespace-nowrap overflow-hidden"

const SplitAndCompareButton = () => {
  const dispatch = useDispatch()
  const { id, filterIds } = useSection()
  const sectionSettings = useSelectorWithArguments<
    SectionNameEnum,
    SectionSettingsStoreType | undefined
  >(getSectionSettingsSelector, id)

  const activeMainFilters = sectionSettings
    ? Object.keys(sectionSettings.filters)
    : []

  const filterIdToOption: Record<FilterNameEnum, string> = {
    BUCKET_FILTER: "By Bucket",
    COUNTRY_FILTER: "By Geography",
    FUND_MANAGER_FILTER: "By Fund Manager",
    SECTOR_FILTER: "By Sector",
    STYLE_FILTER: "By Style",
    THEME_FILTER: "By Theme",
  }

  const handleOnClick = (item: FilterNameEnum) => {
    dispatch(
      enableSplitAndCompare({
        sectionId: id,
        filterId: item,
      })
    )
  }

  const handleOnClose = () => {
    dispatch(disableSplitAndCompare({ sectionId: id }))
  }

  return (
    <Menu as="div" className="relative">
      {!sectionSettings?.splitFilterId ? (
        <Menu.Button
          className={`${buttonBaseClassNames} flex items-center gap-x-2 px-3 py-2 hover:bg-primary-600 active:bg-primary-600`}
        >
          <CompareSolid className="w-5 h-5 text-white" />
          Split and Compare
          <ChevronDownIcon className="w-5 h-5 text-white" />
        </Menu.Button>
      ) : (
        <div
          className={`${buttonBaseClassNames} items-stretch divide-x divide-gray-100`}
        >
          <Menu.Button className="flex items-center px-3 py-2 gap-x-2 hover:bg-primary-600 active:bg-primary-600">
            <CompareSolid className="w-5 h-5 text-white" />
            Split {filterIdToOption[sectionSettings.splitFilterId]}
          </Menu.Button>
          <button
            className="flex items-center px-3 py-2 hover:bg-primary-600 active:bg-primary-600"
            onClick={handleOnClose}
          >
            <XMarkIcon className="w-5 h-5 text-white" />
          </button>
        </div>
      )}
      <Menu.Items className="absolute z-50 flex-col hidden w-full py-2 mt-2 bg-white rounded-md shadow xl:flex">
        {filterIds.map(item => {
          return (
            <DropdownItem
              onClick={() => handleOnClick(item)}
              key={item}
              disabled={activeMainFilters.includes(item)}
            >
              {filterIdToOption[item]}
            </DropdownItem>
          )
        })}
      </Menu.Items>
    </Menu>
  )
}

export default SplitAndCompareButton

interface IDropdownProps {
  children: React.ReactNode
  disabled?: boolean
  onClick: () => void
}

const DropdownItem = ({
  children,
  disabled = false,
  onClick,
}: IDropdownProps) => {
  return (
    <Menu.Item disabled={disabled}>
      {({ active }) => (
        <button
          className={classNames("text-left px-3 py-2 w-full", {
            "bg-gray-100": active,
            "opacity-50 cursor-auto focus:outline-none": disabled,
          })}
          onClick={onClick}
        >
          {children}
        </button>
      )}
    </Menu.Item>
  )
}
