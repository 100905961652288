import * as React from "react"

import { useOnClickOutside } from "@behaviour-lab/blab-component-library"

import { Trie } from "src/components/Section/Panel/variations/FilterPanel/Filter/variations/MultiCategorySelect/Trie"

interface IProps {
  allValues: string[]
  selectedValues: string[]
  suggestionLimit?: number
  onSelectSuggestion: (arg: string) => void
  outsideClickElRef: React.MutableRefObject<HTMLDivElement | null>
}

function useSearchWithSuggestions({
  allValues,
  selectedValues,
  suggestionLimit,
  onSelectSuggestion,
  outsideClickElRef,
}: IProps) {
  const [inputValue, setInputValue] = React.useState<string>("")
  const [suggestionList, setSuggestionList] = React.useState<string[]>([])
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0)

  // ToDo: investigate this issue and fix ts
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const myTrie = new Trie()

  useOnClickOutside(outsideClickElRef, () => setSuggestionList([]))

  React.useEffect(() => {
    if (!allValues.length) return

    for (const rowValue of allValues) {
      if (!rowValue) continue
      myTrie.insert(rowValue.toLowerCase())
    }
  }, [myTrie, allValues])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSelectedIndex(0)
    setInputValue(value)

    // If the input is empty, show all the options
    if (value.length === 0) {
      setSuggestionList(allValues.slice(0, suggestionLimit || allValues.length))

      return
    }

    const trieInputValue = value.toLowerCase()
    const foundWords = myTrie
      .find(trieInputValue)
      .sort((a: string, b: string) => {
        return a.length - b.length
      })

    const newSuggestions = foundWords.length
      ? foundWords
          .slice(0, suggestionLimit || allValues.length)
          .map(
            (foundWord: string) =>
              allValues.find(v => v.toLowerCase() === foundWord) || foundWord
          )
      : []

    setSuggestionList(newSuggestions)
  }

  const handleClickSuggestion = (suggestion: string) => {
    if (!selectedValues.includes(suggestion)) {
      // Add suggestion to selectedValues
      onSelectSuggestion(suggestion)
    }

    // Reset values if the suggestion is already active
    setInputValue("")
    setSelectedIndex(0)
    setSuggestionList([])
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowDown":
        setSelectedIndex(Math.min(selectedIndex + 1, suggestionList.length - 1))
        break
      case "ArrowUp":
        setSelectedIndex(Math.max(selectedIndex - 1, 0))
        break
      case "Enter":
        handleClickSuggestion(suggestionList[selectedIndex])
        break
      default:
    }
  }

  const handleInputFocus = () => {
    if (inputValue.length) return

    const defaultSuggestions = allValues
      .filter(v => !selectedValues.includes(v)) // leave only not-selected values
      .slice(0, suggestionLimit || allValues.length) // leave only limited number of suggestions

    setSuggestionList(defaultSuggestions)
  }

  return {
    inputValue,
    suggestionList,
    selectedIndex,
    handleInputChange,
    handleInputFocus,
    handleKeyDown,
    handleClickSuggestion,
  }
}

export default useSearchWithSuggestions
