import * as React from "react"

import {
  Button,
  ButtonVariantEnum,
  CheckCircleOutline,
  classNames,
} from "@behaviour-lab/blab-component-library"
import { useDispatch } from "react-redux"

import { formatFilterPayload } from "src/components/Section/Filters/functions"
import { useFilterPanel } from "src/context/filter-panel-context"
import { useSection } from "src/context/section-context"
import { updateSectionFilterSelectedValues } from "src/redux/section-settings/actions"

interface IPropTypes {
  className?: string
}

const Controls = ({ className }: IPropTypes) => {
  const dispatch = useDispatch()
  const { id, setShowFilterPanel } = useSection()
  const { filterState } = useFilterPanel()

  const handleCancel = () => setShowFilterPanel(false)

  const handleSubmit = () => {
    if (filterState) {
      dispatch(
        updateSectionFilterSelectedValues({
          sectionId: id,
          data: formatFilterPayload(filterState),
        })
      )
    }

    // Closes filter panel when apply is clicked
    setShowFilterPanel(false)
  }

  return (
    <div
      className={classNames("flex justify-end gap-x-4", {
        [`${className}`]: !!className,
      })}
    >
      <Button variant={ButtonVariantEnum.Transparent} onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant={ButtonVariantEnum.Transparent} onClick={handleSubmit}>
        <CheckCircleOutline className="w-5 h-5" /> Apply
      </Button>
    </div>
  )
}

export default Controls
