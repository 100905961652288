import * as React from "react"

import { NavigationLinkType } from "src/types"

import SubLink from "./SubLink"

interface IProps {
  subMenuItems: NavigationLinkType[]
  isIconView?: boolean
  style?: React.CSSProperties
}

/**
 * Creates a SubLinks component that maps through the SidebarLink's children and returns a sublink
 * * REQUIRED PROPS
 * @param  {boolean} isIconView - Defines the view: true for CollapsedSidebar, false - for Mobile and OpenedSidebar
 * @param  {Array<NavigationLinkType>} subMenuItems - Array of objects containing the sublinks
 * @param  {React.CSSProperties} style - Needed to add a position data (top, bottom, left, right) when showing SubLinks relative to the icon link
 * @return {JSX.Element} Returns the SubLinks component
 */
const SubLinks = ({ subMenuItems, isIconView, style }: IProps) => {
  return (
    <div
      className={
        isIconView
          ? "absolute top-0 z-50 flex flex-col left-20"
          : "flex flex-col"
      }
      style={style}
    >
      {subMenuItems.map(
        ({ label, to, Icon }: NavigationLinkType, i: number) => (
          <SubLink
            key={to + i}
            label={label}
            to={to}
            Icon={Icon}
            isIconView={isIconView}
          />
        )
      )}
    </div>
  )
}

export default SubLinks
