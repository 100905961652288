import { useSelector } from "react-redux"

import {
  getClientSettingsClientsErrorSelector,
  getClientSettingsClientsPendingSelector,
  getClientSettingsClientsSelector,
  getClientSettingsClientIDSelector,
  getClientSettingsPortfolioIDSelector,
  getPortfolioDataByIdSelector,
} from "src/redux/client-settings/selectors"
import { PortfolioType, ClientType } from "src/types/common"

import { useSelectorWithArguments } from "../useSelectorWithArguments"

const useClientSettings = (): {
  clientID: string | null
  portfolioID: string | null
  portfolio: PortfolioType | undefined
  clients: ClientType[]
  pending: boolean
  error: string | null
} => {
  const clientID = useSelector(getClientSettingsClientIDSelector)
  const portfolioID = useSelector(getClientSettingsPortfolioIDSelector)
  const clients = useSelector(getClientSettingsClientsSelector)
  const pending = useSelector(getClientSettingsClientsPendingSelector)
  const error = useSelector(getClientSettingsClientsErrorSelector)
  const portfolio = useSelectorWithArguments<
    string | null,
    PortfolioType | undefined
  >(getPortfolioDataByIdSelector, portfolioID)

  return {
    clientID,
    portfolioID,
    portfolio,
    clients,
    pending,
    error,
  }
}

export default useClientSettings
