import * as React from "react"

import { XMarkIcon } from "@behaviour-lab/blab-component-library"

interface IPropTypes {
  text: string
  clickFn: () => void
}

const ActiveCategoryButton = ({ text, clickFn }: IPropTypes) => {
  const [hovered, setHovered] = React.useState(false)

  return (
    <button
      data-testid="active-category-filter-btn"
      type="button"
      value={text}
      onClick={() => clickFn()}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="flex items-center py-0 my-0 pr-0.5 rounded-md align-center bg-primary-50 hover:bg-primary-100"
    >
      <span className="px-2 font-semibold text-primary-700">{text}</span>
      {hovered && <XMarkIcon className="w-4 h-4 text-gray-800" />}
    </button>
  )
}

export default ActiveCategoryButton
