import * as React from "react"

import { StocksSolid } from "@behaviour-lab/blab-component-library"

import { STOCKS_ROUTE } from "../routes"

type IconProps = {
  className?: string
}

const stocksLink = {
  label: "Stocks",
  to: STOCKS_ROUTE,
  Icon: ({ className }: IconProps) => <StocksSolid className={className} />,
}

export default stocksLink
