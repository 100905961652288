import {
  BUY_SELECTION_ROUTE,
  BUY_TIMING_ROUTE,
  VALUE_CREATION_OVER_TIME_ROUTE,
  PORTFOLIO_CONSTRUCTION_ROUTE,
  SELL_TIMING_ROUTE,
} from "src/data/navigation/routes"
import { ParamNameEnum } from "src/types/common"

export const settingsIdsPerPage = {
  [VALUE_CREATION_OVER_TIME_ROUTE]: [],
  [PORTFOLIO_CONSTRUCTION_ROUTE]: [ParamNameEnum.VOLATILITY_LOOKBACK_WINDOW],
  [BUY_SELECTION_ROUTE]: [
    ParamNameEnum.BUY_SELECTION_HORIZON,
    ParamNameEnum.BUY_SELECTION_PERFORMANCE_THRESHOLD,
  ],
  [BUY_TIMING_ROUTE]: [
    ParamNameEnum.BUY_TIMING_PERFORMANCE_THRESHOLD,
    ParamNameEnum.BUY_TIMING_HORIZON,
  ],
  [SELL_TIMING_ROUTE]: [
    ParamNameEnum.SELL_TIMING_HORIZON,
    ParamNameEnum.SELL_TIMING_PERFORMANCE_THRESHOLD,
  ],
}
