import {
  RawXYCoordinate,
  XYCoordinate,
  getTimeParse,
} from "@behaviour-lab/blab-component-library"
import * as d3 from "d3"

import { AxisFormatEnum } from "src/types/common"

export const bisectLine = (
  line: any,
  xAxisType: AxisFormatEnum | undefined,
  xScale: any,
  event: React.MouseEvent
) => {
  const bisect = d3.bisector((d: XYCoordinate) => d.x).left

  const preprocessedLine = line?.map((d: RawXYCoordinate) => {
    switch (xAxisType) {
      case AxisFormatEnum.DATE:
        return {
          x: getTimeParse(d.x)(d.x),
          y: d.y,
        }
      default:
        return {
          x: d.x,
          y: d.y,
        }
    }
  })

  // Current x coordinate of the mouse
  const x0 = xScale.invert(d3.pointer(event)[0])
  const i = bisect(preprocessedLine, x0, 1)
  const d0 = preprocessedLine[i - 1]
  const d1 = preprocessedLine[i]
  const selectedData = !d1 ? undefined : x0 - d0.date > d1?.date - x0 ? d1 : d0

  return selectedData
}
