import * as React from "react"

import {
  useResizeObserver,
  LoadingSpinner,
  useAuth,
} from "@behaviour-lab/blab-component-library"
import { useRouter } from "next/router"
import { useDispatch, useSelector } from "react-redux"

import { BreadCrumbs } from "src/components/BreadCrumbs"
import { ComingSoon } from "src/components/ComingSoon"
import { ErrorState } from "src/components/Errors"
import { Navigation } from "src/components/Navigation"
import { OverallFundPerformance } from "src/components/Sections/Header/OverallFundPerformance"
import { Seo } from "src/components/Seo"
import { PageTitle } from "src/components/Titles"
import {
  getAnalyticsSettingsPendingSelector,
  getAnalyticsSettingsErrorSelector,
} from "src/redux/analytics-settings/selectors"
import {
  fetchDataForInternalPages,
  updateActivePortfolio,
} from "src/redux/client-settings/actions"
import { setIsSectionDataFetchEnabled } from "src/redux/section-data/actions"
import { BreadCrumbType } from "src/types/common"

import { Header } from "./Header"

type TitleType =
  | {
      title: string
      seoTitle?: never
    }
  | {
      title: React.ReactNode
      seoTitle: string
    }

type IPropTypes = TitleType & {
  children: React.ReactNode
  comingSoon?: boolean
  breadCrumbs?: BreadCrumbType[]
}

/**
 * Creates an InternalLayout component that contains information about the current page
 * * REQUIRED PROPS
 * @param {string | React.ReactNode} title - The title of the page
 * @param {React.ReactNode} children - The children of the component
 * * OPTIONAL PROPS
 * @param {boolean} comingSoon - Boolean that hides the InternalLayout children and shows a coming soon message instead
 * @param {string} seoTitle - When title is of a node type, seoTitle will be added to SEO
 * @param {breadCrumbs} breadCrumbs - List of ordered bread crumbs
 * @returns {JSX.Element} Returns the DefaultLayout component
 */
const InternalLayout = ({
  title,
  seoTitle,
  children,
  comingSoon,
  breadCrumbs,
}: IPropTypes) => {
  const router = useRouter()
  const { clientID, portfolioID } = router.query
  const dispatch = useDispatch()
  const { isAdvisoryOrSuperAdmin, accessiblePortfolios } = useAuth()

  const analyticsSettingsPending = useSelector(
    getAnalyticsSettingsPendingSelector
  )
  const analyticsSettingsError = useSelector(getAnalyticsSettingsErrorSelector)

  React.useEffect(() => {
    // Allow to see the internal pages only for Advisory, Super Admin and users who have access to portfolio
    if (
      typeof portfolioID !== "undefined" &&
      !isAdvisoryOrSuperAdmin &&
      !accessiblePortfolios.includes(+portfolioID)
    ) {
      router.replace("/unauthorized")
    }
  }, [isAdvisoryOrSuperAdmin, accessiblePortfolios, portfolioID])

  // Fetch the settings once each time the page loads to update the redux state once you are inside Polygon and know who the user is
  React.useEffect(() => {
    if (!clientID || !portfolioID) return

    dispatch(setIsSectionDataFetchEnabled(false))
    dispatch(
      updateActivePortfolio({
        clientID: `${clientID}`,
        portfolioID: `${portfolioID}`,
      })
    )

    dispatch(
      fetchDataForInternalPages({
        portfolioID: +portfolioID,
      })
    )
  }, [clientID, portfolioID])

  const headerContainerRef = React.useRef<HTMLDivElement>(null)
  const rightSideContentRef = React.useRef<HTMLDivElement>(null)

  const { width: containerWidth = 0 } =
    useResizeObserver(headerContainerRef) || {}
  const { width: rightSideWidth = 0 } =
    useResizeObserver(rightSideContentRef) || {}

  const maxWidth = React.useMemo(() => {
    return containerWidth - rightSideWidth - 2 * 16 // 16px is a default 1rem value of browser; 2 is a coefficient as space-x-8 means 2rem
  }, [containerWidth, rightSideWidth])

  return (
    <div className="relative flex w-screen h-screen text-gray-800 bg-gray-50">
      <Seo title={(typeof title === "string" ? title : seoTitle) as string} />
      <Navigation />
      <div className="relative flex flex-col w-full max-h-screen overflow-x-hidden overflow-y-scroll pt-22 2xl:pt-0 mt-[76px] 2xl:mt-0">
        <div
          className="z-[60] flex justify-between p-4 space-x-8 bg-white divide-x shadow-md 2xl:sticky 2xl:top-0"
          ref={headerContainerRef}
        >
          <Header maxWidth={maxWidth} />
          <div ref={rightSideContentRef}>
            <OverallFundPerformance />
          </div>
        </div>
        <div className="flex flex-col flex-grow p-6">
          {!!breadCrumbs?.length && <BreadCrumbs breadCrumbs={breadCrumbs} />}
          <div className="pt-4 pb-6">
            {typeof title === "string" ? <PageTitle title={title} /> : title}
          </div>
          <div className="grid flex-grow grid-cols-12 gap-y-10 gap-x-4">
            {analyticsSettingsPending ? (
              <LoadingSpinner className="h-full col-span-12" />
            ) : analyticsSettingsError ? (
              <ErrorState
                title="Configuration Error"
                description="Could not retrieve analytics settings"
                className="col-span-12"
              />
            ) : comingSoon ? (
              <ComingSoon />
            ) : (
              children
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InternalLayout
