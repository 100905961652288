import * as React from "react"

import {
  classNames,
  XMarkIcon,
  ChevronLeftIcon,
  useBreakpoint,
  Button,
  ButtonVariantEnum,
} from "@behaviour-lab/blab-component-library"

import { Title } from "src/components/Section/Panel/Title"
import { ParentPanelEnum } from "src/components/Section/types"
import { useSection } from "src/context/section-context"

interface IPropTypes {
  title?: string
  children: React.ReactElement | React.ReactElement[]
}

const SecondaryPanel = ({ title, children }: IPropTypes) => {
  const titleRef = React.useRef<HTMLDivElement>(null)

  const currentBreakpoint = useBreakpoint()
  const isNarrowScreenView = ["xs", "sm", "md"].includes(currentBreakpoint)

  const {
    secondaryPanelRef,
    showSecondaryPanel,
    setShowSecondaryPanel,
    isSplitActive,
  } = useSection()

  const handleClosePanel = () => setShowSecondaryPanel(false)
  const handleOpenPanel = () => setShowSecondaryPanel(true)

  React.useEffect(() => {
    // This useEffect is needed only for mobile view to close the secondary panel and open it on button-click
    if (!isNarrowScreenView) {
      if (!showSecondaryPanel) setShowSecondaryPanel(true)
      return
    }

    handleClosePanel()
  }, [isNarrowScreenView])

  return (
    <>
      <Button
        onClick={handleOpenPanel}
        variant={ButtonVariantEnum.Outlined}
        className={classNames("absolute right-0 top-0", {
          hidden: !isNarrowScreenView || showSecondaryPanel,
        })}
      >
        <ChevronLeftIcon className="w-5 h-5" />
        <span className="hidden sm:block">{title}</span>
      </Button>
      <div
        ref={secondaryPanelRef}
        className={classNames(
          "w-full lg:w-[50%] lg:min-w-[50%] rounded-sm bg-gray-50",
          {
            hidden: !showSecondaryPanel,
            "absolute left-0 right-0 top-0 bottom-0": isNarrowScreenView,
          }
        )}
      >
        {(!!title || isNarrowScreenView) && (
          <div
            ref={titleRef}
            // z-index should be bigger than z-index of table's sticky column 30, but smaller than tertiary panel 35
            className="sticky top-0 z-[31] flex justify-between px-4 pt-4 pb-4 shadow bg-gray-50"
          >
            <Title>{title || ""}</Title>
            <Button
              variant={ButtonVariantEnum.Transparent}
              onClick={handleClosePanel}
              className={!isNarrowScreenView ? "hidden" : ""}
            >
              <XMarkIcon width={20} />
            </Button>
          </div>
        )}

        <div
          className={classNames("flex flex-col overflow-auto gap-y-12", {
            "pt-2": isSplitActive,
          })}
        >
          {React.Children.map(
            children,
            (child: React.ReactElement<React.PropsWithChildren<any>>) => {
              return React.cloneElement(child, {
                parentPanel: ParentPanelEnum.SECONDARY,
              })
            }
          )}
        </div>
      </div>
    </>
  )
}

export default SecondaryPanel
