import * as React from "react"

import { ParentPanelEnum } from "src/components/Section/types"
import { useSection } from "src/context/section-context"

interface IPropTypes {
  children: React.ReactElement | React.ReactElement[]
}

// Custom top padding values so the graph aligns to the table on the right. These vary depending on whether the section has only a primary panel or not.
const topPaddingOnePanel = "pt-[40px]"
const topPaddingTwoPanels = "pt-[68px]"

const PrimaryPanel = ({ children }: IPropTypes) => {
  const { primaryPanelRef, primaryPanelOnly } = useSection()

  return (
    <div
      ref={primaryPanelRef}
      className={`relative flex flex-col flex-grow gap-y-12 ${
        primaryPanelOnly ? topPaddingOnePanel : topPaddingTwoPanels
      }`}
    >
      {React.Children.map(
        children,
        (child: React.ReactElement<React.PropsWithChildren<any>>) => {
          return React.cloneElement(child, {
            parentPanel: ParentPanelEnum.PRIMARY,
          })
        }
      )}
    </div>
  )
}

export default PrimaryPanel
