/**
 * This module was automatically generated by `ts-interface-builder`
 */
import * as t from "ts-interface-checker"
// tslint:disable:object-literal-key-quotes

export const AreaCard = t.iface([], {
  id: t.opt("string"),
  impact: "number",
  skew: "SkewEnum",
  title: "string",
  keyMetrics: t.array(
    t.iface([], {
      title: "string",
      value: "number",
    })
  ),
})

export const BBS_AREA_CARD = t.iface([], {
  result: "AreaCard",
})

export const BBS_BUY_SELECTION_IMPACT = t.iface([], {
  result: "TotalImpact",
})

export const BBS_BUY_SELECTION_IMPACT_BY_CATEGORY = t.iface([], {
  result: "TotalImpact",
})

export const BBS_BUY_SELECTION_IMPACT_DISTRIBUTION = t.iface([], {
  result: "TotalImpact",
})

export const BBS_BUY_SELECTION_IMPACT_PERFORMANCE = t.iface([], {
  result: "TotalImpact",
})

export const BBS_CATEGORY_PERFORMANCE_TABLE = t.iface([], {
  result: "BbsCategoryPerformanceTable",
})

export const BBS_CATEGORY_STOCK_TABLE = t.iface([], {
  result: "BbsCategoryStockTableAll",
})

export const BBS_DISTRIBUTION_SCATTER_PLOT = t.iface([], {
  result: "ScatterPlot",
})

export const BBS_DISTRIBUTION_SELECTION_PERFORMANCE_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        count: "string",
        year: "string",
        buySelectionImpact: t.union("number", "null"),
        buySelectionPerformance: t.union("number", "null"),
      })
    ),
  }),
})

export const BBS_HORIZONTAL_STACKED_BAR_GRAPH = t.iface([], {
  result: t.iface([], {
    yDataKey: "string",
    keys: t.array("string"),
    data: t.array(
      t.iface([], {
        [t.indexKey]: t.union("number", "string"),
      })
    ),
  }),
})

export const BBS_PERFORMANCE_COLOUR_CODED_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        count: "string",
        impact: t.union("number", "null"),
        medianPerf: t.union("number", "null"),
        outcome: "string",
        proportion: "number",
      })
    ),
  }),
})

export const BBS_PERFORMANCE_HIT_RATE_WIN_LOSS_STOCK_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        year: "string",
        count: "string",
        winLoss: t.opt(t.union("number", "null")),
        hitRate: t.opt(t.union("number", "null")),
      })
    ),
  }),
})

export const BbsPerformanceStockTable = t.iface([], {
  sourceColumns: t.array("SourceColumn"),
  sourceData: t.array(
    t.iface([], {
      buySelectionImpact: "number",
      buySelectionPerformance: "number",
      endDate: "string",
      name: "string",
      sedol: t.opt("string"),
      Sedol: t.opt("string"),
      sellDate: "string",
      buyWeight: "number",
    })
  ),
})

export const BBS_PERFORMANCE_STOCK_TABLE = t.iface([], {
  result: "BbsPerformanceStockTable",
})

export const BBS_PERFORMANCE_STOCK_TABLE_DISTRIBUTION = t.iface([], {
  result: "BbsPerformanceStockTable",
})

export const BBS_PERFORMANCE_STOCK_TABLE_HIT_RATE = t.iface([], {
  result: "BbsPerformanceStockTable",
})

export const BBS_PERFORMANCE_STOCK_TABLE_SELECTION_OVER_TIME = t.iface([], {
  result: "BbsPerformanceStockTable",
})

export const BBS_PERFORMANCE_STOCK_TABLE_WIN_LOSS = t.iface([], {
  result: "BbsPerformanceStockTable",
})

export const BBS_PERFORMANCE_VERTICAL_BAR_GRAPH_WITH_TRENDLINE = t.iface([], {
  result: t.iface([], {
    data: t.array(
      t.iface([], {
        name: t.union("string", "null"),
        value: t.union("number", "null"),
      })
    ),
    trendLineData: t.opt(
      t.iface([], {
        endX: t.union("string", "null"),
        endY: t.union("number", "null"),
        startX: t.union("string", "null"),
        startY: t.union("string", "number", "null"),
      })
    ),
  }),
})

export const BBS_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH = t.iface([], {
  result: t.iface([], {
    xDataKey: "string",
    keys: t.array("string"),
    data: t.array(
      t.iface([], {
        [t.indexKey]: t.union("number", "string"),
      })
    ),
  }),
})

export const BbsCategoryPerformanceTable = t.iface([], {
  sourceColumns: t.array("SourceColumnWithFooter"),
  sourceData: t.array(
    t.iface([], {
      count: "string",
      impact: "number",
      medianPerformance: "number",
      country: t.opt("string"),
      sector: t.opt("string"),
      style: t.opt("string"),
      marketCap: t.opt("string"),
      theme: t.opt("string"),
    })
  ),
})

export const BbsCategoryStockTableAll = t.iface([], {
  sourceColumns: t.array("SourceColumn"),
  sourceData: t.array(
    t.iface([], {
      portfolioWeight: "number",
      buySelectionImpact: "number",
      buySelectionPerformance: "number",
      name: "string",
      sedol: t.opt("string"),
      Sedol: t.opt("string"),
      sellDate: "string",
      endDate: "string",
      country: t.opt("string"),
    })
  ),
})

export const FUND_OVERALL_PERFORMANCE_LINE_AND_IMPACT = t.iface([], {
  result: t.iface([], {
    line: t.tuple(t.tuple("number")),
    title: "string",
    value: "string",
  }),
})

export const LineGraph = t.iface([], {
  data: t.array(
    t.iface([], {
      label: "string",
      data: t.array(
        t.iface([], {
          x: "string",
          y: "number",
        })
      ),
    })
  ),
  xDomain: t.union(t.array("string"), t.array("number")),
  yDomain: t.array("number"),
})

export const O_BEST_WORST_AREA_CARDS = t.iface([], {
  result: t.iface([], {
    data: t.array("AreaCard"),
  }),
})

export const O_FUND_PERFORMANCE_HORIZONTAL_WATERFALL = t.iface([], {
  result: t.array("WaterfallItem"),
})

export const PcCategoryExposureIndividualHoldingsTable = t.iface([], {
  sourceColumns: t.array("SourceColumn"),
  sourceData: t.array(
    t.iface([], {
      name: "string",
      sedol: t.opt("string"),
      Sedol: t.opt("string"),
      buyDate: "string",
      sellDate: "string",
      averagePortfolioWeight: "number",
      averageBenchmarkWeight: "number",
    })
  ),
})

export const PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE = t.iface([], {
  result: "PcCategoryExposureIndividualHoldingsTable",
})

export const PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_SELECTION = t.iface(
  [],
  {
    result: "PcCategoryExposureIndividualHoldingsTable",
  }
)

export const PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_ALLOCATION =
  t.iface([], {
    result: "PcCategoryExposureIndividualHoldingsTable",
  })

export const PC_CATEGORY_EXPOSURE_OVER_TIME_BAR_CHART = t.iface([], {
  result: t.array(
    t.iface([], {
      [t.indexKey]: "number",
    })
  ),
})

export const PcCategoryExposureTable = t.iface([], {
  sourceColumns: t.array("SourceColumn"),
  sourceData: t.array(
    t.iface([], {
      country: t.opt("string"),
      sector: t.opt("string"),
      style: t.opt("string"),
      normalisedPortfolioWeight: "number",
      benchmarkWeight: "number",
      portfolioImpact: "number",
    })
  ),
})

export const PC_CATEGORY_EXPOSURE_TABLE = t.iface([], {
  result: "PcCategoryExposureTable",
})

export const PC_CATEGORY_EXPOSURE_TABLE_SELECTION = t.iface([], {
  result: "PcCategoryExposureTable",
})

export const PC_CATEGORY_EXPOSURE_TABLE_ALLOCATION = t.iface([], {
  result: "PcCategoryExposureTable",
})

export const PC_CATEGORY_EXPOSURE_YEAR_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        portfolioImpact: "number",
        year: "number",
      })
    ),
  }),
})

export const PC_CATEGORY_EXPOSURE_YEAR_TABLE_ALLOCATION = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        portfolioImpact: "number",
        year: "number",
      })
    ),
  }),
})

export const PC_CATEGORY_EXPOSURE_YEAR_TABLE_SELECTION = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        portfolioImpact: "number",
        year: "number",
      })
    ),
  }),
})

export const PC_PERFORMANCE_LINE_GRAPH = t.iface([], {
  result: "LineGraph",
})

export const PC_PERFORMANCE_RISK_ADJUSTED_LINE_GRAPH = t.iface([], {
  result: "LineGraph",
})

export const PC_PERFORMANCE_TOTAL_IMPACT = t.iface([], {
  result: "TotalImpact",
})

export const PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART = t.iface([], {
  result: t.array(
    t.iface([], {
      [t.indexKey]: "number",
    })
  ),
})

export const PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_ALLOCATION = t.iface(
  [],
  {
    result: t.array(
      t.iface([], {
        [t.indexKey]: "number",
      })
    ),
  }
)

export const PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_SELECTION = t.iface([], {
  result: t.array(
    t.iface([], {
      [t.indexKey]: "number",
    })
  ),
})

export const PC_RETURN_ATTRIBUTION_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        benchmarkweight: "number",
        normalisedPortfolioWeight: "number",
        portfolioImpact: "number",
        country: t.opt("string"),
        sector: t.opt("string"),
        style: t.opt("string"),
      })
    ),
  }),
})

export const PC_RETURN_ATTRIBUTION_TABLE_ALLOCATION = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        benchmarkWeight: "number",
        normalisedPortfolioWeight: "number",
        portfolioImpact: "number",
        country: t.opt("string"),
        sector: t.opt("string"),
        style: t.opt("string"),
      })
    ),
  }),
})

export const PC_RETURN_ATTRIBUTION_TABLE_SELECTION = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        benchmarkWeight: "number",
        normalisedPortfolioWeight: "number",
        portfolioImpact: "number",
        country: t.opt("string"),
        sector: t.opt("string"),
        style: t.opt("string"),
      })
    ),
  }),
})

export const PC_RETURN_ATTRIBUTION_TOTAL_IMPACT = t.iface([], {
  result: "TotalImpact",
})

export const PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_ALLOCATION = t.iface([], {
  result: "TotalImpact",
})

export const PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_SELECTION = t.iface([], {
  result: "TotalImpact",
})

export const PC_RISK_BENCHMARK_CORRELATION_LINE_GRAPH = t.iface([], {
  result: "LineGraph",
})

export const PC_RISK_PORTFOLIO_CORRELATION_LINE_GRAPH = t.iface([], {
  result: "LineGraph",
})

export const PC_RISK_TOTAL_IMPACT = t.iface([], {
  result: "TotalImpact",
})

export const PC_RISK_VOLATILITY_LINE_GRAPH = t.iface([], {
  result: "LineGraph",
})

export const SP_BUY_SELL_SELECTION_TIMING_MULTI_STAT_CARD = t.iface([], {
  result: t.iface([], {
    data: t.array(
      t.iface([], {
        format: t.opt("string"),
        title: "string",
        value: t.union("string", "number"),
      })
    ),
  }),
})

export const SP_STOCK_HISTORY_TABLE = t.iface([], {
  result: t.iface([], {
    data: t.iface([], {
      sourceColumns: t.array("SourceColumn"),
      sourceData: t.array(
        t.iface([], {
          addImpact: t.union("number", "null"),
          avgActiveWeight: t.union("number", "null"),
          avgPortfolioWeight: t.union("number", "null"),
          buyDate: t.union("string", "null"),
          drawDownFromPeak: t.union("number", "null"),
          drawUpFromTrough: t.union("number", "null"),
          holdingImpact: t.union("number", "null"),
          performance12MPostBuy: t.union("number", "null"),
          performance12MPostSell: t.union("number", "null"),
          performance12MPreSell: t.union("number", "null"),
          performanceOverHoldingPeriod: t.union("number", "null"),
          sellDate: t.union("string", "null"),
          specialSituations: t.union("string", "null"),
          trimImpact: t.union("number", "null"),
        })
      ),
    }),
  }),
})

export const SP_STOCK_TABLE_MULTI_STAT_CARD = t.iface([], {
  result: t.iface([], {
    data: t.array(
      t.iface([], {
        format: "string",
        title: "string",
        value: t.union("number", "string"),
      })
    ),
  }),
})

export const SSRT_ROUNDTRIP_AREA_CARD = t.iface([], {
  result: "AreaCard",
})

export const SST_CATEGORY_PERFORMANCE_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        count: t.opt(t.union("string", "null")),
        country: t.opt("string"),
        sector: t.opt("string"),
        style: t.opt("string"),
        impact: t.opt(t.union("number", "null")),
        medianPerformance: t.opt(t.union("number", "null")),
      })
    ),
  }),
})

export const SST_CATEGORY_STOCK_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        name: "string",
        sedol: t.opt("string"),
        Sedol: t.opt("string"),
        buyDate: "string",
        sellDate: "string",
        sellWeight: t.opt(t.union("number", "null")),
        sellTimingImpact: t.union("number", "null"),
        sellTimingPerformance: t.union("number", "null"),
      })
    ),
  }),
})

export const SST_COLOUR_CODED_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        count: "string",
        impact: t.union("number", "string", "null"),
        medianPerformance: t.union("number", "string", "null"),
        outcome: "string",
        section: "string",
      })
    ),
  }),
})

export const SST_DISTRIBUTION_TIMING_GRAPH = t.iface([], {
  result: t.array(
    t.iface([], {
      buyDate: "string",
      factsetTicker: "string",
      impact: t.union("number", "null"),
      name: "string",
      sellDate: "string",
      x: "number",
      y: "number",
      holdingPeriod: t.opt(t.union("number", "string")),
    })
  ),
})

export const SST_DRAW_DOWN_DRAW_UP_STOCK_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        drawUpFromTrough: t.opt("number"),
        drawDownFromPeak: t.opt("number"),
        drawTime: t.opt("number"),
        name: t.opt("string"),
        peakDate: t.opt("string"),
        troughDate: t.opt("string"),
        sedol: t.opt("string"),
        Sedol: t.opt("string"),
        sellDate: t.opt("string"),
      })
    ),
  }),
})

export const SST_DRAW_DOWN_FROM_PEAK_GROUPING_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        count: "string",
        distribution: "SkewEnum",
        drawTimeMonths: "string",
        medianDraw: "number",
      })
    ),
  }),
})

export const SST_DRAW_DOWN_FROM_PEAK_SCATTER = t.iface([], {
  result: "SellTimingDrawDownDrawUpScatter",
})

export const SST_DRAW_DOWN_FROM_PEAK_VERTICAL_STACKED_BAR = t.iface([], {
  result: t.iface([], {
    xDataKey: "string",
    keys: t.array("string"),
    data: t.array(
      t.iface([], {
        group: "string",
        sold: "number",
        "still holding": "number",
      })
    ),
  }),
})

export const SST_DRAW_TOTAL_IMPACT = t.iface([], {
  result: "TotalImpact",
})

export const SST_DRAW_UP_FROM_TROUGH_GROUPING_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        count: "string",
        distribution: "SkewEnum",
        drawTimeMonths: "string",
        medianDraw: "number",
      })
    ),
  }),
})

export const SST_DRAW_UP_FROM_TROUGH_SCATTER = t.iface([], {
  result: "SellTimingDrawDownDrawUpScatter",
})

export const SST_DRAW_UP_FROM_TROUGH_VERTICAL_STACKED_BAR = t.iface([], {
  result: t.iface([], {
    xDataKey: "string",
    keys: t.array("string"),
    data: t.array(
      t.iface([], {
        group: "string",
        sold: "number",
        "still holding": "number",
      })
    ),
  }),
})

export const SST_HORIZONTAL_STACKED_BAR_GRAPH = t.iface([], {
  result: t.iface([], {
    yDataKey: "string",
    keys: t.array("string"),
    data: t.array(
      t.iface([], {
        [t.indexKey]: t.union("string", "number"),
      })
    ),
  }),
})

export const SST_PERFORMANCE_COLOUR_CODED_TABLE_DEFAULT = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        count: "string",
        impact: t.union("number", "null"),
        medianPerf: t.union("number", "null"),
        outcome: "string",
      })
    ),
  }),
})

export const SST_PERFORMANCE_STOCK_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        buyDate: "string",
        name: "string",
        sedol: t.opt("string"),
        Sedol: t.opt("string"),
        sellDate: "string",
        sellWeight: t.union("number", "null"),
        sellTimingImpact: "number",
        sellTimingPerformance: "number",
      })
    ),
  }),
})

export const SST_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH = t.iface([], {
  result: t.iface([], {
    xDataKey: "string",
    keys: t.array("string"),
    data: t.array(
      t.iface([], {
        [t.indexKey]: t.union("string", "number"),
      })
    ),
  }),
})

export const SST_SELL_SNAIL_TRAIL_TOTAL_IMPACT = t.iface([], {
  result: "TotalImpact",
})

export const SST_SELL_TIMING_IMPACT = t.iface([], {
  result: "TotalImpact",
})

export const SST_SELL_TIMING_IMPACT_BY_CATEGORY = t.iface([], {
  result: "TotalImpact",
})

export const SST_SELL_TIMING_IMPACT_PERFORMANCE = t.iface([], {
  result: "TotalImpact",
})

export const SST_SOLD_BOTTOM_AREA_CARD = t.iface([], {
  result: "AreaCard",
})

export const SST_SOLD_EARLY_AREA_CARD = t.iface([], {
  result: "AreaCard",
})

export const SST_SOLD_GREAT_AREA_CARD = t.iface([], {
  result: "AreaCard",
})

export const SST_SOLD_LATE_AREA_CARD = t.iface([], {
  result: "AreaCard",
})

export const STOCK_GRAPH = t.iface([], {
  result: t.iface([], {
    events: t.array(
      t.iface([], {
        type: "string",
        event: "string",
        date: t.opt("string"),
        tooltip: t.opt(
          t.iface([], {
            impact: t.opt(t.union("string", "number", "null")),
            title: "string",
            description: t.opt("string"),
          })
        ),
        end: t.opt("string"),
        start: t.opt("string"),
      })
    ),
    lines: t.iface([], {
      absolute: t.array(
        t.iface([], {
          x: "string",
          y: "number",
        })
      ),
      relative: t.array(
        t.iface([], {
          x: "string",
          y: "number",
        })
      ),
    }),
  }),
})

export const STOCK_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        name: "string",
        Sedol: "string",
        [t.indexKey]: t.union("string", "number", "null", t.array("number")),
      })
    ),
  }),
})

export const ScatterPlotDataItem = t.iface([], {
  name: t.opt("string"),
  sedol: t.opt("string"),
  Sedol: t.opt("string"),
  factsetTicker: t.opt("string"),
  x: t.union("number", "string", "Date"),
  y: "number",
  filled: t.opt("boolean"),
})

export const ScatterPlot = t.iface([], {
  data: t.array("ScatterPlotDataItem"),
  xDomain: t.union(t.array("string"), t.array("number")),
  yDomain: t.array("number"),
})

export const SellTimingDrawDownDrawUpScatter = t.iface([], {
  data: t.array(
    t.iface([], {
      x: "number",
      y: "number",
      name: "string",
      factsetTicker: "string",
      buyDate: "string",
      sellDate: "string",
      monthsPostBuy: "number",
      filled: t.opt("boolean"),
      date: "string",
    })
  ),
  xDomain: t.union(t.array("string"), t.array("number")),
  yDomain: t.array("number"),
})

export const SkewEnum = t.enumtype({
  HeavyLeftSkew: "HeavyLeftSkew",
  HeavyRightSkew: "HeavyRightSkew",
  RightSkew: "RightSkew",
  LeftSkew: "LeftSkew",
  CenterSkew: "CenterSkew",
})

export const SourceColumn = t.iface([], {
  Header: "string",
  accessor: "string",
})

export const SourceColumnWithFooter = t.iface([], {
  Footer: t.union("string", "number"),
  Header: "string",
  accessor: "string",
})

export const TotalImpact = t.iface([], {
  skew: "SkewEnum",
  title: "string",
  value: "string",
})

export const VCOT_HOLDING_PERIOD_INTERACTION_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        buyDate: "string",
        holdingImpact: t.union("number", "null"),
        holdingPerformance: t.union("number", "null"),
        periodLength: "number",
        name: "string",
        sedol: t.opt("string"),
        Sedol: t.opt("string"),
      })
    ),
  }),
})

export const VCOT_HOLDING_PERIOD_SCATTER_PLOT = t.iface([], {
  result: "ScatterPlot",
})

export const VCOT_HOLDING_PERIOD_SNAIL_TRAIL_AGGREGATE = t.iface([], {
  result: t.array(
    t.iface([], {
      x: "string",
      y: "number",
    })
  ),
})

export const VCOT_HOLDING_PERIOD_SNAIL_TRAIL_BREAKDOWN = t.iface([], {
  result: t.iface([], {
    xDomain: t.array("number"),
    yDomain: t.array("number"),
    data: t.array(
      t.iface([], {
        label: "string",
        sellingPoint: "number",
        data: t.array(
          t.iface([], {
            x: t.opt("number"),
            y: t.opt("number"),
            sellingPoint: t.opt("number"),
            label: t.opt("string"),
          })
        ),
      })
    ),
  }),
})

export const VCOT_HOLDING_PERIOD_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        count: "string",
        medianPerformance: t.opt("number"),
        totalImpact: t.opt("number"),
        monthsHeld: "string",
      })
    ),
  }),
})

export const VCOT_HOLDING_PERIOD_TOTAL_IMPACT = t.iface([], {
  result: "TotalImpact",
})

export const VCOT_MULTI_STAT_CARD = t.iface([], {
  result: t.array(
    t.iface([], {
      format: "string",
      title: "string",
      value: "number",
    })
  ),
})

export const W_ADD_TRIM_AREA_CARD = t.iface([], {
  result: "AreaCard",
})

export const W_ADD_TRIM_EVENTS_BAR_CHART = t.iface([], {
  result: t.array(
    t.iface([], {
      [t.indexKey]: t.union("number", "string"),
    })
  ),
})

export const W_ADD_TRIM_EVENTS_INTERACTION_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        name: "string",
        sedol: t.opt("string"),
        Sedol: t.opt("string"),
        size: "number",
        endDate: "string",
        impactIndividualAdds: t.opt(t.union("number", "null")),
        impactIndividualTrims: t.opt(t.union("number", "null")),
      })
    ),
  }),
})

export const W_ADD_TRIM_EVENTS_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        event: "string",
        numberOfTrades: t.union("string", "null"),
        avgSize: t.union("number", "null"),
        impact: t.union("number", "null"),
      })
    ),
  }),
})

export const W_ADD_TRIM_EVENT_GRAPH_ADD = t.iface([], {
  result: t.iface([], {
    xDomain: t.tuple("number", "number"),
    yDomain: t.tuple("number", "number"),
    data: t.array(
      t.iface([], {
        x: "number",
        y: "number",
      })
    ),
  }),
})

export const W_ADD_TRIM_EVENT_GRAPH_TRIM = t.iface([], {
  result: t.iface([], {
    xDomain: t.tuple("number", "number"),
    yDomain: t.tuple("number", "number"),
    data: t.array(
      t.iface([], {
        x: "number",
        y: "number",
      })
    ),
  }),
})

export const W_ADD_TRIM_TABLE_WITH_ICONS_ADD = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        impact: "number",
        numOfAdds: "number",
        proportion: "string",
        trend: t.union(t.lit("Down"), t.lit("Up"), t.lit("Flat")),
      })
    ),
  }),
})

export const W_ADD_TRIM_TABLE_WITH_ICONS_TRIM = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        impact: "number",
        numOfTrims: "number",
        proportion: "string",
        trend: t.union(t.lit("Down"), t.lit("Up"), t.lit("Flat")),
      })
    ),
  }),
})

export const W_ADD_TRIM_TOTAL_IMPACT = t.iface([], {
  result: "TotalImpact",
})

export const W_INITIAL_INTERACTION_STOCK_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        name: "string",
        sedol: t.opt("string"),
        Sedol: t.opt("string"),
        activeWeightAtEntry: t.union("number", "null"),
        initialWeight: t.union("number", "null"),
        holdingPerformance: t.union("number", "null"),
        holdingImpact: t.union("number", "null"),
      })
    ),
  }),
})

export const W_INITIAL_SCATTER_PLOT = t.iface([], {
  result: "ScatterPlot",
})

export const W_INITIAL_SIMPLE_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        averagePerformance: t.union("number", "null"),
        count: t.union("string", "null"),
        hitRate: t.union("number", "string", "null"),
        weightGroup: t.union("string", "null"),
        winLoss: t.union("number", "null"),
      })
    ),
  }),
})

export const W_INITIAL_TOTAL_IMPACT = t.iface([], {
  result: t.iface([], {
    data: "TotalImpact",
  }),
})

export const W_INITIAL_WEIGHTING_AREA_CARD = t.iface([], {
  result: "AreaCard",
})

export const W_JOURNEY_INTERACTION_STOCK_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumn"),
    sourceData: t.array(
      t.iface([], {
        name: "string",
        sedol: t.opt("string"),
        Sedol: t.opt("string"),
        averagePortfolioWeight: t.union("number", "null"),
        averageActiveWeight: t.union("number", "null"),
        holdingPerformance: t.union("number", "null"),
        holdingImpact: t.union("number", "null"),
      })
    ),
  }),
})

export const W_JOURNEY_SCATTER_PLOT = t.iface([], {
  result: "ScatterPlot",
})

export const W_JOURNEY_SIMPLE_TABLE = t.iface([], {
  result: t.iface([], {
    sourceColumns: t.array("SourceColumnWithFooter"),
    sourceData: t.array(
      t.iface([], {
        count: t.union("string", "null"),
        totalImpact: t.union("number", "null"),
        medianPerformance: t.union("number", "null"),
        skewClass: "SkewEnum",
        weightGroup: "string",
      })
    ),
  }),
})

export const W_JOURNEY_TOTAL_IMPACT = t.iface([], {
  result: "TotalImpact",
})

export const WaterfallItem = t.iface([], {
  label: "string",
  value: "number",
})

const exportedTypeSuite: t.ITypeSuite = {
  AreaCard,
  BBS_AREA_CARD,
  BBS_BUY_SELECTION_IMPACT,
  BBS_BUY_SELECTION_IMPACT_BY_CATEGORY,
  BBS_BUY_SELECTION_IMPACT_DISTRIBUTION,
  BBS_BUY_SELECTION_IMPACT_PERFORMANCE,
  BBS_CATEGORY_PERFORMANCE_TABLE,
  BBS_CATEGORY_STOCK_TABLE,
  BBS_DISTRIBUTION_SCATTER_PLOT,
  BBS_DISTRIBUTION_SELECTION_PERFORMANCE_TABLE,
  BBS_HORIZONTAL_STACKED_BAR_GRAPH,
  BBS_PERFORMANCE_COLOUR_CODED_TABLE,
  BBS_PERFORMANCE_HIT_RATE_WIN_LOSS_STOCK_TABLE,
  BbsPerformanceStockTable,
  BBS_PERFORMANCE_STOCK_TABLE,
  BBS_PERFORMANCE_STOCK_TABLE_DISTRIBUTION,
  BBS_PERFORMANCE_STOCK_TABLE_HIT_RATE,
  BBS_PERFORMANCE_STOCK_TABLE_SELECTION_OVER_TIME,
  BBS_PERFORMANCE_STOCK_TABLE_WIN_LOSS,
  BBS_PERFORMANCE_VERTICAL_BAR_GRAPH_WITH_TRENDLINE,
  BBS_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH,
  BbsCategoryPerformanceTable,
  BbsCategoryStockTableAll,
  FUND_OVERALL_PERFORMANCE_LINE_AND_IMPACT,
  LineGraph,
  O_BEST_WORST_AREA_CARDS,
  O_FUND_PERFORMANCE_HORIZONTAL_WATERFALL,
  PcCategoryExposureIndividualHoldingsTable,
  PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE,
  PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_SELECTION,
  PC_CATEGORY_EXPOSURE_INDIVIDUAL_HOLDINGS_TABLE_ALLOCATION,
  PC_CATEGORY_EXPOSURE_OVER_TIME_BAR_CHART,
  PcCategoryExposureTable,
  PC_CATEGORY_EXPOSURE_TABLE,
  PC_CATEGORY_EXPOSURE_TABLE_SELECTION,
  PC_CATEGORY_EXPOSURE_TABLE_ALLOCATION,
  PC_CATEGORY_EXPOSURE_YEAR_TABLE,
  PC_CATEGORY_EXPOSURE_YEAR_TABLE_ALLOCATION,
  PC_CATEGORY_EXPOSURE_YEAR_TABLE_SELECTION,
  PC_PERFORMANCE_LINE_GRAPH,
  PC_PERFORMANCE_RISK_ADJUSTED_LINE_GRAPH,
  PC_PERFORMANCE_TOTAL_IMPACT,
  PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART,
  PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_ALLOCATION,
  PC_RETURN_ATTRIBUTION_OVER_TIME_BAR_CHART_SELECTION,
  PC_RETURN_ATTRIBUTION_TABLE,
  PC_RETURN_ATTRIBUTION_TABLE_ALLOCATION,
  PC_RETURN_ATTRIBUTION_TABLE_SELECTION,
  PC_RETURN_ATTRIBUTION_TOTAL_IMPACT,
  PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_ALLOCATION,
  PC_RETURN_ATTRIBUTION_TOTAL_IMPACT_SELECTION,
  PC_RISK_BENCHMARK_CORRELATION_LINE_GRAPH,
  PC_RISK_PORTFOLIO_CORRELATION_LINE_GRAPH,
  PC_RISK_TOTAL_IMPACT,
  PC_RISK_VOLATILITY_LINE_GRAPH,
  SP_BUY_SELL_SELECTION_TIMING_MULTI_STAT_CARD,
  SP_STOCK_HISTORY_TABLE,
  SP_STOCK_TABLE_MULTI_STAT_CARD,
  SSRT_ROUNDTRIP_AREA_CARD,
  SST_CATEGORY_PERFORMANCE_TABLE,
  SST_CATEGORY_STOCK_TABLE,
  SST_COLOUR_CODED_TABLE,
  SST_DISTRIBUTION_TIMING_GRAPH,
  SST_DRAW_DOWN_DRAW_UP_STOCK_TABLE,
  SST_DRAW_DOWN_FROM_PEAK_GROUPING_TABLE,
  SST_DRAW_DOWN_FROM_PEAK_SCATTER,
  SST_DRAW_DOWN_FROM_PEAK_VERTICAL_STACKED_BAR,
  SST_DRAW_TOTAL_IMPACT,
  SST_DRAW_UP_FROM_TROUGH_GROUPING_TABLE,
  SST_DRAW_UP_FROM_TROUGH_SCATTER,
  SST_DRAW_UP_FROM_TROUGH_VERTICAL_STACKED_BAR,
  SST_HORIZONTAL_STACKED_BAR_GRAPH,
  SST_PERFORMANCE_COLOUR_CODED_TABLE_DEFAULT,
  SST_PERFORMANCE_STOCK_TABLE,
  SST_PERFORMANCE_VERTICAL_STACK_BAR_GRAPH,
  SST_SELL_SNAIL_TRAIL_TOTAL_IMPACT,
  SST_SELL_TIMING_IMPACT,
  SST_SELL_TIMING_IMPACT_BY_CATEGORY,
  SST_SELL_TIMING_IMPACT_PERFORMANCE,
  SST_SOLD_BOTTOM_AREA_CARD,
  SST_SOLD_EARLY_AREA_CARD,
  SST_SOLD_GREAT_AREA_CARD,
  SST_SOLD_LATE_AREA_CARD,
  STOCK_GRAPH,
  STOCK_TABLE,
  ScatterPlotDataItem,
  ScatterPlot,
  SellTimingDrawDownDrawUpScatter,
  SkewEnum,
  SourceColumn,
  SourceColumnWithFooter,
  TotalImpact,
  VCOT_HOLDING_PERIOD_INTERACTION_TABLE,
  VCOT_HOLDING_PERIOD_SCATTER_PLOT,
  VCOT_HOLDING_PERIOD_SNAIL_TRAIL_AGGREGATE,
  VCOT_HOLDING_PERIOD_SNAIL_TRAIL_BREAKDOWN,
  VCOT_HOLDING_PERIOD_TABLE,
  VCOT_HOLDING_PERIOD_TOTAL_IMPACT,
  VCOT_MULTI_STAT_CARD,
  W_ADD_TRIM_AREA_CARD,
  W_ADD_TRIM_EVENTS_BAR_CHART,
  W_ADD_TRIM_EVENTS_INTERACTION_TABLE,
  W_ADD_TRIM_EVENTS_TABLE,
  W_ADD_TRIM_EVENT_GRAPH_ADD,
  W_ADD_TRIM_EVENT_GRAPH_TRIM,
  W_ADD_TRIM_TABLE_WITH_ICONS_ADD,
  W_ADD_TRIM_TABLE_WITH_ICONS_TRIM,
  W_ADD_TRIM_TOTAL_IMPACT,
  W_INITIAL_INTERACTION_STOCK_TABLE,
  W_INITIAL_SCATTER_PLOT,
  W_INITIAL_SIMPLE_TABLE,
  W_INITIAL_TOTAL_IMPACT,
  W_INITIAL_WEIGHTING_AREA_CARD,
  W_JOURNEY_INTERACTION_STOCK_TABLE,
  W_JOURNEY_SCATTER_PLOT,
  W_JOURNEY_SIMPLE_TABLE,
  W_JOURNEY_TOTAL_IMPACT,
  WaterfallItem,
}
export default exportedTypeSuite
