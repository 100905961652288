import {
  addTooltip,
  initializeTooltip,
  MouseInteractionArgsType,
  MouseOverlayInteractionArgsType,
  SvgType,
  SvgGType,
} from "@behaviour-lab/blab-component-library"
import format from "date-fns/format"
import isValid from "date-fns/isValid"

import { SplitRequestEnum } from "src/types/common"

import { addBisector } from "./addBisector"
import {
  wrapBisectorTooltipContent,
  TooltipContentAlignmentEnum,
  getBisectorTooltipContent,
} from "./bisectorHtml"
import { generateTooltipHtml, getRowsDataByGraphType } from "./tooltipHtml"
import {
  GraphTypeEnum,
  TooltipAdditionalProps,
  BisectorAdditionalProps,
} from "./types"

const TOOLTIP_CLASS_NAME = "datapoint-tooltip"

export const formatDateInTooltip = (date: Date): string => {
  return !isValid(date) ? "" : format(date, "dd MMMM yyyy")
}

export const formatGraphIdWithSplit = (
  graphId: string,
  split?: SplitRequestEnum
): string => (!split ? graphId : `${graphId}__${split}`)

export const getSplitFromGraphId = (
  svg: SvgType | SvgGType
): SplitRequestEnum => svg.attr("id")?.split("__")[1] as SplitRequestEnum

export const hideTooltipFromVisualization = ({
  wrapper,
}: MouseInteractionArgsType | MouseOverlayInteractionArgsType) => {
  wrapper.selectAll(`.${TOOLTIP_CLASS_NAME}`).remove()
}

export const showBisectorOnMouseInteraction =
  (
    _graphType: GraphTypeEnum.LineGraph | GraphTypeEnum.SnailTrailBreakdown,
    additionalArgs: BisectorAdditionalProps
  ) =>
  (visArgs: MouseOverlayInteractionArgsType) => {
    const { wrapper, d, dimensions } = visArgs

    const content = getBisectorTooltipContent(visArgs, additionalArgs)

    addBisector({
      bisector: wrapper.select(`.${TOOLTIP_CLASS_NAME}`),
      event: d,
      contentLeft: wrapBisectorTooltipContent(
        TooltipContentAlignmentEnum.LEFT,
        content
      ),
      dimensions,
      contentRight: wrapBisectorTooltipContent(
        TooltipContentAlignmentEnum.RIGHT,
        content
      ),
    })
  }

export const showTooltipOnMouseInteraction =
  (graphType: GraphTypeEnum, additionalArgs?: TooltipAdditionalProps) =>
  (visArgs: MouseInteractionArgsType<any>) => {
    const { wrapper, item, d, dimensions } = visArgs

    initializeTooltip(wrapper, TOOLTIP_CLASS_NAME)

    const content = generateTooltipHtml(
      getRowsDataByGraphType[graphType]?.(item, d, additionalArgs),
      additionalArgs?.title?.(visArgs)
    ) as string

    addTooltip(wrapper.select(`.${TOOLTIP_CLASS_NAME}`), d, content, dimensions)
  }
