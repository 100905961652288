import * as React from "react"

interface IProps {
  title: string
}

const PageTitle = ({ title }: IProps) => {
  return <h1 className="text-3xl font-bold">{title}</h1>
}

export default PageTitle
