import {
  MouseInteractionArgsType,
  SeriesPointType,
  TimingGraphDataPoint,
} from "@behaviour-lab/blab-component-library"

import {
  LineGraph,
  VCOT_HOLDING_PERIOD_SNAIL_TRAIL_BREAKDOWN,
} from "src/components/Section/DataQuery/pluginResponseValidation/pluginResponseTypes"
import {
  AxisFormatEnum,
  PropType,
  DataItemType,
  HorizontalWaterfallItemType,
} from "src/types/common"

export interface BisectorAdditionalProps {
  data: LineGraphDataType | SnailTrailBreakdownDataType
  colors: string[]
  title?: (
    legendAtPoint: Array<{ x: string | number | Date; y: number }>
  ) => string
  xNumOfDecimals?: number
  yNumOfDecimals?: number
  yAxisFormat?: AxisFormatEnum
  xAxisFormat?: AxisFormatEnum
}

export enum GraphTypeEnum {
  ScatterPlot = "ScatterPlot",
  VerticalGroupedBarGraphWithTrendline = "VerticalGroupedBarGraphWithTrendline",
  HorizontalStackedBarGraph = "HorizontalStackedBarGraph",
  VerticalStackedBarGraph = "VerticalStackedBarGraph",
  VerticalStackedBarGraphExposure = "VerticalStackedBarGraphExposure",
  HorizontalWaterfall = "HorizontalWaterfall",
  TimingGraph = "TimingGraph",
  VerticalGroupedBarGraphAddAndTrim = "VerticalGroupedBarGraphAddAndTrim",
  LineGraph = "LineGraph",
  SnailTrailBreakdown = "SnailTrailBreakdown",
  VerticalBarGraph = "VerticalBarGraph",
}

export type LineGraphDataType = PropType<LineGraph, "data">

export type SnailTrailBreakdownDataType = PropType<
  PropType<VCOT_HOLDING_PERIOD_SNAIL_TRAIL_BREAKDOWN, "result">,
  "data"
>

export interface TooltipAdditionalProps {
  xLabel?: string
  yLabel?: string
  monthsPostBuy?: string
  key?: string
  yAxisFormat?: AxisFormatEnum
  xAxisFormat?: AxisFormatEnum
  title?: <
    VisArgs extends MouseInteractionArgsType<
      SeriesPointType & HorizontalWaterfallItemType & TimingGraphDataPoint
    >
  >(
    visArgs: VisArgs
  ) => string
  xNumOfDecimals?: number
  yNumOfDecimals?: number
}

export type StackedBarGraphItemType = [
  number,
  number,
  { data: DataItemType }
] & {
  data?: DataItemType
}
