import buyingLink from "./links/buyingLink"
import overviewLink from "./links/overviewLink"
import portfolioConstructionLink from "./links/portfolioConstructionLink"
import sellingLink from "./links/sellingLink"
import stocksLink from "./links/stocksLink"
import valueCreationOverTimeLink from "./links/valueCreationOverTimeLink"
import weightingLink from "./links/weightingLink"

const orderedLinks = [
  overviewLink,
  portfolioConstructionLink,
  valueCreationOverTimeLink,
  buyingLink,
  weightingLink,
  sellingLink,
  stocksLink,
]

export default orderedLinks
